import { Product } from '@app/models';

export class ProductBuilder {
    private _id: string;
    private _name: string;
    private _productId: number;

    withId(value: string): ProductBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): ProductBuilder {
        this._name = value;
        return this;
    }

    withProductId(value: number): ProductBuilder {
        this._productId = value;
        return this;
    }

    build(): Product {
        return {
            id: this._id,
            name: this._name,
            productId: this._productId
        };
    }
}
