import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectFavoritesList} from '@store/selector';
import {ProductSummary} from '@app/models';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TABLE_HEADER_LABELS} from '@data/constants';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ExportBaseComponent} from 'src/app/graphics/components/base/export-base.component';
import {FavoriteActions} from 'src/app/store/actions/favorite.actions';
import {selectFavoritesListLoader} from 'src/app/store/selector';

@Component({
    selector: 'app-favorite-page',
    templateUrl: './favorite-page.component.html',
    styleUrls: ['./favorite-page.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FavoritePageComponent extends ExportBaseComponent implements OnInit {
    favoritesState: Observable<Partial<ProductSummary>[]>;
    dataFavoriteToExport: Partial<ProductSummary>[];
    columnsToDisplay: string[];
    headerLabels = TABLE_HEADER_LABELS;
    dataSource: MatTableDataSource<Partial<ProductSummary>>;

    @ViewChild('searchFilter') searchFilter!: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly favoritesListLoaderView$ = this.store.select(selectFavoritesListLoader);

    constructor(private store: Store,
                public dialog: MatDialog) {
        super();

        this.favoritesState = this.store.select(selectFavoritesList);
        this.columnsToDisplay = ['overview', 'label', 'actions'];
    }

    ngOnInit(): void {
        this.store.dispatch(FavoriteActions.loadElementsFromFavorite());

        this.favoritesState
            .subscribe(data => {
                this.dataFavoriteToExport = data;
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
            });
    }

    removeFromFavoriteList(item) {
        this.store.dispatch(FavoriteActions.toggleFavorite({
            favorites: {
                extID: item.element.ExtID
            }
        }));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue ? filterValue.trim().toLowerCase() : '';

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public resetSearch(event: Event): void {
        this.searchFilter.nativeElement.value = '';
        this.applyFilter(event);
    }

    showMoreInfos(card) {
        this._dialogService.openProductDialog(card);
    }

    openExportDialog() {
        this.exportElements(
            this.dataFavoriteToExport.map(element => element.element.ID),
        );
    }
}
