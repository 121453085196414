<ng-container *ngIf="caracInfo.enableMultiValues else uniqueValueBlock">
  <ul *ngIf="caracInfo.enableMultiValues">
    <li *ngFor="let item of displayValues">
      {{item}}
    </li>
  </ul>
</ng-container>
<ng-template #uniqueValueBlock>
  <span class="value" *ngIf="!caracInfo.enableMultiValues">{{displayValues.length > 0 ? displayValues[0] : ''}}</span>
</ng-template>


