<div class="row title2_block">
    <h2 class="col-8 pull-left title-2-width">{{title}}</h2>

    <div class="col-3 tite-research-block">
        <input [formControl]="researchControl"  [class.display-research-bar]="hasResearchBar" type="text" class="form-control" placeholder="Rechercher au niveau 1...">
    </div>

    <a  [routerLink]="[]" queryParamsHandling="merge" class="col-1 pull-right text-uppercase fold-unfold" (click)="toggleOpen()">
        <span *ngIf="open">{{translate('_ScreenReplier', 'Replier')}}</span>
        <span *ngIf="!open">{{translate('_ScreenDeplier', 'Déplier')}}</span>
        <span><i aria-hidden="true" class="fa" [class.fa-chevron-up]="open" [class.fa-chevron-down]="!open"></i></span>
    </a>
</div>
