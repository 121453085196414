import {createAction, props} from '@ngrx/store';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';
import {MediaSearchResult} from '../../data/models/advanced-search-result.model';

export const SearchMedia = createAction('[Media Library] Search Media',
    props<{ advancedSearch: AdvSearchArgs, multiOperators: boolean }>());
export const SearchMediaSuccess = createAction('[Media Library] Search Media Success', props<{
    searchResult: MediaSearchResult
}>());
export const SearchMediaFailure = createAction('[Media Library] Search Media Failure', props<{
    message: string
}>());
