import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-loader',
    templateUrl: './dialog-loader.component.html',
    styleUrls: ['./dialog-loader.component.scss'],
})
export class DialogLoaderComponent implements OnInit {
    constructor(public _dialogRef: MatDialogRef<DialogLoaderComponent>) {
    }

    ngOnInit() {
        this._dialogRef.updatePosition({top: `${160}px`});
    }
}
