import { CatalogPage, PageTemplate, Product } from '@app/models';
import { Guid } from 'guid-typescript';
import { TemplatesNames, TemplatesTags } from '../../enums/publication-tags.enum';

export class PageBuilder {
    private _id: string;
    private _name: string;
    private _description: string;
    private _template: PageTemplate;
    private _products: Product[];

    withId(value: string): PageBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): PageBuilder {
        this._name = value;
        return this;
    }

    withDescription(value: string): PageBuilder {
        this._description = value;
        return this;
    }

    withTemplate(value: PageTemplate): PageBuilder {
        this._template = value;
        return this;
    }

    withProducts(value: Product[]): PageBuilder {
        this._products = value;
        return this;
    }

    build(): Partial<CatalogPage> {
        const catalogPage = new CatalogPage({
            id: this._id ?? Guid.create().toString(),
            name: this._name,
            description: this._description,
            template: this._template,
            products: this._products
        });

        const pageNameSplitted = catalogPage.name.split(' - ');
        catalogPage.nameWithoutTag = pageNameSplitted[1] ?? catalogPage.name;

        if (catalogPage.name.includes(TemplatesTags.COVER)) {
            catalogPage.type = TemplatesNames.COVER;
        } else if (catalogPage.name.includes(TemplatesTags.EDITO)) {
            catalogPage.type = TemplatesNames.EDITO;
        } else if (catalogPage.name.includes(TemplatesTags.PRODUCT)) {
            catalogPage.type = TemplatesNames.PRODUCT;
        } else {
            catalogPage.type = '';
        }

        catalogPage.isEditable = catalogPage.type === TemplatesNames.COVER ||
            catalogPage.type === TemplatesNames.EDITO ||
            catalogPage.type === TemplatesNames.PRODUCT;


        return catalogPage;
    }
}
