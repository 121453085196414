import {Component, Input, OnInit} from '@angular/core';
import {FileFacade} from "../../../data/facades/file-facade";
import {Observable} from "rxjs";
import {FilePreview} from "../../../data/models/file-preview.model";

@Component({
    selector: 'app-home-page-files-view',
    templateUrl: './home-page-files-view.component.html',
    styleUrls: ['./home-page-files-view.component.scss']
})
export class HomePageFilesViewComponent implements OnInit {
    @Input() customClass: string;

    public files$: Observable<FilePreview[]>

    constructor(private _fileFacade: FileFacade) {
    }

    ngOnInit(): void {
        this.files$ = this._fileFacade.getFiles();
    }

}
