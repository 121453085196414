import {NPCaracValeur} from './np-carac-valeur';

export class NpUser {
    public ID: number;
    public Name: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Group: string;
    public CustomFields?: CustomFieldDto[] = [];
}

export class CustomFieldDto {
    ExtID: string;
    ID: number;
    ID_List: null;
    Label: string;
    Type: string;
    Values: CustomFieldValue[];
}

export interface CustomFieldBase {
    getFirstTextValue();
    getValues();
}

export class CustomField extends CustomFieldDto implements CustomFieldBase {
    constructor(customFieldDto: CustomFieldDto) {
        super();
        if (customFieldDto) {
           this.ExtID = customFieldDto.ExtID;
           this.ID = customFieldDto.ID;
           this.ID_List = customFieldDto.ID_List;
           this.Label = customFieldDto.Label;
           this.Type = customFieldDto.Type;
           this.Values = customFieldDto.Values;
        }
    }
    getFirstTextValue() {
        return this.Values && this.Values.length > 0 ?
            this.Values[0].Value :
            null;
    }

    getValues() {
        return this.Values || [];
    }
}

export class SupplierInfo {
  Logo: NPCaracValeur;
  Raison_sociale: NPCaracValeur;
  Adresse: NPCaracValeur;
  Code_postal: NPCaracValeur;
  Ville: NPCaracValeur;
}
export class CustomFieldValue {
    ID: number;
    ID_Field: number;
    ID_List: number;
    ID_User: number;
    Value: string;
}
