import {Injectable} from '@angular/core';
import {BehaviorSubject, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {flatMap} from 'rxjs/internal/operators';
import {AuthenticationService, Constantes} from '@nextpage/np-sdk-data';
import {USER_TOKEN} from '../constants/sp-constants';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class SpAuthenticationService {
  // private _isAuthenticated: BehaviorSubject<boolean>;
  private _token: string;
  private _afterAuth$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _authService: AuthenticationService,
    private _constantes: Constantes,
    private _tokenService: TokenService
  ) {
  }

  public afterAuth() {
    return this._afterAuth$.asObservable();
  }

  logout() {
    localStorage.removeItem(USER_TOKEN);
    this._token = null;
    TokenService.deleteToken();
  }

  resetPassword(email: string) {
    return this._authService.initPassword(email);
  }

  isAuthenticated() {
    return this._tokenService.getToken$().pipe(
      flatMap((_currentToken) => {
        if (_currentToken && _currentToken.trim() !== '') {
          return this._authService.isAuthenticated(_currentToken).pipe(
            catchError((err) => {
              this.logout();
              return throwError(err);
            })
          );
        }
        this.logout();
        return of(false);
      })
    );
  }
}
