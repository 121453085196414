import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogCatalogueDeletionComponent
} from '../../../graphics/components/publication/dialog-catalogue-deletion/dialog-catalogue-deletion.component';
import { Store } from '@ngrx/store';
import { FetchCatalogTree, selectCatalogTreeView, DeleteCatalogTree, DuplicateCatalogTree, GetCatalogPdf, GetCatalogSources } from '@store/publication';
import { NpCatalogService } from 'src/lib';
import { TreeNodeCatalog } from '@app/models';
import { Subscription } from 'rxjs';
import { RouteResolverService } from 'src/app/data/services/routes/route-resolver.service';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Component({
  selector: 'app-catalogues',
  templateUrl: './catalogues.component.html',
  styleUrls: ['./catalogues.component.scss']
})

export class CataloguesComponent implements OnInit, OnDestroy {
  public catalogTreeView$ = this._store.select(selectCatalogTreeView);

  public actionButtonClicked = false;

  private _catalogueFolderId: number;
  private _confirmSub: Subscription;

  constructor(
    public dialog: MatDialog,
    private _store: Store,
    private _catalogService: NpCatalogService,
    private _routeResolverService: RouteResolverService) { }

  ngOnInit(): void {
    this._store.dispatch(FetchCatalogTree({ parentId: this._catalogueFolderId }));
  }

  public downloadPdf(catalog: TreeNodeCatalog): void {
    this.actionButtonClicked = true;
    this._store.dispatch(GetCatalogPdf({catalog}));
  }

  public downloadSources(catalog: TreeNodeCatalog): void {
    this.actionButtonClicked = true;
    this._store.dispatch(GetCatalogSources({catalog}));
  }

  public editCatalog(catalog: TreeNodeCatalog): void {
    this._routeResolverService.navigateToCurrentEntityRouteWithParams(
      RoutePagesName.CATALOGUE, 
      { id: catalog.id },
      false, catalog);
  }

  public duplicateCatalog(catalog: TreeNodeCatalog): void {
    this.actionButtonClicked = true;
    this._store.dispatch(DuplicateCatalogTree({ catalogId: catalog.id }));
  }

  public confirmCatalogDeletion(catalog: TreeNodeCatalog): void {
    this._confirmSub = this.dialog.open(DialogCatalogueDeletionComponent, {
      data: catalog
    }).afterClosed()
      .subscribe(confirmDeletion => {
        if (confirmDeletion) {
          this._store.dispatch(DeleteCatalogTree({ catalog: catalog }));
        }
      });
  }

  ngOnDestroy(): void {
    this._confirmSub?.unsubscribe();
  }

}
