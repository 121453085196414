import {Component, inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AsyncPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {ThumbsSizeService} from '../../../data/services/thumbs-size.service';
import {map} from 'rxjs/operators';
import {ExportHelper} from '../../../data/helpers/export.helper';
import {NpInstanceService} from '../../../data/services/np-instance.service';
import {DesignComponent} from '../base/design.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subscription } from 'rxjs';
import { ExportParams } from 'src/app/data/models/export.params.model';

@Component({
    selector: 'app-dialog-elements-export',
    templateUrl: './dialog-elements-export.component.html',
    styleUrls: ['./dialog-elements-export.component.scss'],
    imports: [
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        NgForOf,
        MatExpansionModule,
        NgIf,
        AsyncPipe,
        NgClass,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None
})
export class DialogElementsExportComponent extends DesignComponent implements OnDestroy {
    emailFormControl = new FormControl('', [
        Validators.email
    ]);

    private exportParamsSub: Subscription;

    constructor(private _translate: TranslateService) {
        super();
        this.initializeConfigurationView();
    }

    protected configurationView: ExportParams;

    protected readonly displayMediaBlock = !inject(NpInstanceService).isMediaLibraryPage();

    private initializeConfigurationView() {
        this.exportParamsSub = inject(ThumbsSizeService).getAllowedThumbsSize()
            .pipe(
                map(allowedThumbsSizes => {
                    const exportMediaParam = ExportHelper.buildExportMediaParam(allowedThumbsSizes, this._translate.instant('products.export-thumbnails'));
                    const exportParams = new ExportParams(exportMediaParam, this.emailFormControl.value);
                    this.configurationView = exportParams;
                    return exportParams;
                })
            )
            .subscribe();

        this.emailFormControl.valueChanges.subscribe(email => {
            this.configurationView.emailParam = email;
        });
    }

    ngOnDestroy(): void {
        this.exportParamsSub?.unsubscribe();
    }
}
