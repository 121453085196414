<ng-container *ngIf='advancedSearch'>
    <div [ngClass]="customClass">
        <div class="cards-container">
            <div class="cards-block-title">
                {{advancedSearch?.Config?.Properties?.Name || 'homepage.unknown-advanced-search' | translate }}
            </div>
            <ng-container *ngIf='cardsView$ | async as cards'>
                <div class="cards-block-content">
                    <app-media-card
                            [filteredCard]="cards">
                    </app-media-card>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
