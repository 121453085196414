import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {MatDialog} from '@angular/material/dialog';
import {
    CaracConfig,
    DicocaracRepository,
    ElementWriterService,
    NPCaracListe,
    NPListeValues
} from '@nextpage/np-sdk-data';

@Component({
  selector: 'lib-check-simple-list-v2',
  templateUrl: './check-simple-list-v2.component.html',
  styleUrls: ['./check-simple-list-v2.component.css']
})
export class CheckSimpleListV2Component implements OnInit, OnChanges {

  @Input() caracInfo: CaracInfo;
  @Input() value: NPCaracListe;
  @Input() caracConfig: CaracConfig;
  @Input() advancedMode: boolean;

  public displayValues: NPListeValues[];
  public displayValueForAdvancedMode: NPListeValues;

  constructor(private _dicoCaracRep: DicocaracRepository,
              private _eltWrSrv: ElementWriterService,
              private _dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initValues();
  }

  public initValues() {
    this.displayValues = this.caracConfig && this.caracConfig.DicoCarac ? this.caracConfig.DicoCarac.Values : [];
    // this.advancedMode = !this.advancedMode && this.caracConfig && this.caracConfig.Specific && this.caracConfig.Specific.advancedMode;
    this.advancedMode = this.caracConfig && this.caracConfig.Specific && this.caracConfig.Specific.advancedMode;
    console.log('this.caracConfig : ', this.caracConfig);
    this._initDisplayValueForAdvancedMode();
  }

  private _initDisplayValueForAdvancedMode() {
    if (this.advancedMode) {
      const tmpValue = this.displayValues.find(v => this.isChecked(v.ValeurID));
      this.displayValueForAdvancedMode = tmpValue ? tmpValue : this.displayValues.length > 0 ? this.displayValues[0] : undefined;
    }
  }

  /**
   * Coche la case (ou la décoche)
   */
  onValueChange(valeurId: number) {
    let existingValues = (<NPCaracListe>this.value.Element.getValue(this.value.DicoCaracExtID)).Values.map((v) => v.ValueID);
    if (this.isChecked(valeurId)) {
      // On le retire en récupérant d'abord la liste des valeurs déjà choisies
      existingValues = existingValues.filter(currentId => currentId !== valeurId);
      this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, existingValues);
    } else {
      // On l'ajoute
      existingValues = existingValues ? existingValues.concat([valeurId]) : [valeurId];
      this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, existingValues);
      this._initDisplayValueForAdvancedMode();
    }
  }

  /**
   * Indique si la valeur est sélectionnée
   */
  isChecked(newValueId: number) {
    return (<NPCaracListe>this.value.Element.getValue(this.value.DicoCaracExtID)).Values.some((v) => v.ValueID === newValueId);
  }

  openDialog() {
    /* this._dialog.open(DialogCheckSimpleListComponent, {
       width: '30em',
       data: {caracInfo: this.caracInfo, value: this.value, caracConfig: this.caracConfig}
     }).afterClosed()
         .subscribe(response => {
           if (response.saved && response.data) {
             this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, Array.from(response.data));
             this._initDisplayValueForAdvancedMode();
           }
         });*/
  }
}
