import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CharTemplateState, mediaCharTemplateFeatureKey} from './media-char-template.reducer';

const selectMediaCharTemplateState = createFeatureSelector<CharTemplateState>(mediaCharTemplateFeatureKey);

export const selectMediaCharTemplate = createSelector(
    selectMediaCharTemplateState,
    state => state.charTemplates
);

