<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ 'publication.header.catalogue-generation-impossible' | translate }}</h2>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  {{ 'publication.header.empty-pages-text' | translate }}
  <ul>
    <li *ngFor="let emptyPage of data | keyvalue : keepOrder" 
    [innerHTML]="'publication.header.empty-pages-list' | translate : { chapterName : emptyPage.key, pageName: emptyPage.value.join(', ') }"></li>
  </ul>
</mat-dialog-content>
