import {createAction, props} from '@ngrx/store';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';

export const FetchAdvancedSearch = createAction(
    '[advanced Search] Fetch advanced Search',
    props<{ advancedSearchId: number }>
);

export const FetchAdvancedSearchSuccess = createAction(
    '[advanced Search] Fetch Success',
    props<{ advSearch: AdvSearchArgs }>()
);

export const FetchAdvancedSearchFailure = createAction(
    '[advanced Search] Fetch Failure',
    props<{ message: string }>()
);
export const SaveAdvancedSearch = createAction(
    '[advanced Search] Save advanced Search',
    props<{ advSearch: AdvSearchArgs }>()
);
export const UpdateElementType = createAction(
    '[advanced Search] Element type updated',
    props<{ elementType: number }>()
);
