import { ProductPortal, TemplatePortal, PagePortal } from '@lib/models';

export class PagePortalBuilder {
    private _id: string;
    private _name: string;
    private _description: string;
    private _template: TemplatePortal;
    private _products: ProductPortal[]

    withId(value: string): PagePortalBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): PagePortalBuilder {
        this._name = value;
        return this;
    }

    withDescription(value: string): PagePortalBuilder {
        this._description = value;
        return this;
    }

    withTemplate(value: TemplatePortal): PagePortalBuilder {
        this._template = value;
        return this;
    }

    withProducts(value: ProductPortal[]): PagePortalBuilder {
        this._products = value;
        return this;
    }

    build(): PagePortal {
        return {
            Id: this._id,
            Name: this._name,
            Description: this._description,
            Template: this._template,
            Products: this._products
        }
    }
}
