<ng-container *ngIf="currentMedia">
    <div class="tree-container">
        <div class="tree-item" [ngClass]="isSelected(currentMedia) ? 'tree-item-active btn-primary-np' : ''" *ngIf="isRoot" (click)="onDirectoryClick(currentMedia)">{{currentMedia.name}}</div>
        <ng-container *ngIf="isRoot || (currentMedia.opened && currentMedia.mediasDirectory.length > 0)">
            <ul class="children-block">
                <li *ngFor="let mediaDirectory of currentMedia.mediasDirectory" class="">
                    <div class="tree-item" [ngClass]="isSelected(mediaDirectory) ? 'tree-item-active' : ''" (click)="onDirectoryClick(mediaDirectory)">
                        <i *ngIf="hasChildrenDirectories(mediaDirectory)" class="{{mediaDirectory.opened ? 'glyphicon glyphicon-chevron-up' : 'glyphicon glyphicon-chevron-down'}}"></i>
                        <span class="">{{mediaDirectory.name}}</span>
                    </div>
                    <ng-container *ngIf="mediaDirectory.opened">
                        <lib-np-medias-tree [currentMedia]="mediaDirectory" [isRoot]="false"></lib-np-medias-tree>
                    </ng-container>
                </li>
            </ul>
        </ng-container>
    </div>

</ng-container>

