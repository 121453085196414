import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {CaracConfig, ElementWriterService, NPCaracStd, UiTranslationService, StructuredDataService} from '@nextpage/np-sdk-data';
import {ContentStateControllerService, Sheet, ThemingService} from '../../services';
import jss from 'jss';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'lib-title-2',
    templateUrl: './title-2.component.html',
    styleUrls: ['./title-2.component.css']
})
export class Title2Component implements OnInit {
    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;
    @Input() customDatas: any;

    private _translations = new Map<string, string>();

    public open = true;
    public contentKey: string;
    public title: string;
    public hasResearchBar = false;
    researchControl = new UntypedFormControl();

    public classes: Object;
    private researchCarac: string;

    constructor(private _stateControllerService: ContentStateControllerService,
                private _translateSrv: UiTranslationService,
                private _elementWriter: ElementWriterService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService,
                private _structuredDataService: StructuredDataService) {
    }

    ngOnInit() {
        this.hasResearchBar = this.caracConfig.Specific && this.caracConfig.Specific['DisplayResearch']; // S'il faut afficher le menu de recherche
        this.researchCarac = this.caracConfig.Specific ? this.caracConfig.Specific['ResearchCarac'] : ''; //
        const override_css = this._theming.getComponentConfigStyle('Title2Component');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }
        if (this.customDatas != null) {
            this.contentKey = this.customDatas;
        }
        this.title = this.value != null ? this.getFormattedValue() : this.caracConfig.Label;
        this._stateControllerService.pushControllerState(this.contentKey, {
            open: this.open
        });

        this.researchControl.valueChanges
            .subscribe(newValue => {
                this._structuredDataService.setFilterValueResearch(this.researchCarac, newValue);
            });
    }

    public toggleOpen() {
        this.open = !this.open;
        this._stateControllerService.pushControllerState(this.contentKey, {
            open: this.open
        });
    }

    getFormattedValue() {
        const unite = this.caracInfo.unite ? ' ' + this.caracInfo.unite : '';
        return this.value.Value.replace(/\r?\n/g, '<br />') + unite;
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }
}
