<div *ngIf="fileName == null" class="dropzone" fileDrop (filesDropped)="handleDrop($event)"
     (filesHovered)="handleHover($event)" [class.hovered]="dropzoneHovered"
     [title]="caracConfig.DicoCarac.Description">

    <mat-icon class="icon-purple-color" (click)="uploadFileBlock.click()" title="Cliquer pour charger un fichier">cloud_upload</mat-icon>
    <input type="file" #uploadFileBlock class="input-file newMedia" name="newMedia"
           id="uploadFile" accept=".doc,.docx,application/msword" (change)="onFileChange($event)">
    <!--<div>
        &lt;!&ndash;<input class="input-file newMedia" name="newMedia" type="file" (change)="onFileChange($event)">&ndash;&gt;
        <img src="assets/images/picto-upload.png" alt="">
        <span>Glissez votre fichier ici<br>ou cliquez sur <input #myInput class="input-file newMedia" name="newMedia"
                                                                 type="file" (change)="onFileChange($event)"
                                                                 id="inputfield"></span>
    </div>-->
</div>
<div *ngIf="fileName && fileName !== ''" class="file-name">
    <span [innerHTML]="fileName"></span>
    <span class="delete-file" (click)="removeFile()" title="Supprimer ce fichier"><br>Supprimer</span>
</div>

