<p-overlayPanel #op styleClass="template-selection__overlay" (onShow)="getProductsTemplates()" [dismissable]="false">
  <ng-template pTemplate="content">
    <mat-card class="template-selection__container">
      <form [formGroup]='templateSelectionForm'>
        <mat-card-header class="align-items-center justify-content-between template-selection__header">
          <mat-card-title class="template-selection__title">
            <input formControlName="productsPageTitle" type="text" placeholder="Page" #productsPageTitleInput>
          </mat-card-title>
          <div class="d-flex align-items-center template-selection__header-actions">
            <fa-icon class="page-btn" [icon]="['far', 'file-plus']" size="lg" (click)="openPagesAddition()"></fa-icon>
            <fa-icon class="delete-btn" [icon]="['far', 'trash']" size="lg" (click)="confirmPageDeletion()"></fa-icon>
            <fa-icon [icon]="['far', 'xmark']" size="lg" (click)="closeOverlay()"></fa-icon>
          </div>
        </mat-card-header>

        <mat-card-content class="template-selection__content">
          <p class="text-center template-title">{{ 'publication.catalogue.choose-page-template' | translate }}</p>
          <div class="d-flex flex-column">
            <ng-container *ngIf="(productsTemplatesView$ | async) as productsTemplatesView">
              <mat-radio-group *ngIf="productsTemplatesView?.hasTemplates; else noResult"
              formControlName="productsPageTemplate" 
              required>
                  <mat-radio-button *ngFor="let productsTemplate of productsTemplatesView?.publicationTemplates; let index = index"
                  class="mat-radio-container-no-show" 
                  [value]="productsTemplate">
                      <img *ngIf="productsTemplate?.jpgFile" 
                      [src]="productsTemplate?.jpgFile" 
                      [alt]="productsTemplate?.description" 
                      (error)="displayDefaultImage(productsTemplate)">
                      <span class="template-selection-label">{{ productsTemplate?.description ? productsTemplate?.description : productsTemplate?.inddFile }}</span>
                  </mat-radio-button>
              </mat-radio-group>

              <ng-template #noResult>
                  <div class="text-center" *ngIf="!productsTemplatesView?.loading">{{'publication.catalogue.no-template-found' | translate}}</div>
                </ng-template>
            
              <app-loader-spinner *ngIf='productsTemplatesView?.loading' [diameter]="50"></app-loader-spinner>
            </ng-container>
          </div>
        </mat-card-content>
      </form>

      <mat-card-actions class="justify-content-end template-selection__actions">
        <button mat-stroked-button class="cancel-btn" (click)="closeOverlay()">{{ 'common.cancel' | translate }}</button>
        <button mat-flat-button 
          color='primary' 
          class="validate-btn"
          type='submit'
          [disabled]='templateSelectionForm.invalid || (loading$ | async)'
          (click)="submitTemplateSelection()">
          <span *ngIf='(loading$ | async)'
          class='spinner-border spinner-border-sm mr-1'></span>
          {{ 'common.validate' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</p-overlayPanel>
