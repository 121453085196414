import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { PublicationChaptersActions, selectPublicationChaptersView, PublicationChaptersState, CreateCatalog } from '@store/publication';
import { selectLoadingAuthentication } from 'src/app/store/selector';
import { NPElement } from 'src/lib';
import * as _ from 'lodash';
import { CatalogCreation } from '@lib/models';

@Component({
    selector: 'app-publication-catalogue-creation',
    templateUrl: './publication-catalogue-creation.component.html',
    styleUrls: ['./publication-catalogue-creation.component.scss'],
})
export class PublicationCatalogueCreationComponent implements OnInit {

    public catalogForm: FormGroup;
    public loading$ = this._store.select(selectLoadingAuthentication); // TODO new store?

    public chaptersView$ = this._store.select(selectPublicationChaptersView())
        .pipe(
            tap((chaptersState: PublicationChaptersState) => {
                this._chapters = chaptersState.chapters;
                this._chapters?.forEach(chapter => {
                    this.catalogForm.addControl(`${chapter.ID}`, new FormControl(false));
                });
            })
        );

    private _chapters: NPElement[];

    constructor(
        private _fb: FormBuilder,
        private _store: Store) {

        this.catalogForm = this._fb.group<{ catalogName, catalogDescription, chapters }>({
            catalogName: ['', Validators.required],
            catalogDescription: [''],
            chapters: FormArray<FormControl>
        });

    }

    ngOnInit(): void {
        this._store.dispatch(PublicationChaptersActions.fetchPublicationChapters());
    }

    public createCatalogue(): void {
        const checkedChapters = this._chapters?.filter(
            chapter => this.catalogForm.controls[chapter.ID].value).map(chapter => chapter.ExtID);

        const catalogInfo = new CatalogCreation();
        catalogInfo.name = this.catalogForm.controls.catalogName.value;
        catalogInfo.description = this.catalogForm.controls.catalogDescription.value;
        catalogInfo.selectedChapters = checkedChapters;

        this._store.dispatch(CreateCatalog({ catalogInfo }));
    }
}
