import {Component, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {Access, CaracConfig, DicoCarac, NPCaracLien} from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-np-value-link-reader',
    templateUrl: './np-value-link-reader.component.html',
    styleUrls: ['./np-value-link-reader.component.scss']
})
export class NpValueLinkReaderComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;
    public readOnlyAccess: boolean;
    private linkedValues: string[] = [];

    constructor() {
    }

    ngOnInit() {
        this.readOnlyAccess = this.caracInfo && this.caracInfo.authorization === Access.LECTURESEULE;
        // Si le composant est utilisé en lecture seule
        if (this.readOnlyAccess && this.value && this.value && this.value.RebuildLinkedElements) {
            this.linkedValues = this.value.RebuildLinkedElements.map(linked => {
                return linked && linked.Element ? linked.Element.getValueTextValue(DicoCarac.PRODUCT_LABEL) : '';
            });
        }
    }

}
