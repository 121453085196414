import {SdkInterceptorService} from '../app/data/interceptors/sdk-interceptor.service';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {MatPaginatorIntlFrService} from '../app/data/services/mat-paginator-intl-fr.service';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {DataMocker} from "@nextpage/np-sdk-data";

export const environment = {
    production: false,
    providers: [
        {provide: DataMocker, useClass: SdkInterceptorService},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 1500}},
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlFrService},
    ],
    instance: undefined,
    useChannelSearch: true,
    enableMediaLibrary: true,
    favoriteKey: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
