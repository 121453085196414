<div class="product-table-block">
  <ng-container *ngIf="column && element">
    <ng-container *ngIf="column === 'actions && role' ; else dataCell">
      <div class="actions-block">
        <span
          class="material-icons"
          (click)="edit(); $event.stopPropagation()"
          title="Editer cet élément"
          >edit</span
        >
        <span
          class="material-icons"
          title="Supprimer cet élément"
          >delete</span
        >
      </div>
    </ng-container>
    <ng-template #dataCell>
      <div class="content-block np-tag"
      *ngIf="column === 'status' && element['status']"
      [matTooltip]="element[column]['Libelle']"
      matTooltipPosition="above">
        <span class="status-color" [style.background]="element['status']['Color']"></span>
        <span class="status">{{ element[column]['Libelle'] }}</span>
      </div>
      <div class="content-block" *ngIf="column !== 'status'">
        {{ element[column] }}
      </div>
    </ng-template>
  </ng-container>
</div>
