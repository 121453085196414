import {createFeatureSelector, createSelector} from '@ngrx/store';
import { dataLanguageFeatureKey, DataLanguageState } from './data-language.reducer';

const selectState = createFeatureSelector<DataLanguageState>(
    dataLanguageFeatureKey
);

export const selectDataLanguage = createSelector(
    selectState, (state) => state.selectedDataLanguage
);

export const selectDataLanguages = createSelector(
    selectState, (state) => state
);
