import { DisplayField, Properties } from '../../../lib';
import { Filters, SortType, AdvancedSearchDTO } from '../types';

export class AdvancedSearchDTOBuilder {
  private _Properties: Properties;
  private _Filters: Filters;
  private _DisplayFields: DisplayField[];
  private _SortFields: SortType[];

  withProperties(value: Properties): AdvancedSearchDTOBuilder {
    this._Properties = value;
    return this;
  }

  withFilters(value: Filters): AdvancedSearchDTOBuilder {
    this._Filters = value;
    return this;
  }

  withDisplayFields(value: DisplayField[]): AdvancedSearchDTOBuilder {
    this._DisplayFields = value;
    return this;
  }

  withSortFields(value: SortType[]): AdvancedSearchDTOBuilder {
    this._SortFields = value;
    return this;
  }

  build(): AdvancedSearchDTO {
    return {
      Properties: this._Properties,
      Filters: this._Filters,
      DisplayFields: this._DisplayFields,
      SortFields: this._SortFields,
    };
  }
}
