<button mat-button [matMenuTriggerFor]="menu" class="toggle-menu-btn">
    <fa-icon [icon]="['far', 'user']"></fa-icon>
    {{ currentUser ? currentUser.FirstName + " " + currentUser.LastName : "" }}
    <fa-icon [icon]="['fal', 'chevron-down']"></fa-icon>
</button>
<mat-menu #menu="matMenu">
    <button *ngIf="!isPublicationEntity" mat-menu-item class="menu-button" (click)="goToAccount()" >
        <img src="./assets/icons/user.svg" class="fontawesome-icon-custom" alt="user">
        <span>{{ 'common.account' | translate }}</span>
    </button>
    <button  mat-menu-item class="menu-button" *ngIf="isCataloguePage$ | async as catalogue" (click)="openCataloguesManagement(catalogue)">
        <span>{{ 'publication.header.catalogue-management' | translate }}</span>
    </button>
    <button  mat-menu-item class="menu-button" (click)="onLogout()">
        <img src="./assets/icons/arrow-right.svg" class="fontawesome-icon-custom" alt="logout">
        <span>{{ 'common.logout' | translate }}</span>
    </button>
</mat-menu>
