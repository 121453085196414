import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {LocalStorageService} from 'src/app/graphics/services/local-storage.service';
import {LanguageActions} from '../actions/language.action';

@Injectable()
export class LanguageEffect {
    constructor(
        private actions$: Actions,
        private localStorageService: LocalStorageService) {
    }

    fetchLang$ = createEffect(() => this.actions$.pipe(
        ofType(LanguageActions.fetchSelectedLanguage),
        switchMap(() => {
            return this.localStorageService.getUiLanguage$()
                .pipe(map(language => {
                    return LanguageActions.fetchSelectedLanguageSuccess({language});
                }));
        })
    ));
}
