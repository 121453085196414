import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Constantes, DataMocker} from '@nextpage/np-sdk-data';
import {Observable} from 'rxjs';
import {TokenService} from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class SdkInterceptorService extends DataMocker {
  /**
   * Url de base du site web à modifier en fonction des besoins du développeur
   * ATTENTION, il doit y avoir un / à la fin
   */
  private _baseUrl: string;

  /**
   * Header contenant le token =>à modifier en fonction des besoins du développeur
   */
  private headers: HttpHeaders;

  private fileHeaders: HttpHeaders;

  constructor(private _constantes: Constantes, private _tokenService: TokenService) {
    super();
  }

  mockResponse(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._baseUrl = this._constantes.baseUrl;

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      ApiToken: this._getToken(),
    });

    this.fileHeaders = new HttpHeaders({
      ApiToken: this._getToken(),
    });

    let newRequest: HttpRequest<unknown>;

    if (request.url.indexOf('/api/sdk/Import/Product/GenerateImport') > -1 || request.url.indexOf('GenerateMapping') > -1) {
      newRequest = request.clone({
        url: this._baseUrl + request.url.replace('/sdk/', '/sdk-debug/'),
        headers: this.fileHeaders
      });
      return next.handle(newRequest);
    }

    if (request.url.startsWith('/api')) {
      const regex = /sdk/gi;
      const url = request.url.replace(regex, 'sdk-debug');
      newRequest = request.clone({
        url: this._baseUrl + url,
        headers: this.headers,
      });

      return next.handle(newRequest);
    }

    return next.handle(request);
  }

  getData(): any {}

  getUrlPattern(): string {
    return '';
  }

  private _getToken() {
    return this._tokenService.getToken();
  }
}
