import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NpDownloadService} from '@nextpage/np-sdk-data';
import {ProductSummary} from '@app/models';
import {removeExtension} from '../../../core/functions/media.functions';

@Component({
  selector: 'app-dialog-media-sheet',
  templateUrl: './dialog-media-sheet.component.html',
  styleUrls: ['./dialog-media-sheet.component.scss']
})
export class DialogMediaSheetComponent {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
      private _downloadService: NpDownloadService
  ) {
  }

  print() {
    window.print();
  }

  downLoad(fileUrl: string) {
    this._downloadService.downloadFile(fileUrl, removeExtension(this.data?.label));
  }
}
