import {DEFAULT_SEPARATOR, SpDicoCarac} from '../constants';
import {NpUser} from '../../../lib';
import * as _ from 'lodash';

export class UserInfoHelper {
    static getField(fieldExtId: SpDicoCarac, userInfo: NpUser) {
        return userInfo && fieldExtId ?
            userInfo.CustomFields.find(x => x.ExtID === fieldExtId) :
            null;
    }

    static getFieldValue(fieldExtId: SpDicoCarac, userInfo: NpUser) {
        return _.head(this.getField(fieldExtId, userInfo)?.Values || []);
    }

    static getDashboardQueries(userInfo: NpUser) {
        return this.getFieldValue(SpDicoCarac.CP_REQUETESACCUEIL, userInfo)?.Value?.split(DEFAULT_SEPARATOR) || [];
    }


    static getAdvancedSearchId(userInfo: NpUser): number {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_SCOPE_MEDIAS, userInfo);
        return fieldValues &&
        fieldValues.Values?.length > 0
        && fieldValues.Values[0] ?
            Number(fieldValues.Values[0].Value) : null;
    }

    static getChannelScopeExtId(userInfo: NpUser): string {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_EXTID_CHANNEL, userInfo);
        return fieldValues &&
        fieldValues.Values?.length > 0
        && fieldValues.Values[0] ? fieldValues.Values[0].Value : null;
    }

    static getFamilyScopeExtId(userInfo: NpUser): string {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_EXT_ID_PRODUIT, userInfo);
        return fieldValues &&
        fieldValues.Values?.length > 0
        && fieldValues.Values[0] ? fieldValues.Values[0].Value : null;
    }


    static getMainVisualCaracExtId(userInfo: NpUser) {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_EXT_ID_VISUEL, userInfo);
        return fieldValues && fieldValues.Values?.length > 0 && !!fieldValues.Values[0] ?
            fieldValues.Values[0].Value
            : null;
    }

    static getParentId(userInfo: NpUser) {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_EXTID_CHANNEL, userInfo);
        return fieldValues && fieldValues.Values?.length > 0 && !!fieldValues.Values[0] ?
            fieldValues.Values[0].Value
            : null;
    }

    static getPreviewCaracs(userInfo: NpUser | undefined) {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_LISTE_EXTID_CARAC, userInfo);
        return fieldValues && fieldValues.Values?.length > 0 ?
            fieldValues.Values.map(field => field.Value) :
            [];
    }

    static getPublicationChannelScopeExtId(userInfo: NpUser): string {
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_EXTID_CANAL_PUBLICATION, userInfo);
        return fieldValues &&
        fieldValues.Values?.length > 0
        && fieldValues.Values[0] ? fieldValues.Values[0].Value : null;
    }

    static getPublicationCataloguesFolderId(userInfo: NpUser): number | string {
        if(_.isNil(userInfo)) {
            return null;
        }
        const fieldValues = UserInfoHelper.getField(SpDicoCarac.CP_ID_DOSSIER_CATALOGUES, userInfo);
        return fieldValues &&
        !_.isEmpty(fieldValues)
        && fieldValues.Values[0] ? parseInt(fieldValues.Values[0].Value) : '';
    }
}


