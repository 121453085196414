import {Injectable} from '@angular/core';

import {
  Constantes,
  DicoCarac,
  NPCaracStd,
  NPElement,
  ValueOneElementHelper,
} from '@nextpage/np-sdk-data';
import {Platform} from '@angular/cdk/platform';
import {TokenService} from '../../app/data/services/token.service';

@Injectable()
export class FileReaderService {
  protected _urlFileHandlerMedia: string;
  protected _urlFileHandlerMediaThumb: string;
  protected readonly _valueHelper: ValueOneElementHelper;

  constructor(private constantes: Constantes,  private tokenService: TokenService, private _platform?: Platform) {
    if (constantes.token == null || constantes.token === '') {
      this._urlFileHandlerMedia = '/filehandler.ashx?cd=inline&t=original&ft=undefined&p=';
      this._urlFileHandlerMediaThumb = '/filehandler.ashx?cd=inline&t=thumb&ft=undefined&p=';
    } else {
      this._urlFileHandlerMedia = constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${constantes.token}&cd=inline&t=original&ft=undefined&p=`;
      this._urlFileHandlerMediaThumb = constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${constantes.token}&cd=inline&t=thumb&ft=undefined&p=`;
    }
    this._valueHelper = new ValueOneElementHelper();
  }



  toUrlMedia(media: NPElement) {
    const value = this._valueHelper.getCaracValue(media, {
      DicoCaracExtID: DicoCarac.MEDIA_FILE,
    }) as NPCaracStd;
    if (value != null) {
      return (
        this._urlFileHandlerMedia +
        (<NPCaracStd>this._valueHelper.getCaracValue(media, {
          DicoCaracExtID: DicoCarac.MEDIA_FILE,
        })).Value
      );
    } else {
      return '';
    }
  }

  toFilePath(path: string) {
    return path && path !== '' ? (
      `${this.constantes.baseUrl}/filehandlerapi.ashx?authtoken=${this.tokenService.getToken()}&cd=inline&t=thumb&ft=&p=${path}`
    ) : '';
  }
  toFilePathToDownload(path: string) {
    return (
      `${this.constantes.baseUrl}/filehandlerapi.ashx?authtoken=${this.tokenService.getToken()}&cd=attachment&t=original&ft=&p=${path}`
    );
  }
  toFilePathToDownloadCustomSize(path: string, size: string) {
    return (
      `${this.constantes.baseUrl}/filehandlerapi.ashx?authtoken=${this.tokenService.getToken()}&cd=inline&t=${size}&ft=&p=${path}`
    );
  }
  
  toTemplateFilePath(path: string) {
    return path && path !== '' ? (
      `${this.constantes.baseUrl}/filehandlerapi.ashx?authtoken=${this.tokenService.getToken()}&cd=inline&ft=workingfile&p=${path}`
    ) : '';
  }

  toTemplateFilePathWithFullPath(path: string) {
    return path && path !== '' ? (
      `${this.constantes.baseUrl}/filehandlerapi.ashx?authtoken=${this.tokenService.getToken()}&cd=attachment&ft=workingfile&p=Publication/modeles/jpg/${path}`
    ) : '';
  }

  toUrlThumbImage(media: NPElement) {
    const value = this._valueHelper.getCaracValue(media, {
      DicoCaracExtID: DicoCarac.MEDIA_FILE,
    }) as NPCaracStd;
    if (value != null) {
      return (
        this._urlFileHandlerMediaThumb +
        (<NPCaracStd>this._valueHelper.getCaracValue(media, {
          DicoCaracExtID: DicoCarac.MEDIA_FILE,
        })).Value
      );
    } else {
      return '';
    }
  }

  toUrlCustomImage(media: NPElement, dicoCaracExtID: string) {
    const value = this._valueHelper.getCaracValue(media, {
      DicoCaracExtID: DicoCarac.MEDIA_FILE,
      Links: [dicoCaracExtID],
    }) as NPCaracStd;
    if (value != null) {
      return (
        this._urlFileHandlerMedia +
        (<NPCaracStd>this._valueHelper.getCaracValue(media, {
          DicoCaracExtID: DicoCarac.MEDIA_FILE,
          Links: [dicoCaracExtID],
        })).Value
      );
    } else {
      return '';
    }
  }

  toUrlCustomImageThumbnail(media: NPElement, dicoCaracExtID: string) {
    const caracValue = this._valueHelper.getCaracValue(media, {
      DicoCaracExtID: dicoCaracExtID,
      Links: [],
    }) as NPCaracStd;
    if (caracValue) {
      return this._urlFileHandlerMedia + (<NPCaracStd>caracValue).Value;
    } else {
      return '';
    }
  }

  toUrlCustomImageFromPath(path: string) {
    return this._urlFileHandlerMedia + path;
  }

  toUrlCustomImageThumbFromPath(path: string) {
    return this._urlFileHandlerMediaThumb + path;
  }

  getUrlMedia(key: string) {
    return this[key];
  }
  isImage(file: File) {
    return (
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg'
    );
  }
}
