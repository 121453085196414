import {createReducer, on} from '@ngrx/store';
import * as _ from 'lodash';
import { NPElement } from 'src/lib';
import {PublicationChaptersActions} from '@store/publication';
import { Chapter } from '@app/models';

export const publicationChaptersFeatureKey = 'publicationChapters';

export interface PublicationChaptersState {
    chapters: NPElement[];
    channelCategoriesAsChapters?: Chapter[];
    chaptersWithoutIntroAndLast?: Chapter[];
    remainingChapters?: Chapter[];
    hasChapters: boolean;
    errorMessage: any;
    loading: boolean;
    hasError: boolean;
}

export const initialState: PublicationChaptersState = {
    chapters: null,
    channelCategoriesAsChapters: null,
    chaptersWithoutIntroAndLast: null,
    remainingChapters: null,
    hasChapters: false,
    errorMessage: null,
    loading: false,
    hasError: false
};


export const publicationChaptersReducer = createReducer(
    initialState,
    on(
        PublicationChaptersActions.fetchPublicationChapters,
        (state, ): PublicationChaptersState => ({
            ...state,
            chapters: null,
            hasChapters: false,
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        PublicationChaptersActions.fetchPublicationChaptersSuccess, (state, {chapters}): PublicationChaptersState => ({
            ...state,
            chapters,
            hasChapters: !_.isEmpty(chapters),
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(PublicationChaptersActions.fetchPublicationChaptersFailure,
        (state, {message}) => ({
            ...state,
            chapters: null,
            hasChapters: false,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
