import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TreeNodeCatalog } from "@app/models";

@Component({
    selector: 'app-dialog-catalogue-deletion',
    templateUrl: './dialog-catalogue-deletion.component.html',
    styleUrls: ['./dialog-catalogue-deletion.component.scss']
})
export class DialogCatalogueDeletionComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: TreeNodeCatalog) {}

}
