import {Component, Input, OnChanges, OnInit} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {HtmlEditorHelper} from '../../../helpers/html-editor-helper';
import {CaracInfo} from '../Model';
import {CaracConfig, ElementWriterService, NPCaracStd} from '@nextpage/np-sdk-data';

@Component({
  selector: 'lib-np-value-html-write-view',
  templateUrl: './np-value-html-write-view.component.html',
  styleUrls: ['./np-value-html-write-view.component.css']
})
export class NpValueHtmlWriteViewComponent implements OnInit, OnChanges {

  @Input() caracInfo: CaracInfo;
  @Input() value: NPCaracStd;
  @Input() caracConfig: CaracConfig;

  public readonlyMode = false;
  public ckeConfig = HtmlEditorHelper.getCkeConfig();
  public inputOutputContent = '';

  public Editor = ClassicEditor;

  constructor(private _elementWriter: ElementWriterService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // Initialisation de la valeur contenue dans l'élement
    if (this.value && this.value.Value) {
      this.inputOutputContent = this.value.Value;
    }
  }


  onChange($event) {
    if (this.value && this.value.Element && this.caracConfig && this.caracConfig.DicoCaracExtID) {
      this._elementWriter.setValueText(this.value.Element, this.caracConfig.DicoCaracExtID, $event.editor.getData());
    }
  }
}
