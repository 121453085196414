<!--<div class="table-research-block">-->
<!--    <lib-link-style-array-search [caracConfig]="researchConfig" [element]="value.Element" (valueSelected)="onValueSelected($event)"></lib-link-style-array-search>-->
<!--</div>-->

<div class="table-responsive articles-table">
    <table class="table">
        <thead>
        <tr>
            <th *ngFor="let column of columns">{{column.Label}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tableRow of elements" [class]="isFull(tableRow.element) ? 'fillRateInfo': ''"
            (mouseover)="tableRow.over = true" (mouseleave)="tableRow.over = false">
            <td *ngFor="let column of columns">
                <lib-app-value-view [element]="tableRow.element"
                                    [caracIdStateValue]="getCISV(tableRow.element, column)"></lib-app-value-view>
            </td>
            <td class="delete-block" title="Cliquez pour supprimer cette ligne">
                <mat-icon [class.hide-me]="!tableRow.over" [class.display-me]="tableRow.over" (click)="delete(tableRow)">delete</mat-icon>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="add-btn-block">
        <mat-icon (click)="addElement()">add_circle</mat-icon>
    </div>
</div>
