import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NpMediaLibraryComponent} from '../../graphics/np-media-library/np-media-library.component';
import {filter, first} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MediaLibraryService {

    constructor(private _dialog: MatDialog) {
    }

    public open(dicoCaracExtID: string, selectedMediaID = -1, conf?: { panelClass: string }) {
        return this._dialog.open(NpMediaLibraryComponent, {
            width: '80%',
            height: '80%',
            data: {
                selectedMediaID: selectedMediaID,
                dicoCaracExtID: dicoCaracExtID
            },
            panelClass: conf ? conf.panelClass : ''
        }).afterClosed().pipe(
            first(),
            filter(picture => picture !== undefined)
        );
    }
}
