<div [ngClass]="customClass">
    <ng-container>
        <mat-toolbar class="mat-elevation-z2">
            <div class="d-flex align-items-center">
                <button class="burger-button"
                        mat-icon-button
                        *ngIf="sidenav?.mode === 'over'"
                        (click)="sidenav.toggle()">
                    <mat-icon *ngIf="!sidenav?.opened"> menu</mat-icon>
                    <mat-icon *ngIf="sidenav?.opened"> close</mat-icon>
                </button>
                <span class="content-logo mr-4">
                    <ng-content select="[logoSupplier]"></ng-content>
                </span>
                <ng-content select="[logoHeadline]"></ng-content>
            </div>
            <div class="d-flex align-items-center">
                <ng-content select="[favorite]"></ng-content>
                <ng-content select="[languageSelector]"></ng-content>
                <ng-content select="[catalogueActions]"></ng-content>
                <ng-content select="[userName]"></ng-content>
                <ng-content select="[pdfGuide]"></ng-content>
            </div>
        </mat-toolbar>
    </ng-container>

    <mat-sidenav-container [ngClass]="{'h-100': isHomepagePublicationPortal$ | async}">
        <mat-sidenav *ngIf="!(isHomepagePublicationPortal$ | async) && !onlyPublicationPortal"
        #sidenav="matSidenav" 
        mode="side" 
        [(opened)]="opened" class="mat-elevation-z0">
            <div class="sidebar-content">
                <ng-content select="[sidebarContent]"></ng-content>
            </div>
            <div class="toggle-btn-container" *ngIf="!(isHomepagePublicationPortal$ | async)">
                <fa-icon *ngIf="opened" [icon]="['fal', 'circle-chevron-left']" class="toggle-btn" (click)="sidenav.toggle()"></fa-icon>
                <fa-icon *ngIf="!opened" [icon]="['fal', 'circle-chevron-right']" class="toggle-btn" (click)="sidenav.toggle()"></fa-icon>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="content mat-elevation-z8" [ngClass]="{'p-0': isHomepagePublicationPortal$ | async, 'catalogue-page': isCataloguePage$ | async}">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

