<div class="linked-values-container">
    <ng-container *ngIf="readOnlyAccess else waringBlock">
        <ul>
            <li *ngFor="let linked of linkedValues">
                {{linked}}
            </li>
        </ul>
    </ng-container>
    <ng-template #waringBlock>
        <span>Attention: Ce composant n'affiche que des valeurs en lectures seules !</span>
    </ng-template>
</div>
