import {createReducer, on} from '@ngrx/store';
import {SearchMedia, SearchMediaFailure, SearchMediaSuccess} from '../actions';
import {MediaSearchResult} from '../../data/models/advanced-search-result.model';

export const mediaLibraryFeatureKey = 'mediaLibrary';

export interface SearchMediaState {
    data: MediaSearchResult;
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialStateAdvancedSearch: SearchMediaState = {
    data: null,
    errorMessage: null,
    loading: false,
    hasError: false
};

export const mediaLibraryReducer = createReducer(
    initialStateAdvancedSearch,
    on(
        SearchMedia,
        (state, ): SearchMediaState => ({
            ...state,
            data: null,
            loading: true,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        SearchMediaSuccess,
        (state, {searchResult}): SearchMediaState => ({
            ...state,
            data: searchResult,
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        SearchMediaFailure,
        (state, {message}): SearchMediaState => {
            return ({
                ...state,
                data: null,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
);
