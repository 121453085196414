<div class="visuel-media">
  <!--Listing photos uploadées-->
  <div class="photo-block uploaded-img-block" *ngFor="let photo of photoUrls; index as i">
    <div class="uploaded-img-container">
      <img [src]="photo.src" [alt]="photo.alt" [title]="photo.alt" class="img-responsive" (click)="openFile(photo)"/>
      <ul class="list-unstyled img-actions">
        <li *ngIf="!readOnlyAccess">
          <a (click)="changePicture(photo)" queryParamsHandling="merge" title="Modifier">
            <img src="assets/imgs/picto-modifier-photo.png" alt="Modifier"/>
          </a>
        </li>
        <li>
        <!--  <a [href]="photo.src" title="Zoom" target="_blank"
             [attr.data-lightbox]="photo.src" [attr.data-title]="photo.alt">
            <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom"/>
          </a>-->
          <a title="Zoom" target="_blank" (click)="openFile(photo)"
             [attr.data-lightbox]="photo.src" [attr.data-title]="photo.alt">
            <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom"/>
          </a>
        </li>
        <li>
          <a title="Télécharger" (click)="downloadImage(photo)" class="download-btn">
            <img src="assets/imgs/picto-telecharger-photo.png" alt="Télécharger"/>
          </a>
        </li>
        <li *ngIf="!readOnlyAccess">
          <a [routerLink]="[]" (click)="deletePicture(photo)" queryParamsHandling="merge" title="Supprimer">
            <img src="assets/imgs/picto-supprimer-photo.png" alt="Supprimer"/>
          </a>
        </li>
      </ul>
    </div>
    <!--        <div class="text-center uploaded-img-name" [title]="photo.alt">{{photo.alt}}</div>-->
  </div>
  <!--Upload-->
  <ng-container *ngIf="!readOnlyAccess">
    <div class="photo-block photo-add-wrapper" title="Ajouter une image" (click)="addPicture();" *ngIf="canAddMore">
      <input disabled type="file" name="file" id="file" class="inputfile"/>
    </div>
  </ng-container>

</div>
<span *ngIf="!isValid" class="requied-error-message inpu-custo">Champ obligatoire</span>

<!--


<div class="file-upload-container">
  <div class="input-group">
    <ng-container *ngIf="currentMedia">
      <input type="text" class="form-control" disabled [title]="currentMedia.alt"  [value]="currentMedia.src">
    </ng-container>

    <div class="input-group-append">
      <ng-container *ngIf="currentMedia">
        <button class="btn btn-primary np-button custom-button"
                type="button"
                title="Prévisualiser le fichier"
                (click)="openFile()">
          <i class="glyphicon glyphicon-zoom-in"></i>
        </button>
        <button class="btn btn-primary np-button custom-button"
                [ngClass]="currentMedia ? '' : 'full-size'"
                type="button"
                title="Modifier cette image"
                (click)="changePicture(currentMedia)">
          <i class="glyphicon glyphicon-pencil"></i>
        </button>
        <button class="btn btn-primary np-button custom-button"
                type="button"
                title="Supprimer cette image"
                (click)="deletePicture(currentMedia)">
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </ng-container>
      <button class="btn btn-primary np-button custom-button"
              [ngClass]="currentMedia ? '' : 'full-size'"
              type="button"
              title="Ajouter une image"
              (click)="addPicture()">
        <i class="glyphicon glyphicon-plus"></i> {{currentMedia ? '' : 'Ajouter une image'}}
      </button>
    </div>
  </div>
</div>
-->
