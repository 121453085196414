import {PublicationTemplate} from '@app/models';

export class PublicationTemplateBuilder {
    private _inddFile!: string;
    private _id!: number;
    private _jpgFile!: string;
    private _xmlFile!: string;
    private _description!: string;
    private _numberOfElements!: number;

    withInddFile(value: string): PublicationTemplateBuilder {
        this._inddFile = value;
        return this;
    }

    withId(value: number): PublicationTemplateBuilder {
        this._id = value;
        return this;
    }

    withJpgFile(value: string): PublicationTemplateBuilder {
        this._jpgFile = value;
        return this;
    }

    withXmlFile(value: string): PublicationTemplateBuilder {
        this._xmlFile = value;
        return this;
    }

    withDescription(value: string): PublicationTemplateBuilder {
        this._description = value;
        return this;
    }

    withNumberOfElements(value: number): PublicationTemplateBuilder {
        this._numberOfElements = value;
        return this;
    }

    build(): PublicationTemplate {
        const publicationTemplate = new PublicationTemplate({
            inddFile: this._inddFile,
            id: this._id,
            jpgFile: this._jpgFile,
            xmlFile: this._xmlFile,
            description: this._description,
            numberOfElements: this._numberOfElements
        });

        let descriptionJSONParsed;
        if(this._description) {
            try {
                descriptionJSONParsed = JSON.parse(this._description);
            } catch (e) {
                console.error("Invalid JSON:", e);
                descriptionJSONParsed = this._description;
            }
        }

        publicationTemplate.name = descriptionJSONParsed?.Name || '';

        return publicationTemplate;
    }
}
