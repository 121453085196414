import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CaracInfo} from '../../np-value/Model';
import {CaracConfig, DicoCarac, NPCaracListe, NPElementType, NPListeValues} from '@nextpage/np-sdk-data';

@Component({
  selector: 'lib-dialog-check-simple-list',
  templateUrl: './dialog-check-simple-list.component.html',
  styleUrls: ['./dialog-check-simple-list.component.scss']
})
export class DialogCheckSimpleListComponent implements OnInit {

  public caracInfo: CaracInfo;
  public value: NPCaracListe;
  public caracConfig: CaracConfig;
  public displayValues: NPListeValues[] = [];
  public selectedValues: number[] = [];
  public dialogTitle = '';

  constructor(private _dialogRef: MatDialogRef<DialogCheckSimpleListComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data && this.data.caracInfo && this.data.value && this.data.caracConfig) {
      this.caracInfo = this.data.caracInfo;
      this.value = this.data.value;
      this.caracConfig = this.data.caracConfig;
      this.displayValues = this.caracConfig && this.caracConfig.DicoCarac ? this.caracConfig.DicoCarac.Values : [];
      this.selectedValues = (<NPCaracListe>this.value.Element.getValue(this.value.DicoCaracExtID)).Values.map(v => v.ValueID);

      const elementType = this.value.Element.ElementType === NPElementType.Product ? DicoCarac.PRODUCT_LABEL :
        this.value.Element.ElementType === NPElementType.Reference ? DicoCarac.REFERENCE_LABEL : DicoCarac.FAMILY_LABEL;
      let elementLabel = this.value.Element.getValueTextValue(elementType);
      elementLabel = elementLabel.length > 0 ? elementLabel + ' - ' : '';
      this.dialogTitle = `${elementLabel} ${this.caracConfig.Label}`;
    }
  }

  onValueChange(valueId: any) {
    if (this.isChecked(valueId)) {
      this.selectedValues = this.selectedValues.filter(v => v !== valueId);
    } else {
      this.selectedValues.push(valueId);
    }
  }

  isChecked(newValueId: number) {
    return this.selectedValues.some(valueID => valueID === newValueId);
  }

  closeMe() {
    this._dialogRef.close({saved: false, data: this.selectedValues});
  }

  saveInElement() {
    this._dialogRef.close({saved: true, data: this.selectedValues});
  }
}
