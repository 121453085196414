import {ModuleWithProviders, NgModule} from '@angular/core';
import {EqualizeDirective} from './equalize.directive';

@NgModule({
    declarations: [EqualizeDirective],
    exports: [EqualizeDirective],
    providers: []
})
export class EqualizeModule {
    static forRoot(): ModuleWithProviders<EqualizeModule> {
        return {
            ngModule: EqualizeModule,
            providers: [EqualizeDirective]
        };
    }
}
