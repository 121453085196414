import {createReducer, on} from '@ngrx/store';
import {FetchCharacteristicsFailure, FetchCharacteristicsSuccess} from './characteristics.actions';
import {NPDicoCarac} from '../../../lib';

export const characteristicsFeatureKey = 'characteristics';

export interface CharacteristicState {
    dicoCharacteristics: NPDicoCarac[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialAdvancedSearch: CharacteristicState = {
    dicoCharacteristics: [],
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const characteristicsReducer = createReducer(
    initialAdvancedSearch,
    on(
        FetchCharacteristicsSuccess,
        (state, {dicoCharacteristics}): CharacteristicState => {
            return ({
                ...state,
                dicoCharacteristics: dicoCharacteristics,
                loading: false,
                errorMessage: null
            });
        }
    ),
    on(FetchCharacteristicsFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
