import {Component, Input, OnInit} from '@angular/core';
import {NpMediaDirectory, NpMediasTreeService} from '../../../services/media-library-services/np-medias-tree.service';

@Component({
    selector: 'lib-np-medias-tree',
    templateUrl: './np-medias-tree.component.html',
    styleUrls: ['./np-medias-tree.component.scss']
})
export class NpMediasTreeComponent implements OnInit {

    @Input() currentMedia: NpMediaDirectory;
    @Input() isRoot = true;

    public medias: any[] = [];
    public currentDirectory: NpMediaDirectory;

    constructor(private _mediasTreeService: NpMediasTreeService) {
    }

    ngOnInit() {

    }

    onDirectoryClick(mediaDirectory: NpMediaDirectory) {
        this._mediasTreeService.loadChildren(mediaDirectory)
            .subscribe(() => {

            });
    }

    isSelected(selectedMediaDirectory: NpMediaDirectory) {
        return this._mediasTreeService.isSelected(selectedMediaDirectory);
    }

    hasChildrenDirectories(mediaDirectory: NpMediaDirectory) {
        return mediaDirectory
            && mediaDirectory.mediasDirectory
            && mediaDirectory.mediasDirectory.length > 0;
    }
}
