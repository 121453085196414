import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { OverlayPanel } from "primeng/overlaypanel";
import { Subscription } from "rxjs";
import { SpDicoCarac } from "src/app/data/constants";
import { DialogPageDeletionComponent } from "src/app/graphics/components/publication/dialog-page-deletion/dialog-page-deletion.component";
import { DialogPagesAdditionComponent } from "src/app/graphics/components/publication/dialog-pages-addition/dialog-pages-addition.component";
import { FetchPublicationTemplates, FetchPublicationTemplatesSuccess, PublicationTemplateState, selectPublicationTemplateView } from "@store/publication";
import { selectLoadingAuthentication } from "src/app/store/selector";
import { Chapter, PublicationTemplate, CatalogPage, PageTemplate, Catalog } from "@app/models";
import { FileReaderService } from "src/lib";
import { TemplatesTags } from "src/app/data/enums/publication-tags.enum";
import * as _ from "lodash";
import { filter, tap } from "rxjs/operators";
import { NpInstanceService } from "src/app/data/services/np-instance.service";
import { AddOrDeletePage, CatalogState, selectCatalogView } from "@store/publication";

@Component({
    selector: 'app-catalogue-template-selection-overlay',
    templateUrl: './catalogue-template-selection-overlay.component.html',
    styleUrls: ['./catalogue-template-selection-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueTemplateSelectionOverlayComponent implements OnInit, OnDestroy {
    @Input() public chapter: Chapter;
    @Input() public page: CatalogPage;

    @ViewChild('op') overlayPanel: OverlayPanel;
    @ViewChild('productsPageTitleInput') productsPageTitleInput: ElementRef;

    public loading$ = this._store.select(selectLoadingAuthentication); //TODO new store?
    public productsTemplatesView$ = this._store.select(selectPublicationTemplateView)
    .pipe(tap(templatesView => {
        if (templatesView?.hasTemplates) {
            this.setDefaultProductsTemplate(templatesView);
        }
    }));
    
    public catalogView$ = this._store.select(selectCatalogView)
    .pipe(
        filter((catalogState: CatalogState) => !!catalogState?.catalog?.chapters)
    );
      
    public templateSelectionForm: FormGroup;

    private _imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
    private _catalog: Catalog;
    private _catalogSub: Subscription;
    private _confirmPageDeletionSub: Subscription;

    constructor(
        public dialog: MatDialog,
        private _fb: FormBuilder,
        private _store: Store,
        private _fileReaderService: FileReaderService,
        private _instanceService: NpInstanceService) {
    }

    ngOnInit(): void {
        this.templateSelectionForm = this._fb.group({
            productsPageTitle: [this.page.nameWithoutTag, Validators.required],
            productsPageTemplate: ['', Validators.required]
        });

        this._catalogSub = this.catalogView$
            .pipe(
                tap((catalogState: CatalogState) => {
                    this._catalog = catalogState.catalog;
                }),
            )
            .subscribe();
    }

    public getProductsTemplates(): void {
        this.productsPageTitleInput.nativeElement.focus();
        this._store.dispatch(FetchPublicationTemplates(
            { templateCharacteristicExtID: SpDicoCarac.CP_EXTID_MODELE_PAGE_PRODUITS,
            channelCategoryExtID: this.chapter.channelCategoryExtID }
        ));
    }

    public displayDefaultImage(productsTemplate: PublicationTemplate): void {
        productsTemplate.jpgFile = this._imageTemplateByDefault;
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
        this._store.dispatch(FetchPublicationTemplatesSuccess({ publicationTemplates: null }));
    }

    public submitTemplateSelection(): void {
        const formControls = this.templateSelectionForm.controls;
        const productsPageTemplate = formControls.productsPageTemplate.value;

        this.page.name = `${TemplatesTags.PRODUCT} - ${formControls.productsPageTitle.value}`;
        this.page.nameWithoutTag = formControls.productsPageTitle.value;

        this.page.template = {
            id: productsPageTemplate.id,
            completePath: productsPageTemplate.jpgFile || this._imageTemplateByDefault,
            numberOfElements: productsPageTemplate.numberOfElements
        } as PageTemplate;

        this.overlayPanel.hide();
    }

    public confirmPageDeletion(): void {
        this._confirmPageDeletionSub = this.dialog.open(DialogPageDeletionComponent, {
            panelClass: "page-deletion-dialog"
        }).afterClosed()
            .subscribe(confirmDeletion => {
                if (confirmDeletion
                    && this.chapter?.pages
                    && this.page) {
                    _.remove(this.chapter.pages, this.page);

                    const blankPages = this.chapter.pages.filter(page => page.name.includes(TemplatesTags.BLANK));
                    if(blankPages?.length > 0) {
                        blankPages.forEach(blankPage => _.remove(this.chapter.pages, blankPage));
                    }

                    if(this._catalog) {
                        const chapterIndex = _.findIndex(this._catalog.chapters, { id: this.chapter.id });
                        if (chapterIndex !== -1) {
                            _.set(this._catalog.chapters, chapterIndex, this.chapter);
                        }
                    
                        this._store.dispatch(AddOrDeletePage({catalog: this._catalog}));
                    }
                }
            });
    }

    public openPagesAddition(): void {
        this.dialog.open(DialogPagesAdditionComponent, {
            panelClass: "add-pages-dialog",
            data: { chapter: this.chapter, page: this.page }
        });
    }

    private setDefaultProductsTemplate(templatesView: PublicationTemplateState): void {
        const firstProductsTemplate = templatesView?.publicationTemplates[0];
        if (firstProductsTemplate) {
            this.templateSelectionForm.patchValue({ productsPageTemplate: firstProductsTemplate });
        }
    }

    ngOnDestroy(): void {
        this._confirmPageDeletionSub?.unsubscribe();
        this._catalogSub?.unsubscribe();
    }
}
