import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TableHelper} from '../../data/helpers/table.helper';
import {productsFeatureKey, ProductsState} from './products.reducer';

const selectElementsState = createFeatureSelector<ProductsState>(productsFeatureKey);

// Return product-table component products
export const selectProductsElements = createSelector(
    selectElementsState,
    state => ({
        ...state, productWithTotalRow: {
            productSummaries: TableHelper.buildMatTableDataSource(state.productWithTotalRow?.productSummaries || []),
            totalsRows: state.productWithTotalRow?.totalsRows
        }
    })
);
