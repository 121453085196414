import {Component, forwardRef, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    Access,
    CaracConfig,
    DicoCarac,
    ElementWriterService,
    NPCaracLien,
    NPCaracValeur,
    NpDownloadService,
    NPElement,
    TypeCode
} from '@nextpage/np-sdk-data';
import {CaracInfo, PhotosInfos} from '../np-value/Model';
import {MatDialog} from '@angular/material/dialog';
import {DialogFilePreviewComponent} from '../dialog-file-preview/dialog-file-preview.component';
import {FileReaderService, MediaLibraryService} from '../../services';
import {VisualMediaGalleryComponent} from '../visual-media-v2/visual-media-gallery/visual-media-gallery.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {saveAs} from 'file-saver';

@Component({
    selector: 'lib-np-file-upload',
    templateUrl: './np-file-upload.component.html',
    styleUrls: ['./np-file-upload.component.scss']
})
export class NpFileUploadComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() element: NPElement;
    @Input() caracConfig: CaracConfig;

    public currentMedia: NPCaracValeur;
    public isImage: boolean;
    public currentPhoto: PhotosInfos;
    public readOnlyAccess: boolean;
    public fileSource: SafeResourceUrl;

    constructor(private _dialog: MatDialog,
                private _elementWriter: ElementWriterService,
                private _fileReader: FileReaderService,
                private _sanitizer: DomSanitizer,
                private _downloadService: NpDownloadService,
                @Inject(forwardRef(() => MediaLibraryService)) private _mediaLibraryService: MediaLibraryService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._update();
    }

    ngOnInit() {
        // this._update();
    }

    addFile() {
        this._mediaLibraryService.open(this.value.DicoCaracExtID, -1, {
            panelClass: 'file-preview-custom-dialog'
        })
            .subscribe((result: NPElement) => {
                this.currentMedia = result.getValue(DicoCarac.MEDIA_FILE);
                if (this.currentMedia) {
                    this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, this.currentMedia.Value);
                    this._update();
                }
            });
    }

    hasFile() {
        return this.currentMedia && this.currentMedia.Value !== '';
    }

    remove() {
        this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, '');
        this._update();
    }

    private _update() {
        if (this.caracConfig && this.caracConfig.DicoCarac && this.element) {
            this.currentMedia = this.element.getValue(this.caracConfig.DicoCaracExtID);
            this.readOnlyAccess = Number(this.caracConfig.DicoCarac.Editable) === Access.LECTURESEULE;
            this.isImage = this.caracConfig
                && this.caracConfig.DicoCarac
                && this.caracConfig.DicoCarac.TypeCode === TypeCode.IMG_NP;

            if (this.currentMedia && this.currentMedia.Value !== '') {
                this.currentPhoto = new PhotosInfos();
                this.currentPhoto.src = this._fileReader.toUrlCustomImageFromPath(this.currentMedia.Value);
                this.currentPhoto.thumbSrc = this._fileReader.toUrlCustomImageThumbFromPath(this.currentMedia.Value);
                this.currentPhoto.alt = this.currentMedia.Value;
                this.fileSource = this.isImage ? this._sanitizer.bypassSecurityTrustResourceUrl(this._fileReader.toUrlCustomImageFromPath(this.currentMedia.Value)) : undefined;

            } else {
                this.currentPhoto = undefined;
            }
        }

    }

    public openFile() {
        // Si carac IMG
        if (this.isImage) {
            this._dialog.open(VisualMediaGalleryComponent, {
                minWidth: '500px',
                data: {photos: this.currentMedia, value: this.value, caracConfig: this.caracConfig, isImage: true},
            }).afterClosed()
                .subscribe(() => {
                    this._update();
                });
        } else { // Ss carac Fichier
            this._openFilePreview();
        }

    }

    downloadImage() {
        if (this.isImage) {
            this._downloadService.getBase64ImageFromURL(this.currentPhoto.src)
                .subscribe(base64data => {
                    const blob = this._downloadService.getDownload(base64data);
                    saveAs(blob, this.currentPhoto.src);
                });
        } else {
            this._openFilePreview();
        }
    }

    private _openFilePreview() {
        this._dialog.open(DialogFilePreviewComponent, {
            height: '70%',
            width: '50%',
            data: {currentMedia: this.currentMedia},
        });
    }
}
