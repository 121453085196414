import { CatalogPage, Chapter } from '@app/models';
import { ChaptersTags } from '../../enums/publication-tags.enum';

export class ChapterBuilder {
    private _name: string;
    private _id: string;
    private _channelCategoryID: number;
    private _channelCategoryExtID: string;
    private _pages: CatalogPage[];

    withId(value: string): ChapterBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): ChapterBuilder {
        this._name = value;
        return this;
    }

    withChannelCategoryID(value: number): ChapterBuilder {
        this._channelCategoryID = value;
        return this;
    }

    withChannelCategoryExtID(value: string): ChapterBuilder {
        this._channelCategoryExtID = value;
        return this;
    }

    withPages(value: CatalogPage[]): ChapterBuilder {
        this._pages = value;
        return this;
    }

    build(): Chapter {
        const chapter = new Chapter(this._pages);
        chapter.name = this._name;
        chapter.id = this._id;
        chapter.channelCategoryID = this._channelCategoryID;
        chapter.channelCategoryExtID = this._channelCategoryExtID;

        const chapterNameSplitted = chapter.name.split(" - ");
        chapter.nameWithoutTag = chapterNameSplitted[1] ?? chapter.name;
        chapter.isEndChapter = chapter.name.includes(ChaptersTags.END);
        chapter.isIntroductionChapter = chapter.name.includes(ChaptersTags.INTRO);

        return chapter;
    }
}
