import {Component, Inject} from '@angular/core';
import {DesignComponent} from '../base/design.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MessageModel} from '@data/core';

@Component({
    selector: 'app-dialog-message-view',
    templateUrl: './dialog-message-view.component.html',
    styleUrls: ['./dialog-message-view.component.scss']
})
export class DialogMessageViewComponent extends DesignComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: MessageModel) {
        super();
    }

}
