import {Injectable} from '@angular/core';
import {ElementWriterService} from '@nextpage/np-sdk-data';

@Injectable({
  providedIn: 'root'
})
export class SpElementWriterService {

  constructor(private _elementWriterService: ElementWriterService) {
  }

  public pushToNextPage() {
    return this._elementWriterService.publishToNP();
  }

  public hasModifications() {
    return this._elementWriterService.hasModifications();
  }

}
