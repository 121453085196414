import {Injectable} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectElementPreviewConfigs} from '../../store/selector/user-info.selectors';
import {selectAllDicoCharacteristics} from '@store/characteristics';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class UserInfoFacade {

    constructor(private _store: Store) {
    }

    getCaracsPreview() {
        return combineLatest([
            this._store.select(selectElementPreviewConfigs),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            map(([previewParams, dicoCaracs]) => {
                const userDicoCaracs = previewParams.previewCaracs.map(previewCarac => {
                    return dicoCaracs.find(dicoCarac => dicoCarac.ExtID === previewCarac);
                });

                const paths = userDicoCaracs
                    .filter(characteristic =>
                        characteristic?.ExtID !== previewParams.mainVisualCaracExtId
                        && characteristic?.TypeCode.includes('LIEN'))
                    .map(characteristic => [characteristic.ExtID, previewParams.mainVisualCaracExtId]);

                paths.push([previewParams.mainVisualCaracExtId]);

                return ({dicoCaracs: _.compact(userDicoCaracs), paths: paths});
            })
        );
    }
}
