import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChapterPortal, NpCatalogService } from '../../../../lib';
import * as _ from 'lodash';
import { CreateCatalog, CatalogActionsFailure, CatalogActionsSuccess, GetCatalog, GetCatalogFailure, GetCatalogSuccess, EditCatalog, GenerateCatalog, SaveCatalog } from '@store/publication';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';
import { RouteResolverService } from 'src/app/data/services/routes/route-resolver.service';
import { DialogService } from 'src/app/graphics/services/dialog.service';
import { toCatalogModel, toCatalogConfigModel, toCatalogPortal } from '@np/utils';

@Injectable()
export class CatalogEffect {
    constructor(
        private actions$: Actions,
        private _catalogService: NpCatalogService,
        private _routeResolverService: RouteResolverService,
        private _dialogService: DialogService) {
    }

    getCatalogEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetCatalog),
            switchMap((action) => {
                return this._catalogService.getCatalogById(action.id).pipe(
                    map(catalogPortal => {
                        const catalogPortaltoCatalogModel = toCatalogModel(catalogPortal);
                        return GetCatalogSuccess({ catalog: catalogPortaltoCatalogModel });
                    }),
                    catchError(error => {
                        return of(GetCatalogFailure({ message: error }))
                    })
                );
            })
        );
    });

    createCatalogEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CreateCatalog),
            switchMap((action) => {
                return this._catalogService.createCatalog(action.catalogInfo).pipe(
                    map(catalogConfigPortal => {
                        const catalogConfigPortaltoCatalogConfigModel = toCatalogConfigModel(catalogConfigPortal);

                        const chaptersWithoutIntroAndLast = catalogConfigPortaltoCatalogConfigModel.structure.chapters?.filter(chapter =>
                            !chapter.isIntroductionChapter && !chapter.isEndChapter) || [];
                        const existingChaptersExtIds = new Set(
                            chaptersWithoutIntroAndLast.map(chapter => chapter.channelCategoryExtID)
                        );
                        const selectedChaptersSet = new Set(catalogConfigPortaltoCatalogConfigModel.selectedChapters);
                        const diff = [
                            ...catalogConfigPortaltoCatalogConfigModel.selectedChapters.filter(chapterExtId => !existingChaptersExtIds.has(chapterExtId)),
                            ...[...existingChaptersExtIds].filter(chapterExtId => !selectedChaptersSet.has(chapterExtId))
                        ];

                        this._routeResolverService.navigateToCurrentEntityRouteWithParams(
                            RoutePagesName.CATALOGUE,
                            { id: catalogConfigPortaltoCatalogConfigModel.catalogId },
                            true);

                        if (diff.length > 0) {
                            this._dialogService.showErrorMessage('publication.catalogue.edition-chapters-warning', 5000);
                        }
                        return CatalogActionsSuccess({ catalogConfig: catalogConfigPortaltoCatalogConfigModel });
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-creation-error');
                        return of(CatalogActionsFailure({ message: error, catalog: null }))
                    })
                );
            })
        );
    });

    editCatalogEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EditCatalog),
            switchMap((action) => {
                const catalogModelToCatalogPortal = toCatalogPortal(action.catalogConfig.structure);
                return this._catalogService.editCatalog(action.catalogConfig, catalogModelToCatalogPortal).pipe(
                    map(catalogConfigPortal => {
                        const catalogConfigPortaltoCatalogConfigModel = toCatalogConfigModel(catalogConfigPortal);
                        const chaptersWithoutIntroAndLast = catalogConfigPortaltoCatalogConfigModel.structure.chapters?.filter(chapter =>
                            !chapter.isIntroductionChapter && !chapter.isEndChapter) || [];
                        const existingChaptersExtIds = new Set(
                            chaptersWithoutIntroAndLast.map(chapter => chapter.channelCategoryExtID)
                        );
                        const selectedChaptersSet = new Set(catalogConfigPortaltoCatalogConfigModel.selectedChapters);
                        const diff = [
                            ...catalogConfigPortaltoCatalogConfigModel.selectedChapters.filter(chapterExtId => !existingChaptersExtIds.has(chapterExtId)),
                            ...[...existingChaptersExtIds].filter(chapterExtId => !selectedChaptersSet.has(chapterExtId))
                        ];

                        if (diff.length > 0) {
                            this._dialogService.showErrorMessage('publication.catalogue.edition-chapters-warning', 5000);
                        }
                        return CatalogActionsSuccess({ catalogConfig: catalogConfigPortaltoCatalogConfigModel });
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-edition-error');
                        return of(CatalogActionsFailure({ message: error, catalog: action.catalogConfig.structure }))
                    })
                );
            })
        );
    });

    generateCatalogEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenerateCatalog),
            switchMap((action) => {
                const catalogModelToCatalogPortal = toCatalogPortal(action.catalogConfig.structure);
                                
                return this._catalogService.generateCatalog(action.catalogConfig, catalogModelToCatalogPortal).pipe(
                    map(() => {
                        const pagesCount =  _.sumBy(catalogModelToCatalogPortal.Chapters, (Chapter: ChapterPortal) => _.size(Chapter.Pages || []));
                        if(pagesCount % 4 !== 0) {
                            //If the number of pages is not a multiple of 4, show alert message
                            this._dialogService.showSuccessMessage('publication.header.catalogue-generated', 'publication.header.catalogue-pages-nb-alert');
                        } else {
                            this._dialogService.showSuccessMessage('publication.header.catalogue-generated');
                        }
                        return CatalogActionsSuccess({ catalogConfig: action.catalogConfig });
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.header.catalogue-generation-error');
                        return of(CatalogActionsFailure({ message: error, catalog: action.catalogConfig.structure }))
                    })
                );
            })
        );
    });

    saveCatalogEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SaveCatalog),
            switchMap((action) => {
                const catalogModelToCatalogPortal = toCatalogPortal(action.catalogConfig.structure);
                                
                return this._catalogService.saveCatalog(action.catalogConfig, catalogModelToCatalogPortal).pipe(
                    map(() => {
                        this._dialogService.showSuccessMessage('publication.header.catalogue-saved');

                        return CatalogActionsSuccess({ catalogConfig: action.catalogConfig });
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.header.catalogue-save-error');
                        return of(CatalogActionsFailure({ message: error, catalog: action.catalogConfig.structure }))
                    })
                );
            })
        );
    });
}
