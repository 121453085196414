import {AdvSearchArgs, NPElementType} from '@nextpage/np-sdk-data';
import * as _ from 'lodash';

/**
 * Vérification de la compatibité entre le(s) type(s) d'Element
 * renseignés dans la recherche avancée
 * et celui de la configuration du portail dans NP_INSTANCE_CONFIG
 * @param advancedSearches
 * @param instanceElementType
 */
export function getIncompatibleAdvSearches(advancedSearches: AdvSearchArgs[], instanceElementType: NPElementType) {
    return advancedSearches
        .reduce((accumulator, advancedSearch) => {
            const incompatibleElementTypes = [
                advancedSearch.Config.Filters.ElementType,
                ...advancedSearch.Config.Filters.ElementTypes || []
            ].filter(elementType => elementType !== instanceElementType);

            if (!_.isEmpty(incompatibleElementTypes)) {
                accumulator.set(advancedSearch.Config.Properties.Name, _.compact(incompatibleElementTypes));
            }

            return accumulator;
        }, new Map<string, NPElementType[]>());
}
