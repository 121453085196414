export enum EntityName {
    Consultation = 'consultation',
    MediaLibrary = 'media-library',
    Supplier = 'supplier',
    Publication = 'publication'
}

export const ENTITY_NAME_MAP: Map<EntityName, { name: EntityName, label: string, description: string }> = new Map(
    [
        [EntityName.Consultation,
            {
                name: EntityName.Consultation,
                label: 'enums.portals.consultation',
                description: 'Accéder au portail consultation'
            }
        ],
        [EntityName.MediaLibrary,
            {
                name: EntityName.MediaLibrary,
                label: 'enums.portals.media-library',
                description: 'Accéder à la médiathèque'
            }
        ],
        [EntityName.Supplier,
            {
                name: EntityName.Supplier,
                label: 'enums.portals.supplier',
                description: 'Accéder au portail fournisseur'
            }
        ],
        [EntityName.Publication,
            {
                name: EntityName.Publication,
                label: 'enums.portals.publication',
                description: 'Accéder au portail publication'
            }
        ],
    ]
);
