import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
    public message = 'Aucune information à afficher.';

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {message: string}) {
    }

    ngOnInit(): void {
        if (this.data && this.data.message && this.data.message.trim() !== '') {
            this.message = this.data.message;
        }
    }
}
