import {createReducer, on} from '@ngrx/store';
import {AdvancedSearchFailure, AdvancedSearchSuccess, FetchAdvancedSearch} from '../actions';
import {AdvancedSearchHelper} from '../../data/helpers/advanced-search.helper';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';

export const advancedSearchFeatureKey = 'advancedSearch';

export interface AdvancedSearchState {
    data: AdvSearchArgs;
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialStateAdvancedSearch: AdvancedSearchState = {
    data: null,
    errorMessage: null,
    loading: false,
    hasError: false
};

export const advancedSearchReducer = createReducer(
    initialStateAdvancedSearch,
    on(
        FetchAdvancedSearch,
        (state, ): AdvancedSearchState => ({
            ...state,
            data: null,
            loading: false,
            hasError: true,
            errorMessage: null
        })
    ),
    on(
        AdvancedSearchSuccess,
        (state, {advancedSearch}): AdvancedSearchState => ({
            ...state,
            data: AdvancedSearchHelper.buildAdvancedSearch(advancedSearch),
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        AdvancedSearchFailure,
        (state, {message}): AdvancedSearchState => {
            return ({
                ...state,
                data: null,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
);
