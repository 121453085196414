import {createFeatureSelector, createSelector} from '@ngrx/store';
import {catalogTreeFeatureKey, CatalogTreeState} from '@store/publication';

const selectCatalogTreeState = createFeatureSelector<CatalogTreeState>(
    catalogTreeFeatureKey
);

export const selectCatalogTreeView = createSelector(
    selectCatalogTreeState, (state) => state
);
