import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {WorkflowRepository} from '../../../lib';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {FetchWorkflowStatus, FetchWorkflowStatusFailure, FetchWorkflowStatusSuccess} from './workflow-status.actions';

@Injectable()
export class WorkflowStatusEffect {
    constructor(private actions$: Actions,
                private _store: Store,
                private _workflowRepository: WorkflowRepository) {
    }

    fetchStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchWorkflowStatus),
            mergeMap(() => {
                    return this._workflowRepository.getStatusAccess()
                        .pipe(
                            map(wfStatus => {
                                return FetchWorkflowStatusSuccess({status: Array.from(wfStatus.values())});
                            }),
                            catchError(error =>
                                of(FetchWorkflowStatusFailure({message: error?.toString()}))
                            )
                        );
                }
            )
        );
    });
}
