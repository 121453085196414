<mat-dialog-content>
    <div id="np-media-content">
        <div class="top-panel">
        </div>
        <div id="bottom-panel">
            <div class="left-panel">
                <lib-np-medias-tree [currentMedia]="mediaTree"></lib-np-medias-tree>
            </div>
            <div class="right-panel">
                <div class="right-panel-top">
                    <div class="right-panel-top-drop-zone" fileDrop (filesDropped)="handleDrop($event)"
                         (filesHovered)="handleHover($event)"
                         [class.hovered]="dropzoneHovered"
                         (click)="imgFileInput.click()">

                        <div *ngIf="uploadImages && uploadImages.length === 0">
                            <mat-icon>add_box</mat-icon>
                            <span>Veuillez déposer ici les images que vous souhaitez ajouter dans ce dossier</span>
                        </div>

                        <div class="file-list">
                            <div *ngFor="let uploadImage of uploadImages" class="drop-zone-file-container">
                                <div class="image-preview">
                                    <img [src]="uploadImage.imgURL" *ngIf="uploadImage.imgURL">
                                </div>
                                <span class="dz-remove">
                      <a (click)="removeFile(uploadImage, $event)">Supprimer</a>
                </span>
                            </div>
                        </div>
                        <input #imgFileInput class="dz-no-display" type="file"
                               (change)="handleDrop($event.target.files[0])">
                    </div>
                    <div *ngIf="uploadImages && uploadImages.length > 0">
                        <button class="btn btn-primary np-button" title="Enregister" (click)="uploadAllFiles()">
                            <span class="glyphicon glyphicon-upload"></span>
                            Upload
                        </button>
                    </div>
                    <hr>
                    <div class="right-panel-top-research-block">
                        <!--Bouton de chercche-->
                        <div class="nav-research-block">
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       placeholder="Rechercher dans le dossier courant..."
                                       [formControl]="researchFormControl">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary " type="button" title="Effacer"
                                            (click)="resetField()">
                                        <i class="glyphicon glyphicon-remove"></i>
                                    </button>
                                    <button class="btn-np btn-primary-np" type="button" title="Rechercher">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-panel-top">
                    <ul>
                        <ng-container *ngIf="mediasToDisplay.length > 0; else bottomBlock">
                            <li *ngFor="let media of mediasToDisplay" class="search-result">
                                <lib-np-media-preview [mediaObject]="media"
                                                      (selected)="selectMedia($event)"></lib-np-media-preview>
                            </li>
                        </ng-container>
                        <ng-template #bottomBlock>
                            <ng-container *ngIf="loading; else treeNoDataBlock">
                                <div  class="tree-no-data-block">Chargement en cours...</div>
                            </ng-container>
                            <ng-template #treeNoDataBlock>
                                <div class="tree-no-data-block">Aucune donnée n'a été trouvée</div>
                            </ng-template>
                        </ng-template>
                    </ul>
                </div>

            </div>
        </div>
    </div>

</mat-dialog-content>
