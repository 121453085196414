<div class="search-block">
    <input class="search-input" placeholder="{{translate('Recherche', 'Recherche')}}" [formControl]="searchTextControl">
</div>

<div class="products-list">
    <mat-selection-list #possibles>
        <mat-list-option *ngFor="let possible of listPossiblesFiltered" [value]="possible">
            {{possible.elementName}}
        </mat-list-option>
    </mat-selection-list>
    <div class="arrows">
        <span class="arrow-to-right" (click)="moveRight(possibles.selectedOptions.selected)"></span>
        <span class="arrow-to-left" (click)="moveLeft(lies.selectedOptions.selected)"></span>
    </div>
    <mat-selection-list #lies>
        <mat-list-option *ngFor="let lie of listLinked" [value]="lie">
            {{lie.elementName}}
        </mat-list-option>
    </mat-selection-list>
    <span *ngIf="!isValid"  class="requied-error-message inpu-custo">Champ obligatoire</span>
</div>
