import {Component} from '@angular/core';
import {LanguagesList} from 'src/app/data/constants/languages.constants';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {selectLanguage} from 'src/app/store/selector/language.selector';
import {LocalStorageService} from '../../services/local-storage.service';
import {selectUserInfoForm} from '../../../store/selector/user-info.selectors';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent {
  public currentUiLanguageView$ = this._store.select(selectLanguage);
  public currentUserView$ = this._store.select(selectUserInfoForm);
  public languages = LanguagesList;

  constructor(
      private _translate: TranslateService,
      private _store: Store,
      private _localStorageService: LocalStorageService
  ) {
  }

  public changeSiteLanguage(localeCode: string): void {
    this._localStorageService.saveUiLanguage(localeCode);
  }
}
