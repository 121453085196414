import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';
import {advancedSearchFeatureKey, AdvancedSearchState} from '../reducers';

const selectAdvancedSearchState = createFeatureSelector<AdvancedSearchState>(advancedSearchFeatureKey);

export const selectAdvancedSearch = createSelector(
    selectAdvancedSearchState,
    state => _.cloneDeep(state.data)
);
