import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Paginator } from "primeng/paginator";
import { filter, tap } from "rxjs/operators";
import { ParamsFilterBuilder } from "src/app/data/builders";
import { PUBLICATION_DEFAULT_PAGE_SIZE } from "src/app/data/constants/products-page-size.constants";
import { ParamsFilter, ProductSummary, Chapter, Catalog, Product, CatalogPage } from "@app/models";
import { AddOrDeleteProduct, CatalogState, selectCatalogView } from "@store/publication";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogProductsSelectionComponent } from "../../dialog-products-selection.component";

@Component({
  selector: 'app-products-selection',
  templateUrl: './products-selection.component.html',
  styleUrls: ['./products-selection.component.scss'],
})
export class ProductsSelectionComponent implements OnInit, OnDestroy {

  @ViewChild(Paginator) paginator: Paginator;

  @Input() public loading: boolean;
  @Input() public products: ProductSummary[];
  @Input() public totalRecords: number;
  @Input() public chapter: Chapter;
  @Input() public page: CatalogPage;
  @Input() public paramFilterBuilder: ParamsFilterBuilder;
  @Input() public pageSize = PUBLICATION_DEFAULT_PAGE_SIZE;
  @Input() public descriptionField: string;

  @Output() filterElements = new EventEmitter<ParamsFilter>();

  public catalogView$ = this._store.select(selectCatalogView)
    .pipe(
        filter((catalogState: CatalogState) => !!catalogState?.catalog?.chapters)
    );

  private _catalog: Catalog;
  private _catalogSub: Subscription;

  constructor(public _dialogRef: MatDialogRef<DialogProductsSelectionComponent>,private _store: Store) {
  }

  public ngOnInit(): void {
    this._catalogSub = this.catalogView$
      .pipe(
        tap((catalogState: CatalogState) => {
            this._catalog = catalogState.catalog;
        }),
      ).subscribe();
  }

  public onPageChange(event) {
    const paramsFilter = this.paramFilterBuilder
      .withPage(event.page + 1)
      .withNumberOfElementByPage(event.rows)
      .build();

    this.filterElements.emit(paramsFilter);
  }

  public goToFirstPage(): void {
    this.paginator.first = 0;
  }

  public selectProduct(product: ProductSummary) {
    const newProduct = new Product({
      id: Guid.create().toString(),
      name: product.label,
      productId: product.element.ID
    })

    if (this.chapter
      && this.page) {
      this.page.products?.push(newProduct);

      if(this._catalog) {
        const chapterIndex = _.findIndex(this._catalog.chapters, { id: this.chapter.id });
        if (chapterIndex !== -1) {
          _.set(this._catalog.chapters, chapterIndex, this.chapter);
        }

        this._store.dispatch(AddOrDeleteProduct({catalog: this._catalog}));
      }
    }

    this._dialogRef.close();
  }

  public ngOnDestroy(): void {
    this._catalogSub?.unsubscribe();
  }
}
