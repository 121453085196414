import {createAction, props} from '@ngrx/store';
import { ExportParams } from 'src/app/data/models/export.params.model';

export const ExecuteElementsExport = createAction('[Elements Export] Execute Elements Export',
    props<{
        payload: { elementIds: number[], exportConfig: ExportParams, fileName: string }
    }>());
export const ExecuteElementsExportSuccess = createAction('[Elements Export] Execute Elements Success', props<{
    exported: boolean
}>());
export const ExecuteElementsExportFailure = createAction('[Elements Export] Execute Elements Failure', props<{
    message: string
}>());
