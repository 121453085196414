import { Family } from '../../../lib';

export class FamilyBuilder {
  private _ID: number;
  private _label: string;

  withID(value: number): FamilyBuilder {
    this._ID = value;
    return this;
  }

  withLabel(value: string): FamilyBuilder {
    this._label = value;
    return this;
  }

  build(): Family {
    return {
      ID: this._ID,
      label: this._label,
    };
  }
}
