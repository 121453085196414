import {createAction, props} from '@ngrx/store';
import {PublicationTemplate} from '@app/models';

export const FetchPublicationTemplates =
    createAction('[Publication Templates] Fetch Publication Templates',
        props<{ templateCharacteristicExtID: string, channelCategoryExtID?: string }>());

export const FetchPublicationTemplatesSuccess =
    createAction('[Publication Templates] Fetch Publication Templates Success',
        props<{ publicationTemplates: PublicationTemplate[] }>());

export const FetchPublicationTemplatesFailure =
    createAction('[Publication Templates] Fetch Publication Templates Failure',
        props<{ message: any }>());
