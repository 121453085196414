import { KeyValue } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-dialog-catalogue-generation-error',
    templateUrl: './dialog-catalogue-generation-error.component.html',
    styleUrls: ['./dialog-catalogue-generation-error.component.scss']
})
export class DialogCatalogueGenerationErrorComponent {

    public keepOrder = (): number => 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Map<string, number[]>) {

    }   
}