import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SpUserFields} from '../../../data/constants/sp-user-fields';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {SpValueHelper} from '../../../data/helpers/sp-value-helper';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {ProductSummary} from '@app/models';
import {SpUserInfoService} from '@data/services';
import {SpElementWriterService} from '../../../data/services/sp-element-writer.service';
import {SpLoaderService} from '../../services/sp-loader.service';
import {ProductsFacade} from 'src/app/data/facades/products-facade';
import {Subscription} from 'rxjs/Subscription';
import { NpInstanceService } from 'src/app/data/services/np-instance.service';

@Component({
    selector: 'app-dialog-element-writer',
    templateUrl: './dialog-element-writer.component.html',
    styleUrls: [
        '../dialog-product-sheet/dialog-product-sheet.component.scss',
        './dialog-element-writer.component.scss'
    ],
})
export class DialogElementWriterComponent implements OnInit, OnDestroy {
    public menuItems: MenuItem[] = [];
    public element: NPElement;
    public caracValues: CaracIdStateValue[] = [];
    public fields$: Subscription;

    public imageByDefault$ =  this._instanceService.getImageByDefault();

    constructor(
        public _dialogRef: MatDialogRef<DialogElementWriterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
        private _userInfoService: SpUserInfoService,
        private _elementWriterService: SpElementWriterService,
        private _loaderService: SpLoaderService,
        private _instanceService: NpInstanceService,
        private productFacade: ProductsFacade
    ) {
    }

    ngOnInit(): void {
        if (this.data) {
            this.element = this.data.element;
        }

        this.fields$ = this.productFacade.getInfoForProduct(this.data).subscribe();

        this._userInfoService
            .getConfigByUserField(SpUserFields.CP_LISTE_EXTID_CARAC)
            .subscribe((configs) => {
                if (configs && configs.length > 0) {
                    configs.forEach((config) => {
                        this._addToTree(config);
                    });
                }
                // Affichage du premier onglet
                if (this.menuItems && this.menuItems.length > 0) {
                    this.showCaracValues(this.menuItems[0]);
                }
            });
    }

    ngOnDestroy() {
        this.fields$.unsubscribe();
    }

    private _addToTree(config: CaracConfig) {
        let item = this.menuItems.find(
            (x) => x.id === config.DicoCarac.LibelleDossier
        );
        if (item) {
            item.add(config);
        } else {
            item = new MenuItem();
            item.id = config.DicoCarac.LibelleDossier;
            item.add(config);
            this.menuItems.push(item);
        }
    }

    showCaracValues(item: MenuItem) {
        if (this.element) {
            this.caracValues = item.configs.map((config) =>
                SpValueHelper.toBlock(this.element, config)
            );
        }
    }

    save(close = false) {
        if (this._elementWriterService.hasModifications()) {
            this._loaderService.openPreloader();
            this._elementWriterService.pushToNextPage()
                .subscribe(() => {
                    this._loaderService.openSnackBar('Enregistrement effectué avec succès !');
                    this._loaderService.close();
                    if (close) {
                        this._dialogRef.close();
                    }
                },
                (error) => {
                    this._loaderService.openSnackBar(
                        error
                            ? error.toString()
                            : 'Erreur lors de la sauvegarde des données dans NP.'
                    );
                    this._dialogRef.close();
                }
            );
        } else {
            this._loaderService.openSnackBar('Aucune modification à archiver !');
        }
    }
}

class MenuItem {
    id: string;
    configs: CaracConfig[] = [];

    add(config: CaracConfig) {
        if (config.DicoCarac.LibelleDossier === this.id) {
            this.configs.push(config);
        }
    }
}
