import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'lib-dialog-warning-message',
    templateUrl: './dialog-warning-message.component.html',
    styleUrls: ['./dialog-warning-message.component.css']
})
export class DialogWarningMessageComponent implements OnInit {

    constructor(
        private _dialogRef: MatDialogRef<DialogWarningMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onClose(toDelete: boolean) {
        this._dialogRef.close(toDelete);
    }
}
