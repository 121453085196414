import {Component, OnInit} from '@angular/core';
import {flatMap} from 'rxjs/internal/operators';
import {throwError} from 'rxjs';
import {SpUserInfoService} from '@data/services';
import {ElementReaderService} from '../../../data/services/element-reader.service';
import {NpCaracConfigService} from '../../../data/services/np-carac-config.service';
import {SpDicoCarac} from '@data/constants';

@Component({
  selector: 'app-supplier-information',
  templateUrl: './supplier-information.component.html',
  styleUrls: ['./supplier-information.component.scss'],
})
export class SupplierInformationComponent implements OnInit {
  supplierData = [];


  constructor(
    private _userInfoService: SpUserInfoService,
    private _elementReaderService: ElementReaderService,
    private _npCaracConfig: NpCaracConfigService
  ) {
  }

  ngOnInit(): void {
    this._userInfoService
      .getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_FAMILLE_FOURNISSEUR)
      .pipe(
        flatMap((userFields) => {
          if (userFields && userFields.Values && userFields.Values.length > 0) {
            return this._elementReaderService.getDescendants(
              userFields.Values[0].Value
            );
          }
          return throwError(
            'Element extID not found. Please create or set custom field or set value in your PIM'
          );
        })
      )
      .subscribe(
        (family) => {
          let listInforSupplier = [];
          const listFinal = [];
          family.Values.forEach((NpCaracValue: unknown, carac: string | string[]) => {
              if (carac.indexOf('DC_F') !== -1) {
                listInforSupplier.push({
                  carac: carac,
                  NpCaracValue: NpCaracValue,
                });
              }
            }
          );
          if (listInforSupplier && listInforSupplier.length > 0) {
            listInforSupplier = listInforSupplier.filter(
              (y) => y.carac !== 'DC_F_LOGOFOURNISSEUR'
            );
            this._npCaracConfig
              .getCaracConfigs(listInforSupplier.map((x) => x.carac))
              .subscribe((result) => {
                result.map((x, i) =>
                  listFinal.push({
                    label: x.Label,
                    value: listInforSupplier[i].NpCaracValue.Value,
                  })
                );
                this.supplierData = listFinal;
              });
          }
        });
  }
}
