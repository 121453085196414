import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ChoiceCriteriasState, publicationChoiceCriteriasFeatureKey} from '@store/publication';

const selectPublicationChoiceCriteriasState = createFeatureSelector<ChoiceCriteriasState>(publicationChoiceCriteriasFeatureKey);

export const selectPublicationChoiceCriterias = createSelector(
    selectPublicationChoiceCriteriasState,
    state => state.choiceCriterias
);

