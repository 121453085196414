
import { Guid } from 'guid-typescript';
import {ChaptersTags, TemplatesNames, TemplatesTags} from 'src/app/data/enums/publication-tags.enum';

export class CatalogConfig {
    catalogId: number;
    structure: Catalog;
    selectedChapters: string[];
}

export class Catalog {
    id: string; //does not match catalog id. Always equal to '-1'
    name: string;
    description?: string;
    chapters: Chapter[];
}

export class Chapter {
    name: string;
    id?: string;
    nameWithoutTag?: string;
    isIntroductionChapter?: boolean;
    isEndChapter?: boolean;
    channelCategoryID?: number;
    channelCategoryExtID?: string;
    pages?: CatalogPage[];

    constructor(pages?: CatalogPage[]) {
        this.pages = pages;
        const chapterNameSplitted = this.name?.split(" - ");
        this.nameWithoutTag = chapterNameSplitted?.length > 0 ? chapterNameSplitted[1] : this.name;
        this.isEndChapter = this.name?.includes(ChaptersTags.END);
        this.isIntroductionChapter = this.name?.includes(ChaptersTags.INTRO);
    }
}

export class CatalogPage {
    id: string;
    name: string;
    nameWithoutTag: string;
    type = TemplatesNames || '';
    isEditable: boolean;
    description?: string;
    template?: PageTemplate;
    products?: Product[]; 

    constructor(params: Partial<CatalogPage>) {
        const pageNameSplitted = params.name.split(' - ');

        this.name = params.name;
        this.nameWithoutTag = pageNameSplitted[1] ?? this.name;
        this.description = params.description;
        this.template = params.template;
        this.products = params.products;

        if (params.name.includes(TemplatesTags.COVER)) {
            this.type = TemplatesNames.COVER;
        } else if (params.name.includes(TemplatesTags.EDITO)) {
            this.type = TemplatesNames.EDITO;
        } else if (params.name.includes(TemplatesTags.PRODUCT)) {
            this.type = TemplatesNames.PRODUCT;
        } else {
            this.type = '';
        }

        this.isEditable = this.type === TemplatesNames.COVER || this.type === TemplatesNames.EDITO || this.type === TemplatesNames.PRODUCT;

        this.id = params.id ?? Guid.create().toString();
    }
}

export class Product {
    id: string;
    name: string;
    productId: number;

    constructor(params: Product) {
        this.id = params.id ?? Guid.create().toString();
        this.name = params.name;
        this.productId = params.productId;
    }
}

export class PageTemplate {
    id: number;
    path: string;
    completePath: string;
    numberOfElements?: number;
    maxCharactersLength?: number;

    constructor(params: PageTemplate) {
        this.id = params.id;
        this.path = params.path;
        this.completePath = params.path;
        this.numberOfElements = params.numberOfElements;
        this.maxCharactersLength = params.maxCharactersLength;
    }
}
