<div class="d-flex align-items-center header__actions mr-4">
    <button class="save-btn" mat-stroked-button 
    [disabled]="isLoading"
    (click)="saveCatalogue()">{{ 'common.save' | translate }}</button>

    <button class="generate-btn generating-btn" mat-flat-button
    *ngIf="(catalogView$ | async)?.loading">
        {{ 'publication.header.catalogue-generating' | translate }}
    </button>

    <button class="generate-btn" mat-flat-button
    [disabled]="isLoading"
    *ngIf="!(catalogView$ | async)?.loading"
    (click)="generateCatalogue()">
        {{ 'publication.header.generate-catalogue' | translate }}
    </button>

    <app-loader-spinner [diameter]="30" *ngIf='(catalogView$ | async)?.loading'></app-loader-spinner>

    <button class="download-btn" mat-flat-button
    *ngIf="!(catalogTreeView$ | async)?.loading || sourcesButtonClicked"
    [disabled]="!catalogTreeNode?.pdfFileName || isLoading" 
    (click)="downloadPdf()">{{ 'publication.header.download-pdf' | translate }}</button>

    <button class="download-btn downloading-btn" mat-flat-button
    *ngIf="(catalogTreeView$ | async)?.loading && pdfButtonClicked">{{ 'publication.header.downloading' | translate }}</button>


    <button class="d-flex align-items-center sources-btn" mat-icon-button
    *ngIf="!(catalogTreeView$ | async)?.loading || pdfButtonClicked"
    [disabled]="!catalogTreeNode?.zipFileName || isLoading" 
    (click)="downloadSources()" 
    [matTooltip]="'publication.header.download-sources' | translate">
        <fa-icon [icon]="['far', 'download']"></fa-icon>
    </button>

    <button class="d-flex align-items-center sources-btn downloading-btn" mat-button
    *ngIf="(catalogTreeView$ | async)?.loading && sourcesButtonClicked">
        <fa-icon [icon]="['far', 'download']"></fa-icon>
        {{ 'publication.header.downloading' | translate }}
      </button>
    <app-loader-spinner [diameter]="30" *ngIf='(catalogTreeView$ | async)?.loading'></app-loader-spinner>
</div>