import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SearchMedia, SearchMediaFailure, SearchMediaSuccess} from '@store/action';
import {MediaFacade} from '@data/facades';
import {of} from 'rxjs';

@Injectable()
export class MediaLibraryEffects {
    constructor(private actions$: Actions,
                private _mediaFacade: MediaFacade) {
    }

    searchMedia$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchMedia),
            switchMap(action => {
                    return this._mediaFacade.search(action.advancedSearch, action.multiOperators)
                        .pipe(
                            map(searchResult => SearchMediaSuccess({searchResult}))
                        );
                }
            ),
            catchError(error => of(SearchMediaFailure({message: error?.toString()})))
        );
    });
}
