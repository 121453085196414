<div class="section-block">
    <span *ngIf="!isValid"  class="requied-error-message inpu-custo">Champ obligatoire</span>
    <section class="row align-items-center check-section">
        <mat-checkbox *ngFor="let currImage of photosUrl; index as index" class="example-margin col-sm-3"
                      color="primary" [checked]="isChecked(currImage.element)" (change)="check($event, currImage)">
            <span class="image-block">
              <img [src]="currImage.src" [alt]="currImage.alt" [title]="currImage.alt" class="img-responsive"/>
            </span>
        </mat-checkbox>
    </section>
</div>
