<ng-container *ngIf="files$ | async as files">
    <ng-container *ngIf="files?.length > 0">
        <div [ngClass]="customClass">
            <div class="cards-container">
                <div class="cards-block-title">
                    {{ 'homepage.files-to-download' | translate }}
                    <span class="material-icons">download</span>
                </div>

                <div class="cards-block-content">
                    <ul>
                        <li *ngFor="let file of files">
                            <app-file-item-view
                                    [customClass]="customClass"
                                    [file]="file">
                            </app-file-item-view>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
