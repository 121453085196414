import {createAction, props} from '@ngrx/store';
import { Catalog, CatalogConfig } from '@app/models';
import { CatalogCreation } from '@lib/models';

export const GetCatalog =
    createAction('[Catalog] Get Catalog',
        props<{ id: number }>());

export const GetCatalogSuccess =
    createAction('[Catalog] Get Catalog Success',
        props<{ catalog: Catalog }>());

export const GetCatalogFailure =
    createAction('[Catalog] Get Catalog Failure',
        props<{ message: any }>());

export const CreateCatalog =
    createAction('[Catalog] Create Catalog',
        props<{ catalogInfo: CatalogCreation }>());

export const EditCatalog =
    createAction('[Catalog] Edit Catalog',
        props<{ catalogConfig: CatalogConfig }>());

export const CatalogActionsSuccess =
    createAction('[Catalog] Catalog Actions Success',
        props<{ catalogConfig: CatalogConfig }>());

export const CatalogActionsFailure =
    createAction('[Catalog] Catalog Actions Failure',
        props<{ message: any, catalog: Catalog }>());

export const GenerateCatalog =
    createAction('[Catalog] Generate Catalog',
        props<{ catalogConfig: CatalogConfig }>());

export const SaveCatalog =
    createAction('[Catalog] Save Catalog',
        props<{ catalogConfig: CatalogConfig }>());

export const AddOrDeletePage =
    createAction('[Catalog] Add Or Delete Page',
        props<{ catalog: Catalog }>());

export const AddOrDeleteProduct =
    createAction('[Catalog] Add Or Delete Product',
        props<{ catalog: Catalog }>());