import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {FavoriteActions} from '@store/action';
import {ElementRepository} from 'src/lib/data/services/element-repository';
import {Store} from '@ngrx/store';
import {selectDataLanguage} from '../data-languages';
import {selectElementPreviewConfigs} from '../selector/user-info.selectors';
import {ProductsFacade} from 'src/app/data/facades';
import {combineLatest} from 'rxjs';
import {selectAllDicoCharacteristics} from '../characteristics';
import {environment} from '@env';

@Injectable()
export class FavoriteEffect {
    constructor(
        private actions$: Actions,
        private _elementRepository: ElementRepository,
        private _store: Store,
        private _productsFacade: ProductsFacade) {
    }

    loadElementsFromFavorite$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavoriteActions.loadElementsFromFavorite),
            mergeMap(() => {
                const favorites = this.getFavoritesFromLocalStorage();
                return this._getElementByExtIds(favorites).pipe(
                    map(result => {
                        return Array.from(result.values()).map(element => {
                            return ({
                                urlWithoutToken: this._productsFacade.getImageUrlWithoutToken(element),
                                imageUrl: this._productsFacade.getImageUrl(element),
                                label: element.Label,
                                element: element
                            });
                        });
                    })
                );
            }),
            map(favorites => FavoriteActions.loadElementsFromFavoriteSuccess({favorites: favorites}))
        );
        },
    );

    LoadElementsFromFavoriteSuccess$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(FavoriteActions.loadElementsFromFavoriteSuccess),
            );
        },
        {dispatch: false}
    );

    toggleFavorite$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavoriteActions.toggleFavorite),
                map(action => {
                    const favorite = action.favorites;
                    let storedFavorites = this.getFavoritesFromLocalStorage();
                    if (!storedFavorites.some(element => favorite.extID === element)) {
                        storedFavorites.push(favorite.extID);
                    } else {
                        storedFavorites = storedFavorites.filter(item => item !== favorite.extID);
                    }

                    localStorage.setItem(
                        environment.favoriteKey,
                        JSON.stringify(storedFavorites)
                    );

                    return this._store.dispatch(FavoriteActions.updateFavorites({favoriteExtIds: storedFavorites}));
                })
            );
        },
        {dispatch: false}
    );

    getFavoritesFromLocalStorage(): string[] {
        const storedFavorites = localStorage.getItem(environment.favoriteKey);
        if (storedFavorites) {
            const dataFromLocalStorage: string[] = JSON.parse(storedFavorites);
            return dataFromLocalStorage;
        } else {
            return [];
        }
    }

    private _getElementByExtIds = (elementExtIds: string[]) => {
        return combineLatest([
            this._store.select(selectDataLanguage),
            this._store.select(selectElementPreviewConfigs)
                .pipe(filter(result => !!result.mainVisualCaracExtId)),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            switchMap(([selectedDataLang, previewParams, dicoCaracs]) => {
                const userDicoCaracs = previewParams?.previewCaracs.map(previewCarac => {
                    return dicoCaracs.find(dicoCarac => dicoCarac?.ExtID === previewCarac);
                });
                const characeristicsListOfLinkType = userDicoCaracs
                    .filter(characteristic => characteristic?.ExtID !== previewParams?.mainVisualCaracExtId && characteristic?.TypeCode.includes('LIEN'))
                    .map(characteristic => characteristic?.ExtID)
                    .map(element => [element, previewParams?.mainVisualCaracExtId]);
                characeristicsListOfLinkType.push([previewParams?.mainVisualCaracExtId]);

                return this._elementRepository.getElements(
                    elementExtIds,
                    characeristicsListOfLinkType,
                    previewParams.previewCaracs,
                    selectedDataLang?.ID
                );
            })
        );
    };
}    
