import {createFeatureSelector, createSelector} from '@ngrx/store';
import {advancedSearchFeatureKey, AdvancedSearchState} from '../reducers';

const selectState = createFeatureSelector<AdvancedSearchState>(
    advancedSearchFeatureKey
);

export const selectAdvancedSearch = createSelector(
    selectState, (state) => state.data
);
