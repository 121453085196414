import { NPElementType } from '@nextpage/np-sdk-data';
import {Chars, SortType} from '../types';

export class ParamsFilter {
  constructor(
    private _numberOfElementByPage: number,
    private _page: number,
    private _keyword: string,
    private _elementTypeList: NPElementType[],
    private _productTypeId?: number,
    private _facetsList?: string[],
    private _chars?: Chars,
    private _sortFields?: SortType[],
    private _channelScope?: number,
    private _familyScope?: number,
    private _fieldsToDisplay?: string[],
    private _mainVisualCaracExtId?: string,
    private _langID?: number
  ) {}


  get numberOfElementByPage(): number {
    return this._numberOfElementByPage;
  }

  get page(): number {
    return this._page;
  }

  get keyword(): string {
    return this._keyword;
  }

  get elementTypeList(): NPElementType[] {
    return this._elementTypeList;
  }

  get productTypeId(): number {
    return this._productTypeId;
  }

  get facetsList(): string[] {
    return this._facetsList;
  }

  get chars(): Chars {
    return this._chars;
  }

  get sortFields(): SortType[] {
    return this._sortFields;
  }

  set sortFields(value: SortType[]) {
    this._sortFields = value;
  }

  get channelScope(): number {
    return this._channelScope;
  }

  set channelScope(value: number) {
    this._channelScope = value;
  }

  get familyScope(): number {
    return this._familyScope;
  }

  set familyScope(value: number) {
    this._familyScope = value;
  }

  get fieldsToDisplay(): string[] {
    return this._fieldsToDisplay;
  }

  set fieldsToDisplay(value: string[]) {
    this._fieldsToDisplay = value;
  }

  get mainVisualCaracExtId(): string {
    return this._mainVisualCaracExtId;
  }

  set mainVisualCaracExtId(value: string) {
    this._mainVisualCaracExtId = value;
  }

  get langID(): number {
    return this._langID;
  }

  set langID(value: number) {
    this._langID = value;
  }
}
