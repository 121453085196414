import { DataMocker } from './data-mocker';

import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NpApiResult } from '../../../model/np-api-result';
import { Injectable, Optional } from '@angular/core';
import { Constantes } from '../../../const';

/**
 * Ce dataMocker a pour objectif de rediriger les requetes envoyées vers "sdk" vers un sdk-debug en ajoutant le token
 */
@Injectable({ providedIn: 'root' })
export class DataMockerSdkdebug extends DataMocker {
  /**
   * Url de base du site web=> à modifier en fonction des besoins du développeur
   * ATTENTION, il doit y avoir un / à la fin
   */
  private _baseUrl = 'https://preprod-testtmr.nextpage.fr/';
  /**
   * Header contenant le tocken =>à modifier en fonction des besoins du développeur
   */
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    ApiToken: 'fakeTokenForDebug-user:tmontanier-mdp:ailier',
    // 'ApiToken': "UWW48RH-HEoTsCYNTsGt_zbCKtRb1xMMuMwZcR7NTAeWgT-r4-PoxPYC1Ssj-mT9CrZidfLcwhLREQZ_nEL1KO7G3F8jyXvHFx5lc0BN7BS1rdOdg5xKSQC3I9xicKToC1jMTALodvbb2QhUO2YvB8HFlOGOWigTYvzJwkbEv9I1"
    // 'ApiToken': '5pGfB8bMxo1h5cHuWd6ch1ih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPDPnECplXcndaGHKf4oWOTCg3FByjvDh7nZAFI1riPfB9w-cC8wsBcqzgZ_FKVD_7mANpDk47ZQcGneI5TEg2Spk65z-PAtFWkudwbsydJkI1'
    // 'ApiToken': 'aan-351a5JHlFBEjADcCZFih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPDPnECplXcndaGHKf4oWOTBWEmICh2f-tFRDe9IHeqY6n2tLRG8OYtrZVp8ziZufHTDuXVG38Euw2lvc00oIdGz-2tDkT2JvLXLKbjgVSHFs1'
  });
  private readonly fileHeaders = new HttpHeaders({
    ApiToken: 'fakeTokenForDebug-user:tmontanier-mdp:ailier',
    // 'ApiToken': '5pGfB8bMxo1h5cHuWd6ch1ih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPDPnECplXcndaGHKf4oWOTCg3FByjvDh7nZAFI1riPfB9w-cC8wsBcqzgZ_FKVD_7mANpDk47ZQcGneI5TEg2Spk65z-PAtFWkudwbsydJkI1'
    // 'ApiToken': 'aan-351a5JHlFBEjADcCZFih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPDPnECplXcndaGHKf4oWOTBWEmICh2f-tFRDe9IHeqY6n2tLRG8OYtrZVp8ziZufHTDuXVG38Euw2lvc00oIdGz-2tDkT2JvLXLKbjgVSHFs1'
  });

  public constructor(@Optional() private _cstes: Constantes) {
    super();
    if (_cstes) {
      if (_cstes.baseUrl) {
        this._baseUrl = this._cstes.baseUrl;
      }
      if (this._cstes.token) {
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
          ApiToken: this._cstes.token,
        });
        this.fileHeaders = new HttpHeaders({
          ApiToken: this._cstes.token,
        });
      }
    }
  }

  // 'ApiToken': 'fakeTokenForDebug-user:tmontanier-mdp:ailier'
  /*
      private _baseUrl = 'https://projet-naosdev.nextpage.fr/';
      private readonly headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiToken': '5pGfB8bMxo1h5cHuWd6ch1ih81WaIbYL7IReflxm9yI2-qDDJAoZ0Tb9Nsb7CQgPWetYOT2vMUBHrcA8fTIL8SNi3tFj4q1tKYxEcWqmwz6C1xLTKrSh26v_tmfR3c3fTDuXVG38Euw2lvc00oIdGz-2tDkT2JvLXLKbjgVSHFs1'
      });
      */

  /**
   * Redirige les requetes vers les urls de debug
   * Pour utiliser cette classe, il faut spécifier l'url du projet (racine) ainsi que le tocken à envoyer
   */
  mockResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/filehandler') && request.url.indexOf('SncfScreen') > -1) {
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: './assets/SncfScreen.json',
      });
      return next.handle(newRequest);
    }
    if (request.url.startsWith('/filehandler') && request.url.indexOf('ConfigSearch') > -1) {
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: './assets/ConfigSearch.json',
      });
      return next.handle(newRequest);
    }
    if (request.url.indexOf('uitranslation') > -1) {
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: './assets/UiTranslations.json',
      });
      return next.handle(newRequest);
    }

    if (request.url.startsWith('/filehandler') && request.url.indexOf('thumbs') > -1) {
      let newRequest: HttpRequest<any>;
      newRequest = request.clone({
        url: (this._baseUrl + request.url).replace('//', '/'),
      });
      return next.handle(newRequest);
    }
    // si la requête de base commence par "api/"
    if (request.url.startsWith('/api/')) {
      let url = request.url;
      if (request.url.indexOf('sdk/') > -1) {
        url = url.replace('/sdk/', '/sdk-debug/');
      }
      // on crée une nouvelle requete en changeant les options et en ajoutant la base url
      let newRequest: HttpRequest<any>;
      if (request.url.indexOf('UpdateMedia') > -1) {
        newRequest = request.clone({
          url: (this._baseUrl + url).replace('//', '/'),
          headers: this.fileHeaders,
        });
      } else {
        newRequest = request.clone({
          url: (this._baseUrl + url).replace('//', '/'),
          headers: this.headers,
        });
      }

      return next.handle(newRequest);
    } else {
      return next.handle(request);
    }
  }

  matchUrl(request: HttpRequest<any>): boolean {
    return true;
    // return (request.Url ==this.getUrlPattern());
  }

  getData(): NpApiResult {
    return null;
  }

  getUrlPattern(): string {
    return '';
  }
}
