import { ProductPortal } from '@lib/models';

export class ProductPortalBuilder {
    private _id: string;
    private _name: string;
    private _productId: number;

    withId(value: string): ProductPortalBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): ProductPortalBuilder {
        this._name = value;
        return this;
    }

    withProductId(value: number): ProductPortalBuilder {
        this._productId = value;
        return this;
    }

    build(): ProductPortal {
        return {
            Id: this._id,
            Name: this._name,
            ProductId: this._productId
        };
    }
}
