import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Characteristic} from '@app/models';
import {SpUserFields} from '../constants/sp-user-fields';
import {map, mergeMap} from 'rxjs/operators';
import {CaracConfig, CustomFieldValue} from '../../../lib';
import {SpUserInfoService} from './sp-user-info.service';
import {selectAllDicoCaracConfigs} from '@store/characteristics';
import {Store} from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(
        private _userInfoService: SpUserInfoService,
        private _store: Store
    ) {
    }

    getCharacteristicConfigListByUser(): Observable<Characteristic[]> {
        return this._userInfoService.getUserCustomFieldByExtId(SpUserFields.CP_LISTE_EXTID_CARAC).pipe(
            map(listCharacteristic => listCharacteristic.Values),
            mergeMap((listCharacteristic: CustomFieldValue[]) => {
                return this._store.select(selectAllDicoCaracConfigs).pipe(
                    map(dicoCharacteristic => dicoCharacteristic.data),
                    map((CharacteristicConfigList: CaracConfig[]) =>
                        this._getCharacteristicConfigsForListUser(CharacteristicConfigList, listCharacteristic)
                    ),
                    map((configList: CaracConfig[]) => this._getCharacteristic(listCharacteristic, configList))
                );
            })
        );
    }

    private _getCharacteristic(listCharacteristic: CustomFieldValue[], caracConfigList: CaracConfig[]): Characteristic[] {
        return listCharacteristic.map((characteristic: CustomFieldValue) => {
            const caracConfig = caracConfigList.find(config => config.DicoCaracExtID === characteristic.Value);
            return new Characteristic(caracConfig?.Label, caracConfig?.DicoCaracExtID, caracConfig?.DicoCarac?.TypeCode);
        });
    }

    private _getCharacteristicConfigsForListUser(
        CharacteristicConfigList: CaracConfig[],
        listCharacteristic: CustomFieldValue[]
    ): CaracConfig[] {
        return CharacteristicConfigList.filter(caracConfig =>
            listCharacteristic.some(characteristic => characteristic.Value === caracConfig.DicoCaracExtID)
        );
    }
}
