export class PublicationTemplateDto {
    ID: number;
    InddFile: string;
    XmlFile: string;
    JpgFile: string;
    Type: string;
    TemplateType: any;
    NumberOfElements: number;
    Description: string;
    Valid: boolean;
    Actif: boolean;
    UserIDModifAvailability: number;
    DateModificationAvailability: string;
    Format: string;
    NumberOfPages: number;
    CategoryID: number;
    CategoryName: any;
    DateModification: string;
    CustomAreas: string;
    TypeWeb: string;
    ZonesPerso: string;
    RemotePath: any;
}
