import {createAction, props} from '@ngrx/store';

import {User} from '@app/models';
import {NpUser} from '../../../lib/data/model/np-user';

export const Login = createAction('[Authentication] Login', props<{ user: User }>());
export const Logout = createAction('[Authentication] Logout');
export const LoginSuccess = createAction('[Authentication] Login Success', props<{ result: string }>());
export const LoginFailure = createAction('[Authentication] Login Failure', props<{ message: string }>());
export const GetUser = createAction('[Authentication] Get User');
export const GetUserSuccess = createAction('[Authentication] Get User Success', props<{ result: NpUser[] }>());
