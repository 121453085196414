<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ 'publication.catalogue.add-pages' | translate }}</h2>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <form class="d-flex align-items-center" [formGroup]='addPagesForm'>
    <p-dropdown 
      styleClass="operators-dropdown"
      panelStyleClass="operators-dropdown-panel"
      formControlName="pagesOperators" 
      [options]="operators"
      optionLabel="name" 
      appendTo="body"/>

    <p-inputNumber styleClass="pages-number-input" inputId="pagesNumber" 
    [showButtons]="true" 
    [min]="1"
    formControlName="pagesNumber" />

    <button mat-flat-button 
      color='primary' 
      class="submit-btn"
      type='submit'
      [disabled]='addPagesForm.invalid || (loading$ | async)'
      [mat-dialog-close]="true"
      (click)="addPagesToChapter()">
      <span *ngIf='(loading$ | async)'
      class='spinner-border spinner-border-sm mr-1'></span>
      OK</button>
  </form>
</mat-dialog-content>
