import {createAction, props} from '@ngrx/store';
import {NPDicoCarac} from '../../../lib';

export const FetchAllCharacteristics = createAction(
    '[Dico Characteristics] Fetch DicoCharacteristics'
);

export const FetchCharacteristicsSuccess = createAction(
    '[Dico Characteristics] Fetch Success',
    props<{ dicoCharacteristics: NPDicoCarac[] }>()
);

export const FetchCharacteristicsFailure = createAction(
    '[Dico Characteristics] Fetch Failure',
    props<{ message: string }>()
);
