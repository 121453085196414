<div class="d-flex flex-column align-items-end catalogue-page__left-part">
  <ng-container *ngIf="leftPage">
    <div class="d-flex justify-content-end w-100 catalogue-page__edition">
      <app-catalogue-page-title class="d-flex" 
      [page]="leftPage" 
      [chapter]="chapter" 
      [isEditable]="leftPage?.isEditable"></app-catalogue-page-title>
    </div>
    <app-catalogue-products class="w-100 d-flex justify-content-end" 
    *ngIf="leftPage?.type === productPageTypeEnum"
    [page]="leftPage"
    [chapter]="chapter"></app-catalogue-products>
  </ng-container>
</div>
<div class="d-flex catalogue-page__pages">
  <div class="d-flex justify-content-center align-items-center text-center catalogue-page__side catalogue-page__left-side"
  *ngIf="leftPage || chapter?.channelCategoryExtID"
  [ngClass]="{'blank-page': isFirstBlankPage && leftPage, 'add-page': !leftPage && !templateSelectionDisabled, 'no-template': leftPage?.type === productPageTypeEnum}">
    <ng-container *ngIf="leftPage || templateSelectionDisabled; else addPage">
      <app-catalogue-page [chapter]="chapter" [page]="leftPage" *ngIf="leftPage?.template || templateSelectionDisabled; else chooseTemplate"></app-catalogue-page>
      <ng-template #chooseTemplate>
        <app-catalogue-template-selection class="h-100" 
        #catalogueTemplateSelectionComponent
        [chapter]="chapter"
        [page]="leftPage"></app-catalogue-template-selection>
      </ng-template>
    </ng-container>
    <ng-template #addPage>
      <app-catalogue-page-addition [chapter]="chapter" (addPage)="handleComponentLifeCycle()"></app-catalogue-page-addition>
    </ng-template>
  </div>
  <div class="d-flex justify-content-center align-items-center text-center catalogue-page__side catalogue-page__right-side" 
  [ngClass]="{'add-page': !rightPage && !templateSelectionDisabled, 'no-template': !rightPage?.type !== productPageTypeEnum || templateSelectionDisabled}"
  *ngIf="leftPage && (rightPage || chapter?.channelCategoryExtID)">
    <ng-container *ngIf="rightPage  || templateSelectionDisabled; else addPage">
      <app-catalogue-page [chapter]="chapter" [page]="rightPage" *ngIf="rightPage?.template || templateSelectionDisabled; else chooseTemplate"></app-catalogue-page>
      <ng-template #chooseTemplate>
        <app-catalogue-template-selection class="h-100" 
        #catalogueTemplateSelectionComponent
        [chapter]="chapter"
        [page]="rightPage"></app-catalogue-template-selection>
      </ng-template>
    </ng-container>
    <ng-template #addPage>
      <app-catalogue-page-addition [chapter]="chapter" (addPage)="handleComponentLifeCycle()"></app-catalogue-page-addition>
    </ng-template>
  </div>
</div>

<div class="d-flex flex-column align-items-start catalogue-page__right-part">
  <ng-container *ngIf="rightPage">
    <div class="d-flex catalogue-page__edition">
      <app-catalogue-page-title class="d-flex" 
      [page]="rightPage"
      [chapter]="chapter" 
      [isEditable]="rightPage.isEditable"></app-catalogue-page-title>
    </div>
    <app-catalogue-products class="w-100 d-flex justify-content-start" 
    *ngIf="rightPage.type === productPageTypeEnum"
    [page]="rightPage"
    [chapter]="chapter"></app-catalogue-products>
  </ng-container>
</div>  