
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Chapter, CatalogPage } from "@app/models";
import { CatalogueTemplateSelectionOverlayComponent } from "../catalogue-template-selection-overlay/catalogue-template-selection-overlay.component";

@Component({
  selector: 'app-catalogue-template-selection',
  templateUrl: './catalogue-template-selection.component.html',
  styleUrls: ['./catalogue-template-selection.component.scss']
})
export class CatalogueTemplateSelectionComponent {
  @Input() chapter: Chapter;

  @Input() page: CatalogPage;
  
  @ViewChild('catalogueTemplateSelectionOverlayComponent') catalogueTemplateSelectionOverlayComponent: CatalogueTemplateSelectionOverlayComponent;
  @ViewChild('selectTemplateButton') selectTemplateButton: ElementRef;

  public triggerSelectTemplateClickEvent(): void {
    Promise.resolve().then(() => {
      this.selectTemplateButton.nativeElement.click();
    });
  }

  public selectPageTemplate(event: Event): void {
    this.catalogueTemplateSelectionOverlayComponent.toggle(event);
  }
}
