import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, map, mergeMap, shareReplay, switchMap, tap} from 'rxjs/operators';
import {NPElement, NPElementType} from '../model';
import {NPCaracLien, NPCaracLienRebuildValue, NPCaracValeur} from '../model';
import {Observable} from 'rxjs/Observable';
import {NPAPIElementLinksResult} from '../model';
import {WSParameters, WsParamsService} from './ws-params.service';
import {NPDicoCarac} from '../model';
import {Constantes} from '../const';
import {CharTemplateDto} from '@app/models';
import { ResultSearchType } from 'src/app/data/types';

@Injectable({
  providedIn: 'root',
})
export class ElementRepository {
  private _urlGetElement = '/api/sdk/element/ElementsAndLinks';
  private _urlGetDescendants = '/api/sdk/element/DescendantsAndLinks';
  private _urlGetByCharTemplate = '/api/sdk/element/ElementsByCharTemplate';
  private _urlGetSearchForLink = '/api/sdk/element/GetElementsByTextForDicoCarac';
  private _urlGetDescendantsByElementTypes = '/api/sdk/element/GetDescendantsAndLinksByElementType';
  private _urlGetElementByID = '/api/sdk/element/GetElement';
  private _urlGetCharTemplate = '/api/sdk/chartemplate/GetAll';
  private _urlGetCharTemplateByExtIds = '/api/sdk/chartemplate/GetByElementExtIds';


  public forTestAllResults: Map<string, NPElement>;

  constructor(private _http: HttpClient, private _paramServices: WsParamsService, private _const: Constantes = null) {
    this._initialize();
  }

  private _initialize() {}
  getProductTemplate(ElementIDs: string[]): Observable<CharTemplateDto[]> {
    return this._paramServices.getParams().pipe(
        mergeMap(params => {
          const postParameters = {
            ElementType: NPElementType.Product,
            LangID: params.LangID,
            ContextID: params.ContextID,
            ExtIDs: ElementIDs,
            GetDescendants: true,
          };
          return this._http.post(this._urlGetCharTemplateByExtIds, postParameters).pipe(
              map(response => {
                if (response && 'Results' in response) {
                  const results = response['Results'];
                  if ('CharTemplates' in results) {
                    return results['CharTemplates'] as CharTemplateDto[];
                  }
                }
                return [];
              })
          );
        })
    );
  }

  public getElement(elementExtId: string, linksPath: string[][], caracExtIDs: string[] = [], langID?: number) {
    return this.getElements([elementExtId], linksPath, caracExtIDs, langID)
        .pipe(map(elementMap => elementMap.size > 0 ? elementMap.get(elementExtId) : null));
  }

  getElements(ElementExtIds: string[], linksPath: string[][], caracExtIDs: string[] = [], langID?: number): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
        switchMap((params: WSParameters) => {
          const postParameters = {
            ElementsExtIDs: ElementExtIds,
            Paths: linksPath,
            ContextID: params.ContextID,
            LangID: langID ? langID : params.LangID,
            DCExtIDs: caracExtIDs
          };
        return this._http.post<ResultSearchType>(this._urlGetElement, postParameters);
      }),
      tap(data => {
        // reconstruction de la variable forTestAllResults uniquement si on n'est pas en prod
        if (this._const != null && !this._const.envProd) {
          if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              const requestResultCasted = new NPAPIElementLinksResult(data['Results']);

              this.forTestAllResults = new Map<string, NPElement>();
              requestResultCasted.Elements.forEach(elmt => {
                this.forTestAllResults.set(elmt.ExtID, elmt);
              });
            }
          }
        }
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  getElementWithProperties(elementExtIDs: string[], linksPath: string[][], dcExtIDs: string[]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: elementExtIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
          DCExtIDs: dcExtIDs
        };
        return this._http.post<Object>(this._urlGetElement, postParameters);
      }),
      tap(data => {
        // reconstruction de la variable forTestAllResults uniquement si on n'est pas en prod
        if (this._const != null && !this._const.envProd) {
          if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              const requestResultCasted = new NPAPIElementLinksResult(data['Results']);

              this.forTestAllResults = new Map<string, NPElement>();
              requestResultCasted.Elements.forEach(elmt => {
                this.forTestAllResults.set(elmt.ExtID, elmt);
              });
            }
          }
        }
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  getDescendants(ElementIDs: string[], linksPath: string[][], onlyOneLevel = false, dicoCaracs: string[] = []): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: ElementIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
          OnlyOneLevel: onlyOneLevel,
          DCExtIDs: dicoCaracs
        };
        return this._http.post<Object>(this._urlGetDescendants, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  getDescendantsByElementType(
    ElementIDs: string[],
    linksPath: string[][],
    elementType: NPElementType,
    onlyOneLevel = false
  ): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: ElementIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
          OnlyOneLevel: onlyOneLevel,
          ElementTypeValue: elementType,
        };
        return this._http.post<Object>(this._urlGetDescendantsByElementTypes, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results'], true);
          }
        }
      }),
      map(response => {
        return response;
      }),
      first(),
      shareReplay(1)
    );
  }

  getByCharTemplates(charTemplatesExtID: string, linksPath: string[][]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          CharTemplateExtID: charTemplatesExtID,
          ContextID: params.ContextID,
          Paths: linksPath,
          LangID: params.LangID,
        };
        return this._http.post<Object>(this._urlGetByCharTemplate, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public searchForLink(dicoCarac: NPDicoCarac, searchText: string, element: NPElement) {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          DicoCaracID: dicoCarac.ID,
          SearchText: searchText,
          ElementID: element.ID,
          ContextID: params.ContextID,
          LangID: params.LangID,
        };
        return this._http.post<Object>(this._urlGetSearchForLink, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      map(data => {
        const result = [];
        data.forEach(value => {
          result.push(value);
        });
        return result;
      }),
      first(),
      shareReplay(1)
    );
  }

  public searchForLinkByDicoExtID(dicoCaracID: number, searchText: string, element: NPElement) {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          DicoCaracID: dicoCaracID,
          SearchText: searchText,
          ElementID: element.ID,
          ContextID: params.ContextID,
          LangID: params.LangID,
        };
        return this._http.post<Object>(this._urlGetSearchForLink, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuild(data['Results']);
          }
        }
      }),
      map(data => {
        const result = [];
        data.forEach(value => {
          result.push(value);
        });
        return { dicoCaracID: dicoCaracID, result: result };
      }),
      first(),
      shareReplay(1)
    );
  }

  public _rebuild(requestResult: any, byElementTypes = false): Map<string, NPElement> {
    const requestResultCasted = new NPAPIElementLinksResult(requestResult);
    const result = new Map<string, NPElement>();
    // on recaste les éléments pour éviter des pertes de propriétés bizarres
    const recasted = new Map<string, NPElement>();
    requestResultCasted.Elements.forEach(eltData => {
      // reconstruction propre des objets
      const element = new NPElement(eltData);
      recasted.set(element.ID.toString(), element);
    });
    requestResultCasted.Elements = recasted;

    requestResultCasted.Elements.forEach(element => {
      // reconstruction des liens entre les éléments
      element.Values.forEach((value: NPCaracValeur) => {
        value.Element = element;
        if ((<NPCaracLien>value).LinkedElements != null) {
          // Si caracLien
          (<NPCaracLien>value).RebuildLinkedElements = (<NPCaracLien>value).LinkedElements.reduce((accumulator, val) => {
            if (requestResultCasted.Elements.has(val.ElementID.toString())) {
              const rebuildedVal = new NPCaracLienRebuildValue();
              rebuildedVal.Order = val.Order;
              rebuildedVal.Element = requestResultCasted.Elements.get(val.ElementID.toString());
              accumulator.push(rebuildedVal);
            } else if (val.hasOwnProperty('RebuildElement')) {
              // dans le cadre des API, nextPage retourne l'objet dans RebuildElement. Ca marche et ca tombe bien.
              const rebuildedVal = new NPCaracLienRebuildValue();
              rebuildedVal.Order = val.Order;
              rebuildedVal.Element = val['RebuildElement'];
              accumulator.push(rebuildedVal);
            }
            return accumulator;
          }, []);
        }
      });
      // reconstruction des parents
      if (requestResultCasted.Elements.has(element.ParentID.toString())) {
        element.Parent = requestResultCasted.Elements.get(element.ParentID.toString());
        if (element.ParentExtID == null) {
          element.ParentExtID = element.Parent.ExtID;
        }
        if (element.Parent.Children == null) {
          element.Parent.Children = [];
        }
        element.Parent.Children.push(element);
      }
    });

    if (byElementTypes) {
      return requestResultCasted && requestResultCasted.Elements ? requestResultCasted.Elements : new Map<string, NPElement>();
    }

    requestResultCasted.Results.map(ElementID => {
      const element = requestResultCasted.Elements.get(ElementID.toString());
      if (element) {
        result.set(element.ExtID, requestResultCasted.Elements.get(ElementID.toString()));
      }
    });
    return result;
  }

  getElementByID(ElementID: number, linksPath: string[][]) {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementID: ElementID,
          // ExtID: null,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
        };
        return this._http.post<Object>(this._urlGetElementByID, postParameters);
      }),
      map(data => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return this._rebuildElement(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public _rebuildElement(currentElement: any): Map<string, NPElement> {
    const recasted = new Map<string, NPElement>();
    const element = new NPElement(currentElement);
    recasted.set(element.ID.toString(), element);
    element.Values.forEach((value: NPCaracValeur) => {
      value.Element = element;
      if ((<NPCaracLien>value).LinkedElements != null) {
        // Si caracLien
        (<NPCaracLien>value).RebuildLinkedElements = (<NPCaracLien>value).LinkedElements.reduce((accumulator, val) => {
          if (recasted.has(val.ElementID.toString())) {
            const rebuildedVal = new NPCaracLienRebuildValue();
            rebuildedVal.Order = val.Order;
            rebuildedVal.Element = recasted.get(val.ElementID.toString());
            accumulator.push(rebuildedVal);
          } else if (val.hasOwnProperty('RebuildElement')) {
            // dans le cadre des API, nextPage retourne l'objet dans RebuildElement. Ca marche et ca tombe bien.
            const rebuildedVal = new NPCaracLienRebuildValue();
            rebuildedVal.Order = val.Order;
            rebuildedVal.Element = val['RebuildElement'];
            accumulator.push(rebuildedVal);
          }
          return accumulator;
        }, []);
      }
    });
    // reconstruction des parents
    if (recasted.has(element.ParentID.toString())) {
      element.Parent = recasted.get(element.ParentID.toString());
      if (element.ParentExtID == null) {
        element.ParentExtID = element.Parent.ExtID;
      }
      if (element.Parent.Children == null) {
        element.Parent.Children = [];
      }
      element.Parent.Children.push(element);
    }
    return recasted;
  }
}
