import * as _ from "lodash";
import { CatalogConfig, Catalog } from "@app/models";

export class PublicationHelper {
    public static removeFirstBlankPage(catalog: CatalogConfig | Catalog) {
        const catalogStructure: Catalog = catalog instanceof CatalogConfig ? catalog?.structure : catalog;
        const introductionChapter = catalogStructure?.chapters?.find(chapter => chapter.isIntroductionChapter);

        if (introductionChapter?.pages?.length > 0 && _.isEmpty(introductionChapter?.pages[0].name)) {
            introductionChapter.pages.shift();
        }

        return catalog;
    }
}