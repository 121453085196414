import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import * as XLSX from 'xlsx';
import {Subscriber} from 'rxjs';
import {DataSummary} from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  public sheetContent: unknown[] = [];
  public headers: string[] = [];
  public headers2: string[] = [];
  public sheetContent2: unknown[] = [];

  getDataFromFile(currentFile: File): Observable<any> {
    let workBook = null;
    let jsonData = null;
    let jsonData2 = null;
    const reader = new FileReader();
    return new Observable((observer: Subscriber<any>) => {
      reader.onload = () => {
        const data = reader.result;
        workBook = XLSX.read(data, {type: 'binary'});
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        jsonData2 = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, {
            raw: true,
            header: 1,
          });
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataString2 = JSON.stringify(jsonData2);
        observer.next(this.setDownload(dataString, dataString2));
        observer.complete();
      };
      reader.readAsBinaryString(currentFile);
    });
  }

  private setDownload(dataString: string, dataString2: string) {
    const jsonData = JSON.parse(dataString);
    const jsonData2 = JSON.parse(dataString2);

    const _sheetNames2 = Object.getOwnPropertyNames(jsonData2);
    const _sheetNames = Object.getOwnPropertyNames(jsonData);
    if (_sheetNames2 && _sheetNames2.length > 0) {
      this.sheetContent = jsonData[_sheetNames[0]] as unknown[];
      this.sheetContent2 = jsonData2[_sheetNames2[0]] as unknown[];
      this.headers2 = this.sheetContent2[0] as string[];
      this.headers = Object.getOwnPropertyNames(this.sheetContent[0]);
    } else {
      this.sheetContent = [];
      this.headers = [];
      this.headers2 = [];
    }
    const _selectedData = new DataSummary();
    _selectedData.data = this.sheetContent;
    _selectedData.headers = this.headers2;
    _selectedData.headers2 = this.headers2;
    _selectedData.count = this.sheetContent.length;
    _selectedData.AllData = this.sheetContent;
    return _selectedData;
  }
}
