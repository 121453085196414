<form class="d-flex flex-column" [formGroup]='catalogForm' (ngSubmit)='createCatalogue()'>
  <mat-form-field class="mb-3" appearance='outline'>
    <mat-label>{{ 'common.fields.catalogue-name' | translate }}</mat-label>
    <input matInput type='text' formControlName='catalogName' required />
    <mat-error>{{ 'common.fields.required-field' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance='outline'>
    <mat-label>{{ 'common.fields.catalogue-description' | translate }}</mat-label>
    <textarea rows="2" matInput formControlName='catalogDescription'></textarea>
  </mat-form-field>

  <mat-label class="fw-600">{{ 'common.fields.catalogue-chapters' | translate }}</mat-label>
  <ng-container *ngIf="(chaptersView$ | async) as chaptersView">
    <ul class="list-unstyled homepage-card__chapters-list">
      <ng-container *ngIf="chaptersView?.hasChapters; else noResult">
        <li *ngFor="let chapter of chaptersView?.chapters">
            <mat-checkbox class="chapter-checkbox" color="primary" [formControlName]="chapter.ID">
                {{chapter?.Label}}
            </mat-checkbox>
        </li>
      </ng-container>  
    </ul>

    <ng-template #noResult>
      <div *ngIf="!chaptersView?.loading">{{'publication.homepage.no-chapter-found' | translate}}</div>
    </ng-template>

    <app-loader-spinner *ngIf='chaptersView?.loading' [diameter]="75"></app-loader-spinner>
  </ng-container>
  
  <button type='submit'
          class='homepage-card__create-btn'
          color='primary'
          mat-raised-button
          [disabled]='catalogForm.invalid || (loading$ | async)'>
    <span *ngIf='(loading$ | async)'
        class='spinner-border spinner-border-sm mr-1'></span>
    {{ 'publication.homepage.create-catalogue' | translate }}
  </button>
</form>
