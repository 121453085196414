<div class="file-upload-container">
  <div class="visuel-media">
    <ng-container *ngIf="currentPhoto; else addFileBlock">
      <div class="photo-block uploaded-img-block">
        <div class="uploaded-img-container" (click)="openFile()">
          <img
            [src]="isImage ? currentPhoto.src : 'assets/imgs/file-logo.png'"
            [alt]="currentPhoto.alt"
            [title]="currentPhoto.alt"
            class="img-responsive"
          />
<!--          DONT REMOVE THIS-->

<!--          <ul class="list-unstyled img-actions" *ngIf="!readOnlyAccess">-->
<!--            <li>-->
<!--              <a-->
<!--                (click)="addFile()"-->
<!--                queryParamsHandling="merge"-->
<!--                title="Modifier"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Modifier"-->
<!--                  >edit</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                title="Zoom"-->
<!--                target="_blank"-->
<!--                (click)="openFile()"-->
<!--                [attr.data-lightbox]="currentPhoto.src"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Zoomer"-->
<!--                  >zoom_in</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                title="Télécharger"-->
<!--                (click)="downloadImage()"-->
<!--                class="download-btn"-->
<!--              >-->
<!--                <mat-icon-->
<!--                  style="color: white; font-size: 20px"-->
<!--                  alt="Télécharger"-->
<!--                  >download</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li *ngIf="!readOnlyAccess">-->
<!--              <a-->
<!--                [routerLink]=""-->
<!--                (click)="remove()"-->
<!--                queryParamsHandling="merge"-->
<!--                title="Supprimer"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Supprimer"-->
<!--                  >delete_outline</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
      </div>
    </ng-container>

    <ng-template #addFileBlock>
      <div
        class="photo-block photo-add-wrapper"
        title="Ajouter un fichier"
        (click)="addFile()"
      >
        <input disabled type="file" name="file" id="file" class="inputfile" />
      </div>
    </ng-template>
  </div>

  <!--<div class="input-group">
        <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
            <input type="text" class="form-control" disabled [title]="currentMedia.Value"  [value]="currentMedia.Value">
        </ng-container>
        <div class="input-group-append">
            <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
                <button class="btn btn-primary np-button custom-button"
                        type="button"
                        title="Prévisualiser le fichier"
                        (click)="openFile()">
                    <i class="glyphicon glyphicon-zoom-in"></i>
                </button>
            </ng-container>
            <button class="btn btn-primary np-button custom-button"
                    [ngClass]="hasFile() ? '' : 'full-size'"
                    type="button"
                    title="Accès à un fichier"
                    (click)="addFile()">
                <i class="glyphicon glyphicon-pencil"></i> {{hasFile() ? '' : 'Associer un fichier'}}
            </button>

            <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
                <button class="btn btn-primary np-button custom-button"
                        type="button"
                        title="Supprimer le fichier"
                        (click)="remove()">
                    <i class="glyphicon glyphicon-remove"></i>
                </button>
            </ng-container>

        </div>
    </div>-->
</div>
