import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ProductSummary} from '../models';
import {AdvSearchArgs, DicoCarac, FileReaderService, NPElement, NPElementType, SearchRepository} from '../../../lib';
import {map, switchMap} from 'rxjs/operators';
import {ParamsFilterBuilder} from '@data/builders';
import {ElementLabels} from '@data/constants';
import {SpUserInfoService, TableHandlerService} from '@data/services';
import {ThumbsSizeService} from '../services/thumbs-size.service';
import {MediaSearchResult} from '../models/advanced-search-result.model';
import {MatTableDataSource} from '@angular/material/table';

@Injectable({
    providedIn: 'root'
})
export class MediaFacade {

    private paramFilterBuilder: ParamsFilterBuilder;

    private npElementToCards = (element: NPElement): Partial<ProductSummary> =>
        new ProductSummary({
            label: element.getValueTextValue(ElementLabels[element.ElementType]),
            modificationDate: TableHandlerService.toDate(TableHandlerService.getLastModifiedInfos(element)),
            action: '', // Permet de créer la colonne "Actions" dans le tableau pour afficher les boutons
            element: element,
            imageUrl: '',
            imageName: element.getValueTextValue(DicoCarac.MEDIA_FILE)
        })

    constructor(
        private _searchRepository: SearchRepository,
        private _fileReaderService: FileReaderService,
        private _thumbsSizeService: ThumbsSizeService,
        private _userInfoService: SpUserInfoService) {
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(10).withElementTypes([NPElementType.Media]);
    }

    public getImageUrl(image: string): string {
        return this._fileReaderService.toFilePath(image);
    }

    public getFilePathToDownload(image: string): string {
        return this._fileReaderService.toFilePathToDownload(image);
    }

    public getFilePathToDownloadCustom(image: string, size: string) {
        return this._fileReaderService.toFilePathToDownloadCustomSize(image, size);
    }

    search(advancedSearch: AdvSearchArgs, multiOperators: boolean): Observable<MediaSearchResult> {
        const searchRoute = multiOperators ? this._searchRepository.SearchWithMultipleOperators(advancedSearch) : this._searchRepository.Search(advancedSearch);
        return advancedSearch ?
            searchRoute
                .pipe(
                    map(result => {
                        return {
                            cards: result?.elements?.map(element => this.npElementToCards(element)) || [],
                            count: result.resultsCount
                        };
                    }),
                    switchMap(result => {
                        return this._thumbsSizeService.getAllowedThumbsSize()
                            .pipe(
                                map(availableSizes => {
                                    return {
                                        ...result,
                                        cards: result.cards.map(card => {
                                            card.imageUrl = this.getImageUrl(card.imageName);
                                            card.downloadUrl = [
                                                {
                                                    label: 'original', url: this.getFilePathToDownload(card.imageName)},
                                                ...availableSizes.map(size => ({
                                                    label: size,
                                                    url: this.getFilePathToDownloadCustom(card.imageName, size)
                                                }))
                                            ];
                                            return card;
                                        })
                                    };
                                })
                            );
                    }),
                    map(result => {
                        return ({
                            ...result,
                            mediaSummary: new MatTableDataSource<ProductSummary>(result.cards as ProductSummary[]),
                        });
                    })
                ) :
            of(null);
    }
}
