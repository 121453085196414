import { Injectable } from '@angular/core';
import { ElementRepository } from '@nextpage/np-sdk-data';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ElementReaderService {
    constructor(private _elementRepository: ElementRepository) {
    }

  public getDescendants(familyExtID: string, onlyOneLevel = false, dicoCaracs: string[] = []) {
    if (familyExtID === null || familyExtID === '') {
      return throwError(
        'Element extID not found. Please create or set custom field or set value in your PIM'
      );
    }
    return this._elementRepository
      .getDescendants([familyExtID], [[]], onlyOneLevel, dicoCaracs)
      .pipe(
        map(response => {
          return response && response.has(familyExtID)
            ? response.get(familyExtID)
            : null;
        }),
        catchError(response => {
            console.error(response);
          return throwError(
            'Erreur lors de la récupération des données. Vérifier si vous êtes bien connectés.'
          );
        })
      );
  }
}
