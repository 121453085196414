import {Component, forwardRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DicoCarac, NPCaracStd} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../Model';
import {ThemingService} from '../../../services';
import jss from 'jss';

export interface Sheet {
    readonly classes: Object;
    // readonly update: Function;
}

@Component({
    selector: 'lib-app-value-text-read-view',
    templateUrl: './np-value-text-read-view.component.html',
    styleUrls: ['./np-value-text-read-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueTextReadViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;

    fillrateGlobal = false;
    fillrateGlobalFull = false;

    public classes: Object;

    constructor(@Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {
        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('NpValueTextReadViewComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes.value && changes.value.currentValue) {
        this.fillrateGlobal = changes.value.currentValue.DicoCaracExtID === DicoCarac.PRODUCTFILLRATE_GLOBAL;
        this.fillrateGlobalFull = <number>changes.value.currentValue.Value === 100;
      }
    }

    getFormattedValue() {
        if (this.value == null || this.value.Value == null) {
            return '';
        }
        const unite = this.caracInfo.unite ? ' ' + this.caracInfo.unite : '';
        return this.value.Value.replace(/\r?\n/g, '<br />') + unite;
    }

}
