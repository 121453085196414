import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectAdvancedSearch} from 'src/app/store/selector/advanced-search.selector';
import {selectMediaSearchResult} from 'src/app/store/selector/media-library.selector';
import {FormControl} from '@angular/forms';
import {SearchMedia} from '@store/action';
import {
    LIBRARY_MEDIA_DEFAULT_PAGE_INDEX,
    LIBRARY_MEDIA_HOME_PAGE_SIZE
} from 'src/app/data/constants/advanced-search.constants';
import {selectDataLanguage} from 'src/app/store/data-languages/data-language.selector';
import { AdvSearchArgs } from 'src/lib';

@Component({
    selector: 'app-np-home-page',
    templateUrl: './np-home-page.component.html',
    styleUrls: ['./np-home-page.component.scss'],
})
export class NpHomePageComponent implements OnInit, OnDestroy {
    @ViewChild('searchFilter') searchFilter!: ElementRef;

    private _advancedSearch: AdvSearchArgs;
    private _storeSub: Subscription;

    public searchFormControl = new FormControl();
    public mediaView$ = this._store.select(selectMediaSearchResult);

    constructor(private _store: Store) {
    }

    ngOnInit(): void {
        this.getMediasList();

        this.searchFormControl.valueChanges
            .subscribe(valueToSearch => {
                // Reset data if value to search is empty
                if (!valueToSearch && this._advancedSearch) {
                    this._advancedSearch.Config.Filters.Keywords = '';
                    this._store.dispatch(SearchMedia({advancedSearch: this._advancedSearch, multiOperators: false}));
                }
            });
    }

    private getMediasList(): void {
        // Init media
        this._storeSub = combineLatest([
            this._store.select(selectAdvancedSearch),
            this._store.select(selectDataLanguage)
        ])
        .subscribe(([advancedSearch, selectedDataLang]) => {
            if (advancedSearch) {
                this._advancedSearch = Object.assign({}, advancedSearch) as AdvSearchArgs;
                this._advancedSearch.PageSize = LIBRARY_MEDIA_HOME_PAGE_SIZE;
                this._advancedSearch.CurrentPage = LIBRARY_MEDIA_DEFAULT_PAGE_INDEX;
                this._advancedSearch.Config.Filters.Keywords = '';
                this._advancedSearch.LangID = selectedDataLang?.ID;
                this._store.dispatch(SearchMedia({advancedSearch: this._advancedSearch, multiOperators: false}));
            }
        });
    }

    ngOnDestroy(): void {
        this._storeSub?.unsubscribe();
    }

    searchByKeyword(value: string): void {
        if (this._advancedSearch) {
            this._advancedSearch.Config.Filters.Keywords = value;
            this._store.dispatch(SearchMedia({advancedSearch: this._advancedSearch, multiOperators: false}));
        }
    }

    public resetSearch(event: Event): void {
        this.searchFilter.nativeElement.value = '';
        this.searchByKeyword(event.target['value']);
    }
}
