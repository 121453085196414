import {createReducer, on} from '@ngrx/store';
import { ChoiceCriteria } from 'src/app/data/types';
import {FetchPublicationChoiceCriteriasSuccess, FetchPublicationChoiceCriteriasFailure} from '@store/publication';

export const publicationChoiceCriteriasFeatureKey = 'publicationChoiceCriterias';

export interface ChoiceCriteriasState {
    choiceCriterias: ChoiceCriteria[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialChoiceCriteriasState: ChoiceCriteriasState = {
    choiceCriterias: [],
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const publicationChoiceCriteriasReducer = createReducer(
    initialChoiceCriteriasState,
    on(
        FetchPublicationChoiceCriteriasSuccess,
        (state, {choiceCriterias}): ChoiceCriteriasState => {
            return ({
                ...state,
                choiceCriterias: choiceCriterias,
                loading: false,
                errorMessage: null,
                hasError: false
            });
        }
    ),
    on(FetchPublicationChoiceCriteriasFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
