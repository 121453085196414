import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChaptersTags, TemplatesNames } from 'src/app/data/enums/publication-tags.enum';
import { CatalogPage, Chapter } from '@app/models';
import { CatalogueTemplateSelectionComponent } from '../catalogue-template-selection/catalogue-template-selection.component';

@Component({
  selector: 'app-catalogue-chapter-row',
  templateUrl: './catalogue-chapter-row.component.html',
  styleUrls: ['./catalogue-chapter-row.component.scss']
})
export class CatalogueChapterRowComponent implements OnInit {

  @ViewChild('catalogueTemplateSelectionComponent') catalogueTemplateSelectionComponent: CatalogueTemplateSelectionComponent;

  @Input() chapter: Chapter;
  @Input() leftPage?: CatalogPage;
  @Input() rightPage?: CatalogPage;

  public isFirstBlankPage: boolean;
  public productPageTypeEnum = TemplatesNames.PRODUCT;
  public templateSelectionDisabled = false;

  ngOnInit(): void {
    this.isFirstBlankPage = this.chapter?.name?.includes(ChaptersTags.INTRO) && this.leftPage?.name === '';

    this.templateSelectionDisabled =  this.chapter && (this.chapter.isIntroductionChapter || this.chapter.isEndChapter || this.isFirstBlankPage);
  }

  public handleComponentLifeCycle(attempts: number = 0): void {
    //TODO to be reworked
    // if (attempts >= 5) {
    //   console.error('Le composant n\'a pas pu être chargé après 5 tentatives.');
    //   return;
    // }

    // if (!this.catalogueTemplateSelectionComponent) {
    //   setTimeout(() => {
    //     this.handleComponentLifeCycle(attempts + 1);
    //   }, 1);
    // } else {
    //   this.catalogueTemplateSelectionComponent.triggerSelectTemplateClickEvent();
    // }
  }
}
