import {createReducer, on} from '@ngrx/store';
import {LanguageActions} from '../actions/language.action';

export const languageFeatureKey = 'uiLanguage';

export interface LanguageState {
    selectedUiLang: string;
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialState: LanguageState = {
    selectedUiLang: null,
    errorMessage: null,
    loading: false,
    hasError: false
};


export const languageReducer = createReducer(
    initialState,
    on(
        LanguageActions.fetchSelectedLanguageSuccess, (state, {language}) => ({
            ...state,
            selectedUiLang: language,
            loading: true,
            hasError: false,
            errorMessage: null
        }))
);
