<!--Si mode lecture/écriture-->
<ng-container *ngIf="writeAccess; else stringDateBlock">
    <div class="input-date">
        <input #dateField (click)="hideDatePickerToggle() ? picker.open() : null" [(ngModel)]="dateToPass" matInput [matDatepicker]="picker" (ngModelChange)="onChange($event)" placeholder="Sélectionnez une date" class="input-border"
               [class.red-border]="!isValid" (keypress)="numberAndNumpadDivideOnly($event)">
        <ng-container *ngIf="!hideDatePickerToggle()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        </ng-container>
        <mat-datepicker #picker></mat-datepicker>
        <div class="invalidDate">
            <span class="requied-error-message inpu-custo" *ngIf="!isValid && !invalidDate">Champ obligatoire</span>
            <span class="requied-error-message" *ngIf="invalidDate">Valeur incorrecte</span>
        </div>
    </div>
</ng-container>

<!--Si mode lecture seule-->
<ng-template #stringDateBlock >
    <span>{{getStringDate()}}</span>
</ng-template>


