import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Messages} from '../constants/message';
import {DialogService} from '../../graphics/services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(
        private readonly _snackBar: MatSnackBar,
        private readonly _dialogService: DialogService,
    ) {
    }

    display = (message: Messages | string) => this._snackBar.open(message);

    error = (
        summary: string,
        detail = '',
        severity = null
    ) => this._dialogService.openErrorDialog({summary, detail, severity, config: {displayCloseButton: true}})
}
