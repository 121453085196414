import {FetchUserInfoFailure, FetchUserInfoSuccess} from '../actions';
import {createReducer, on} from '@ngrx/store';
import {NpUser} from '../../../lib';

export const userInfoFeatureKey = 'userInfo';

export interface UserInfoState {
  userInfo: NpUser;
  errorMessage: string | null;
  loading: boolean;
  hasError: boolean;
}

export const initialUserInfo: UserInfoState = {
  userInfo: null,
  errorMessage: null,
  loading: true,
  hasError: false
};

export const userInfoReducer = createReducer(
  initialUserInfo,
  on(
    FetchUserInfoSuccess,
    (state, {userInfo}): UserInfoState => ({
      ...state,
      userInfo: userInfo,
      loading: false,
      errorMessage: null,
    })
  ),
  on(FetchUserInfoFailure,
    (state, {message}) => ({
      ...state,
      errorMessage: message,
      loading: false,
      hasError: true
    })
  )
);
