import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CaracInfo, SummaryElement} from '../np-value/Model';
import {
    Access,
    CaracConfig,
    ElementRepository,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracValeur,
    NPElement,
    NPListeValues,
    StructuredDataService,
    UiTranslationService,
    ValueOneElementHelper,
} from '@nextpage/np-sdk-data';

import jss from 'jss';
import {Sheet, ThemingService} from '../../services';
import {Subscription} from 'rxjs';

@Component({
    selector: 'lib-select-list-value-lien',
    templateUrl: './select-list-value-lien.component.html',
    styleUrls: ['./select-list-value-lien.component.scss']
})
export class SelectListValueLienComponent implements OnInit, OnDestroy {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracValeur;
    @Input() caracConfig: CaracConfig;
    @Input() baseElement: NPElement = null;

    public selectFilterCtrl: UntypedFormControl = new UntypedFormControl();

    listPossibles: SummaryElement[] = [];
    listPossiblesFiltered: SummaryElement[] = [];
    _initialListPossiblesFiltered: SummaryElement[] = [];
    listLinked: SummaryElement[] = [];

    public voe: ValueOneElementHelper = new ValueOneElementHelper();
    public displayValues: NPListeValues[];
    public selectedValue: SummaryElement;
    public dropDownControl = new UntypedFormControl();

    private _translations = new Map<string, string>();
    private _baseElementSubscription: Subscription;
    public isValid = true;
    public readOnlyAccess: boolean;

    private _voe = new ValueOneElementHelper();
    public hasUnite = false;

    public classes: Object;

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                private _structuredDataService: StructuredDataService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {
        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;
        this.hasUnite = this._voe.hasUnite(this.caracConfig);
        this._initializeListePossibles();

        /**
         * Reçoit un signal lorsque l'utilisateur clique sur le bouton enregistrer
         * Le but eet de vérifier si toutes les données sont conformes
         * et d'afficher un message d'erreur elle ne le sont pas
         */
        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });

        // listen for search field value changes
        this.selectFilterCtrl.valueChanges
            .subscribe(newValue => {
                this.listPossiblesFiltered = newValue !== '' ?
                    this._initialListPossiblesFiltered.filter(currElement => currElement.elementName.toUpperCase().indexOf(newValue.toString().toUpperCase()) !== -1) :
                    this._initialListPossiblesFiltered;
            });

        const override_css = this._theming.getComponentConfigStyle('SelectListValueLienComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }

        // abonnement aux modifications de baseElement pour être sur de toujours être sur le bon lien
        if (this.baseElement != null && this.caracConfig.Links != null && this.caracConfig.Links.length)
        {
            // si on travaille sur un objet lié qui peut être amené à changer (typiquement l'objet lié à une donnée structurée), il faut s'abonner
            if (this.getSpecificConf('baseElementListen'))
            {
                // attention, on cherche les premières modifications sur le "baseElement", ça ne fonctionne pas si on n'a pas de lien ou si on a un lien d'un lien d'un lien
                this._baseElementSubscription = this._elementWriter.getElementModificationsNotifier({elementExtID: this.baseElement.ExtID, dicoCaracExtID: this.caracConfig.Links[0]}, true)
                .subscribe((d)=>
                {
                    if (!d){
                        return;
                    }
                        
                    this.value = this._voe.getCaracValue(this.baseElement, this.caracConfig);
                    if (this.value != null){
                        this._initializeListePossibles();// TODOTMR ici, un subscribe dans un subscribe, pas top
                    }
                    
                }
                );
            }
        }
        
    }

    public ngOnDestroy(){
        if (this._baseElementSubscription){
            this._baseElementSubscription.unsubscribe();
        }
        if (this._selectOptionSubscriber){
            this._selectOptionSubscriber.unsubscribe();
        }
    }

    public initValues() {
        if (this.value != null) {
            const DicocaracExtID = this.caracConfig.DicoCaracExtID;
            const valeur = this.value.Element.getValueLien(DicocaracExtID);
            if (valeur.LinkedElements !== undefined && valeur.LinkedElements.length && valeur.LinkedElements[0]) {
                const valeurID = valeur.LinkedElements[0].ElementID;
                const tmpSelectValue = this.listPossibles.find(value => value.elementID === valeurID);
                if (tmpSelectValue) {
                    this.selectedValue = tmpSelectValue;
                    this.dropDownControl.setValue(this.selectedValue);
                }
            }
        } else {
            this.selectedValue = null;
        }
    }

    private getSpecificConf(propertyName: string){
        if (this.caracConfig.Specific && this.caracConfig.Specific.hasOwnProperty(propertyName)){
            return this.caracConfig.Specific[propertyName];
        }
        return null;
    }
    /**
     * pour gérer l'abonnement au réception des nouvelles valeurs
     */
    private _selectOptionSubscriber: Subscription;
    /**
     * Sélectionne l'option
     * TODO interface à créer : valeur
     */
    public selectOption(valeur: any) {

        if (!valeur || !this.getSpecificConf('reloadOnSelect'))
        {
            this._doSelectOption(valeur);
        }else{
            if (this._selectOptionSubscriber){
                this._selectOptionSubscriber.unsubscribe();
            }
                
            this._selectOptionSubscriber = this._elementRepository.getElements([valeur.element.ExtID], [])
            .subscribe((d)=>{
                valeur.element = d.get(valeur.element.ExtID);
                this._doSelectOption(valeur);
            });
        }
       
    }

    private _doSelectOption(valeur: any){
        if (valeur) {
            this.selectedValue = valeur;
            this._elementWriter.concatValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, valeur.element, false);
        } else {
            this.moveItem();
        }
        this._structuredDataService.setSelectChanges({
            elementSD: this.value.Element,
            elementLinked: valeur ? valeur.element : null,
            dicoCaracExtID: this.caracConfig.DicoCaracExtID
        });
        // Permet de filtrer la liste des données structurées
        this._structuredDataService.filterSucturedDataList(valeur ? valeur.element : null, this.caracConfig.DicoCaracExtID);
        this._initRequiedError();
    }

    /**
     * Initialisation de la liste des éléments possibles
     */
    private _updateListePossible(searchText: string) {
        this.listPossibles = [];
        if (this.value != null && this.value.Element != null){
           
            this._elementRepository.searchForLink(this.caracConfig.DicoCarac, searchText, this.value.Element)
            .subscribe((result: NPElement[]) => {
                this.listPossibles = result.map(rel => {
                    // On ajoute l'élément à la liste s'il n'est pas lié
                    return {
                        elementName: this.voe.getLabel(rel),
                        elementID: rel.ID,
                        elementOrder: rel.getValueSearchRankLevelSearchRanking(),
                        element: rel
                    };
                });
                this.initValues();
                this._filterListPossibles();
                this._sortListPossibleByName();
            });
            this._sortListPossibleByName();
        }        
    }

    /**
     * Initilisation de la liste des éléments possibles
     */
    private _initializeListePossibles() {
        this._updateListePossible('');
    }

    /**
     * Tri de liste des éléments possibles
     */
    private _sortListPossibleByName() {
        this.listPossibles.sort((e1, e2) => e1.elementName < e2.elementName ? -1 : 1);
        this.listPossiblesFiltered.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
        this._initialListPossiblesFiltered = Object.assign([], this.listPossiblesFiltered);
    }

    /**
     * Filtrer la liste des éléments possibles pour éviter d'afficher les éléments qui sont aussi liés
     */
    private _filterListPossibles() {
        const listIDs = this.listLinked.map((val) => val.elementID);
        this.listPossiblesFiltered = this.listPossibles.filter((val) => !listIDs.includes(val.elementID));
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

    private moveItem() {
        const tmpValueLink = this.value.Element.getValueLien(this.caracConfig.DicoCarac.ExtID);
        if (tmpValueLink) {
            tmpValueLink.RebuildLinkedElements = [];
        }
    }

    private _initRequiedError() {
        this.isValid = this.value && this.value.Element && this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    resetField() {
        this.selectFilterCtrl.setValue('');
    }
}
