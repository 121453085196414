import {NPElementType} from '../../../lib';
import {Chars} from './chars';
import {Family} from './family';
import {NpDictionaryDto} from '@app/models';

export class Filters {
  ElementType = NPElementType.Product;
  ElementTypes?: NPElementType[] = [];
  CreatorID?: number;
  CreationDate?: string;
  CreationDateOperator?: string;
  ModificationDate?: string;
  ModificationDateOperator?: string;
  WFStatusID?: number;
  WFStatusOperator?: string;
  Family?: Family = {ID: 0, label: ''};
  Channel?: Family = {ID: 0, label: ''};
  ProductsTypeID?: number;
  ProductsType375?: NpDictionaryDto;
  Keywords?: string;
  Chars?: Chars = new Chars();
  Facets?: string[];
}

