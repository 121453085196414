<div class="dialog-content__products-list">
    <ng-container *ngIf="products?.length > 0; else noResults">
        <mat-card class="align-items-start products-list__item"
        *ngFor="let product of products">
            <app-product-item class="w-100" 
            [product]="product" 
            [descriptionFieldExtId]="descriptionField"
            (click)="selectProduct(product)"></app-product-item>
        </mat-card>
    </ng-container>
    <ng-template #noResults>
        <div *ngIf="!loading">{{'common.no-result' | translate}}</div>
    </ng-template>

    <app-loader-spinner *ngIf='loading' [diameter]="75"></app-loader-spinner>
</div>

<div class="d-flex justify-content-center">
    <p-paginator 
    styleClass="products-pagination"
    (onPageChange)="onPageChange($event)" 
    [rows]="pageSize"
    [totalRecords]="totalRecords" 
    [rowsPerPageOptions]="[10, 20, 30]"/>
</div>