export enum RoutePortalsName {
    CONSULTATION = 'consultation',
    MEDIA_LIBRARY = 'media-library',
    SUPPLIER = 'supplier',
    PUBLICATION = 'publication',
    NO_INSTANCE_FOUND = 'no-instance-found'
}

export enum RouteParamName {
    INSTANCE_ID = 'instanceId'
}
