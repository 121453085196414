<div>

  <button *ngIf="(dataLanguageView$ | async)?.selectedDataLanguage as selectedDataLanguage" mat-flat-button [matMenuTriggerFor]="menu" aria-label="Language" class="current-language">
    <mat-icon>flag</mat-icon>
    {{selectedDataLanguage?.LibelleLong}}
    <mat-icon iconPositionEnd>expand_more</mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before" class="languages__list">
    <ng-container *ngIf="(dataLanguageView$ | async) as dataLanguageView">
      <ng-container *ngFor="let language of dataLanguageView.dataLanguages">
        <button class="center-item" mat-menu-item (click)="changeDataLanguage(language)">
          {{language.LibelleLong}}
        </button>
      </ng-container>
    </ng-container>
  </mat-menu>
</div>
