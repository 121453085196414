import {createReducer, on} from '@ngrx/store';
import {FetchElementsFailure, FetchElementsLoader, FetchElementsSuccess} from './elements.actions';
import {ProductSummaryWithTotalRow} from '@data/types';

export const elementsFeatureKey = 'elements';

export interface ElementsState {
    productWithTotalRow: ProductSummaryWithTotalRow;
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialElements: ElementsState = {
    productWithTotalRow: null,
    errorMessage: null,
    loading: true,
    hasError: false
};

export const elementsReducer = createReducer(
    initialElements,
    on(
        FetchElementsLoader,
        (state, ): ElementsState => ({
            ...state,
            productWithTotalRow: null,
            loading: true, // To maintain the loader
            errorMessage: null,
        })
    ),
    on(
        FetchElementsSuccess,
        (state, {productSummariesResult}): ElementsState => ({
            ...state,
            productWithTotalRow: productSummariesResult,
            loading: false,
            errorMessage: null,
        })
    ),
    on(FetchElementsFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
