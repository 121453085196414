<div>
    <h3>{{ 'import.title' | translate }}</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<mat-vertical-stepper class="mat-elevation-z2">
    <mat-step>
        <ng-template matStepLabel>{{ 'import.data-import' | translate }}</ng-template>
        <!--    <div class="stepper-content">-->
        <!--      <h5 class="stepper-title">-->
        <!--        1. Téléchargez le modèle de matrice d'import-->
        <!--        <mat-icon-->
        <!--          [ngStyle]="{-->
        <!--            color: fileDownload ? 'green' : 'red'-->
        <!--          }"-->
        <!--          >{{ fileDownload ? "check" : "close" }}</mat-icon-->
        <!--        >-->
        <!--      </h5>-->
        <!--      <button mat-raised-button>Télécharger le modèle de fichier</button>-->
        <!--    </div>-->
        <div class="stepper-content">
            <h5 class="stepper-title">
                1. {{ 'import.import-data' | translate }}
                <mat-icon
                        [ngStyle]="{
            color: selectedFile ? 'green' : 'red'
          }"
                >{{ selectedFile ? "check" : "close" }}</mat-icon
                >
            </h5>
            <div
                    class="dropzone fullscreen"
                    fileDrop
                    (filesDropped)="handleDrop($event[0])"
                    (filesHovered)="handleHover($event)"
                    [class.hovered]="dropZoneHovered"
            >
                <div>
                    <div class="dropzone-info">
            <span class="selected-file">{{
                selectedFile ? fileName : "import.no-selected-file" | translate
                }}</span>
                    </div>
                    <input
                        #inputFile
                        style="display: none"
                        type="file"
                        (change)="onFileChange($event)"
                        accept="*"
                    />
                    <div class="input-file-block">
                        <p>{{ 'import.drag-file' | translate }}</p>
                        <p>{{ 'common.or' | translate }}</p>
                        <button mat-raised-button (click)="inputFile.click()">
                            {{ 'import.import-file' | translate }}
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="fileError">
                    <div class="file-error-block">
                        <span class="glyphicon glyphicon-ban-circle"></span>
                        <span>{{ 'import.incorrect-file-extension' | translate }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="steps-button-next">
            <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!selectedFile"
            >
            {{ 'common.next' | translate }}
            </button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>{{ 'import.data-verification' | translate }}</ng-template>
        <div class="stepper-content" style="overflow: auto;">
            <h5 class="stepper-title">2. {{ 'import.check-data' | translate }}</h5>
            <div class="stepper-table">
                <table
                        *ngIf="dataSummary && dataSummary.data && dataSummary.data.length > 0"
                        mat-table
                        [dataSource]="dataSummary.data"
                >
                    <ng-container
                            *ngFor="let header of dataSummary['headers']"
                            [matColumnDef]="header"
                    >
                        <th mat-header-cell *matHeaderCellDef>{{ header }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row[header] }}
                        </td>
                    </ng-container>
                    <tr
                            mat-header-row
                            *matHeaderRowDef="dataSummary['headers']; sticky: true"
                    ></tr>
                    <tr mat-row *matRowDef="let row; columns: dataSummary['headers']"></tr>
                </table>
            </div>
        </div>
        <div class="steps-buttons">
            <button mat-raised-button matStepperPrevious>{{ 'common.back' | translate }}</button>
            <button mat-raised-button color="primary"
                    [disabled]='isLoading'
                    (click)="executeDataImport()">
                <span
                        *ngIf="isLoading"
                        class='spinner-border spinner-border-sm mr-1'
                ></span>{{ 'import.validate-import' | translate }}
            </button>
        </div>
    </mat-step>
</mat-vertical-stepper>
