import {createReducer, on} from '@ngrx/store';
import {
    ExecuteElementsExport,
    ExecuteElementsExportFailure,
    ExecuteElementsExportSuccess
} from './elements-export.actions';

export const elementsExportFeatureKey = 'elementsExport';

export interface ElementExportState {
    exported: boolean;
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialStateElementExport: ElementExportState = {
    exported: false,
    errorMessage: null,
    loading: false,
    hasError: false
};

export const elementsExportReducer = createReducer(
    initialStateElementExport,
    on(
        ExecuteElementsExport,
        (state, ): ElementExportState => ({
            ...state,
            exported: false,
            loading: true,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        ExecuteElementsExportSuccess,
        (state, {exported}): ElementExportState => ({
            ...state,
            exported,
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        ExecuteElementsExportFailure,
        (state, {message}): ElementExportState => {
            return ({
                ...state,
                exported: false,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
);
