import {ModuleWithProviders, NgModule} from '@angular/core';
import {UiTranslateDirective} from './ui-translate.directive';
import {UiTranslationService} from '@nextpage/np-sdk-data';


@NgModule({
    declarations: [UiTranslateDirective],
    exports: [UiTranslateDirective],
    providers: []
})
export class UiTranslateModule {
    static forRoot(): ModuleWithProviders<UiTranslateModule> {
        return {
            ngModule: UiTranslateModule,
            providers: [
                UiTranslationService
            ]
        };
    }
}
