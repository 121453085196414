import {TreeNodeCatalogAccess} from './tree-node-catalog-access';

export class TreeNodeCatalogDto {
    Locked: boolean;
    UserIdLock: number;
    Droits: TreeNodeCatalogAccess;
    Children: any[];
    CountChild: number;
    CountAllChild: number;
    CountRef: number;
    CountSpecific: number;
    CssClass: null;
    ID: number;
    Type: string;
    Name: string;
    State: null;
    DnD: null;
    Order: number;
    Rank: number;
    ParentID: number;
    ZeroIfNullParentIDForVB: number;
    UniqueID: string;
    DisableAutoExpanded: boolean;
    PdfFileName: string;
    ZipFileName: string;
}
