import {Facet, Item} from '@data/types';
import {NpwFilterOperator, TypeCode} from '@nextpage/np-sdk-data';
import * as _ from 'lodash';
import {ItemBuilder, SelectedDicoBuilder} from '@data/builders';
import {environment} from '@env';
import { NPElementType } from 'src/lib';


/***
 * Returns the value of a facet based on its type
 * @param facet
 */
export function getFacetValueByType(facet: Facet): string | number {
    if (_.isNil(facet)) {
        throw Error('Error: Null or empty facet.');
    }

    switch (facet.typeCode) {
        case TypeCode.CHOIX_NP:
            return facet.ValueId;
        case TypeCode.LISTSPE:
            return facet.ValueId;
        case TypeCode.TXT:
            return facet.Value;
        case TypeCode.TXTLONG:
            return facet.Value;
        case TypeCode.NUMERIC:
            return facet.Value;
        case TypeCode.TXTHTML_HTML:
            return facet.Value;
        case TypeCode.IMG_NP:
            return facet.Value;
        default:
            throw Error(`Error: Unsupported ${facet.typeCode} Type code.`);
    }
}

export function convertFacetMapToItems(collection: Map<number, Facet[]>, isMedia?: boolean): Item[] {
    if (_.isEmpty(collection) || _.isNull(collection)) {
        return [];
    }
    return Array.from(collection.values())
        .reduce((accumulator, facets) => {
            accumulator = [...accumulator, ...convertFacetsToItems(facets, isMedia)];
            return accumulator;
        }, [] as Item[]);
}

export function convertFacetsToItems(facets: Facet[], isMedia?: boolean): Item[] {
    if (_.isEmpty(facets) || _.isNull(facets)) {
        return [];
    }

    return _.map(facets, (facet) => facetToItem(facet, isMedia));
}

export function facetToItem(facet: Facet, isMedia?: boolean): Item {
    return _.isNull(facet)
        ? null
        : new ItemBuilder()
            .withOperatorValue(NpwFilterOperator.Equal)
            .withValue(getFacetValueByType(facet))
            .withSelectedDico(
                new SelectedDicoBuilder()
                    .withDicoCaracExtId(facet.DCExtID)
                    .withTypeCode(facet.typeCode)
                    .withID(facet.DicoCaracID)
                    .withElType(isMedia ? NPElementType.Media : environment.instance.elementType)
                    .build()
            ).build();
}
