<div class="upload-photos-block">
  <!--Upload-->
  <div class="photos-header">
    <input disabled type="file" name="file" id="file" class="inputfile" />
    <label for="file" (click)="addPicture();">Ajouter</label>
  </div>
  <!--Listing photos uploadées-->
  <div class="row photos-content">
    <!-- TODO A transformer en boucle -->
    <div class="col-sm-4 uploaded-img-block" *ngFor="let photo of photosUrl">
      <div class="uploaded-img-container">
        <img [src]="photo.src" [alt]="photo.alt" class="img-responsive" />
        <ul class="list-unstyled img-actions">
          <li>
            <a [routerLink]="[]" (click)="changePicture(photo)" queryParamsHandling="merge" title="Modifier">
              <img src="assets/imgs/picto-modifier-photo.png" alt="Modifier" />
            </a>
          </li>
          <li>
            <a [href]="photo.src" download queryParamsHandling="merge" [attr.data-lightbox]="photo.src" [attr.data-title]="photo.alt">
              <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom" />
            </a>
          </li>
          <li>
            <a [href]="photo.src" download queryParamsHandling="merge" title="Télécharger">
              <img src="assets/imgs/picto-telecharger-photo.png" alt="Télécharger" />
            </a>
          </li>
          <li>
            <a [routerLink]="[]" (click)="deletePicture(photo)" queryParamsHandling="merge" title="Supprimer">
              <img src="assets/imgs/picto-supprimer-photo.png" alt="Supprimer" />
            </a>
          </li>
        </ul>
      </div>
      <div class="uploaded-img-name">{{photo.alt}}</div>
    </div>
  </div>
</div>
