<div class="catalogues-list" *ngIf="(catalogTreeView$ | async) as catalogTreeView">
  <ng-container *ngIf="catalogTreeView?.hasCatalogs; else noResult">
    <ng-container *ngFor="let catalog of catalogTreeView.catalogTree">
      <div class="d-flex align-items-center justify-content-between catalogues-list__item">
        <span class="catalogue-name">{{catalog.name}}</span>
        <div class="catalogues-list__item-actions">
          <button mat-icon-button 
          *ngIf="catalog.pdfFileName"
          (click)="downloadPdf(catalog)" 
          [matTooltip]="'publication.header.download-pdf' | translate">
            <fa-icon [icon]="['far', 'file-pdf']"></fa-icon>
          </button>
          <button mat-icon-button 
          *ngIf="catalog.zipFileName"
          (click)="downloadSources(catalog)" 
          [matTooltip]="'publication.header.download-sources' | translate">
            <fa-icon [icon]="['far', 'download']"></fa-icon>
          </button>
          <button mat-icon-button (click)="editCatalog(catalog)" [matTooltip]="'publication.homepage.edit-catalogue' | translate">
            <fa-icon [icon]="['far', 'pen']"></fa-icon>
          </button>
          <button mat-icon-button (click)="duplicateCatalog(catalog)" [matTooltip]="'publication.homepage.duplicate-catalogue' | translate">
            <fa-icon [icon]="['fas', 'clone']"></fa-icon>
          </button>
          <button mat-icon-button (click)="confirmCatalogDeletion(catalog)" [matTooltip]="'publication.homepage.delete-catalogue' | translate">
            <fa-icon [icon]="['far', 'trash']"></fa-icon>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>

  <ng-template #noResult>
    <div *ngIf="!catalogTreeView?.loading">{{'publication.homepage.no-catalogue-found' | translate}}</div>
  </ng-template>

  <app-loader-spinner *ngIf='catalogTreeView?.loading' [ngClass]="{'position-fixed': actionButtonClicked}" [diameter]="75"></app-loader-spinner>
</div>


