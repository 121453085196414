import { Component, OnInit } from '@angular/core';
import { FetchUserInfo } from '@store/action';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/graphics/components/base/base.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent extends BaseComponent implements OnInit {
  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(FetchUserInfo());
  }
}
