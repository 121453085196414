import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {FetchAdvancedSearch} from '../actions';
import {FetchAdvancedSearchFailure, FetchAdvancedSearchSuccess} from './advanced-search.actions';
import {SearchRepository} from '../../../lib';
import {AdvancedSearchWriter} from '../../core/services/advanced-search/advanced-search-writer.service';

@Injectable()
export class AdvancedSearchEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private _advancedSearchWriter: AdvancedSearchWriter,
        private _searchRepository: SearchRepository
    ) {
    }

    fetchAdvancedSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchAdvancedSearch),
            switchMap(() => {
                    return this._advancedSearchWriter.createEmpty()
                        .pipe(
                            map((avdSearch) => FetchAdvancedSearchSuccess({advSearch: avdSearch})),
                            catchError(error => of(FetchAdvancedSearchFailure({message: error.toString()})))
                        );
                }
            )
        );
    });

}
