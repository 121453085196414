import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CaracConfig, DicoCarac, NPDicoCarac, NPElement, TypeCode} from '../model';

export interface CarcsByBlock {
    blockName: string;
    caracConfig: CaracConfig;
    pageName: string;
}

@Injectable({
    providedIn: 'root'
})
export class ManagementRulesCheckerService {

    public subjectCheck = new BehaviorSubject(false);
    public _startChecking = new BehaviorSubject(false);
    public carcsByBlock: CarcsByBlock[] = [];

    constructor() {
    }

    public isValueValide(element: NPElement, caracConfig: CaracConfig) {
        // si la valeur est obligatoire, on vérifie si le champs a été renseigné
        if (caracConfig && caracConfig.DicoCarac && caracConfig.DicoCarac.Required === '1') {
            // Si carac texts || numérique
            if (caracConfig.DicoCarac.TypeCode === TypeCode.TXT
                || caracConfig.DicoCarac.TypeCode === TypeCode.NUMERIC
                || caracConfig.DicoCarac.TypeCode === TypeCode.TXTLONG
                || caracConfig.DicoCaracExtID === DicoCarac.PRODUCT_LABEL
                || caracConfig.DicoCaracExtID === DicoCarac.REFERENCE_LABEL
                || caracConfig.DicoCaracExtID === DicoCarac.FAMILY_LABEL
                || caracConfig.DicoCarac.TypeCode === TypeCode.DATETYPE) {
                const tmpValue = element.getValueTextValue(caracConfig.DicoCaracExtID);
                return String(tmpValue) !== '';
            } else if (caracConfig.DicoCarac.TypeCode === TypeCode.LIEN || caracConfig.DicoCarac.TypeCode === TypeCode.LIENPPTYPE) {
                const tmpValue = element.getValueLien(caracConfig.DicoCaracExtID);
                return tmpValue && tmpValue.RebuildLinkedElements && tmpValue.RebuildLinkedElements.length > 0;
            }
        } else {
            return true;
        }
    }

    public isFillRateValide(element: NPElement, caracConfig: CaracConfig) {
        if (caracConfig.DicoCarac.FillRates[0].Selected === '1') {
            return null;
        } else {
            return true;
        }
    }

    public isRequiredOrFillRate(caracConfig: CaracConfig) {
        return this.isRequired(caracConfig.DicoCarac) || this.isFillRateSelected(caracConfig);
    }

    public isRequired(dicoCarac: NPDicoCarac) {
        return dicoCarac && dicoCarac.Required === '1';
    }

    public isFillRateSelected(caracConfig: CaracConfig) {
        return caracConfig &&
            caracConfig.DicoCarac &&
            caracConfig.DicoCarac.FillRates.find(currentCC => currentCC && currentCC.Selected === '1');
    }

    public setCaracsByBlock(caracsConfigToCheck: CaracConfig[], pageName: string) {
        if (caracsConfigToCheck) {
            caracsConfigToCheck.forEach(value => { // Liste de blocs
                if (value.ConfigurationObject) {
                    value.ConfigurationObject.forEach(cc => { // On parcourt les caracas de chaque bloc
                        if (cc.BlocInfo !== 'Title' && this.isRequiredOrFillRate(cc) && !this.alreadyExist(value, cc)) {
                            this.carcsByBlock.push({
                                blockName: value.Label, // Le nom du block
                                caracConfig: cc, // La carac
                                pageName: pageName
                            });
                        }
                    });
                }
            });
        }
    }

    alreadyExist(value: CaracConfig, caracConfig: CaracConfig) {
        return this.carcsByBlock.some(existCarac => existCarac.blockName === value.Label &&
            existCarac.caracConfig.DicoCaracExtID === caracConfig.DicoCaracExtID);
    }

    getNoValidCaracs(element: NPElement) {
        const _noValidCaracs: CarcsByBlock[] = [];
        Array.from(element.Values.values()).forEach(value => {
            const tmpCC = this.carcsByBlock.find(cc => cc.caracConfig.DicoCaracExtID === value.DicoCaracExtID);
            if (tmpCC && element.getValue(tmpCC.caracConfig.DicoCaracExtID)) {
                if (!this.isValueValide(element, tmpCC.caracConfig)) {
                    _noValidCaracs.push(tmpCC);
                }
            }
        });
        return _noValidCaracs;
    }

    // Revoie True si le produit en cours contient la caracConfig passée en paramètre
    public exist(element?: NPElement, caracConfig?: CaracConfig) {
        return element &&
            caracConfig &&
            element.getValue(caracConfig.DicoCaracExtID) !== undefined
            && element.getValue(caracConfig.DicoCaracExtID) !== null;
    }

    startChecking() {
        this._startChecking.next(true);
    }

    stopChecking() {
        this._startChecking.next(false);
    }

    isChecking() {
        return this._startChecking.asObservable();
    }
}
