import { Catalog, Chapter } from '@app/models';

export class CatalogBuilder {
    private _id: string;
    private _name: string;
    private _description: string;
    private _chapters: Chapter[];

    withId(value: string): CatalogBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): CatalogBuilder {
        this._name = value;
        return this;
    }

    withDescription(value: string): CatalogBuilder {
        this._description = value;
        return this;
    }

    withChapters(value: Chapter[]): CatalogBuilder {
        this._chapters = value;
        return this;
    }

    build(): Catalog {
        return {
            id: this._id,
            name: this._name,
            description: this._description,
            chapters: this._chapters
        };
    }
}
