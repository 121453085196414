import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {AdvancedSearchDTO} from '@data/types';
import {AdvSearchArgsBuilder} from '@data/builders';
import { AdvSearchArgs } from 'src/lib/data/model';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSearchWriter {

  constructor(private _store: Store) {
  }

  createEmpty(): Observable<AdvSearchArgs> {
    return of(
        new AdvSearchArgsBuilder()
            .withPageSize(10)
            .withCurrentPage(1)
            .withPaths([])
            .withFacets([])
            .withConfig(new AdvancedSearchDTO())
            .build()
    );
  }
}
