<div mat-dialog-title>
    <span class="p-error">{{ 'common.error' | translate }}</span>
</div>
<mat-dialog-content>
    <div class="flex mt-3">
        <span class="material-icons p-error mr-1">remove_circle</span>
        <span class="p-error">{{data?.summary}}.</span>
    </div>
    <div class="mt-3">
        <p>{{data.detail}}</p>
    </div>
    <ng-container *ngIf="data?.iterableData?.length > 0">
        <ul class="mt-3">
            <li *ngFor="let item of data.iterableData" [innerHtml]="item"></li>
        </ul>
    </ng-container>
</mat-dialog-content>
<ng-container *ngIf="data.config.displayCloseButton">
    <mat-dialog-actions [ngClass]="customClass$ | async">
        <button mat-button mat-dialog-close>{{ 'common.close' | translate }}</button>
    </mat-dialog-actions>
</ng-container>
