import {Component, inject} from '@angular/core';
import {NpInstanceService} from '../../../data/services/np-instance.service';

@Component({
  selector: 'app-design',
  template: '',
  standalone: true
})
export class DesignComponent {
  protected readonly customClass$ = inject(NpInstanceService).getStyleCass$();
}
