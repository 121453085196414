<form [formGroup]='formFilter'>
    <ng-container *ngIf="charTemplates">
        <div class="flex-grow-1 list-filters__container" *ngIf="charTemplates.length > 0">
            <mat-form-field appearance='outline' class='filter-form-field'>
                <mat-label>{{ 'products.filter-by' | translate }}</mat-label>
                <mat-select formControlName="formProductType"
                panelClass="filter-form-field__container"
                (selectionChange)='filterByTypeProduct($event)'>
                    <mat-option [value]="'reset'">{{ 'products.all' | translate }}</mat-option>
                    <mat-option *ngFor='let typeProduct of charTemplates' [value]='typeProduct'>
                        {{ typeProduct.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf='filtersCriteriaList && filtersCriteriaList.length > 0'>
                <ng-container *ngFor='let filtersCriteria of filtersCriteriaList'>
                    <mat-form-field appearance='outline' class='filter-form-field'
                                *ngIf="filtersCriteria?.facets?.length > 0">
                        <mat-label>{{filtersCriteria.label}}</mat-label>
                        <mat-select multiple #filtersCriteriaList="matSelect"
                        [formControlName]='filtersCriteria.DicoCaracID'
                        panelClass="filter-form-field__container"
                        (selectionChange)="onChoicesCriteriaValuesChange($event)"
                        (openedChange)="onChoicesCriteriaDropdownOpened($event, filtersCriteria)">
                            <div class="options-list">
                                <mat-option *ngFor='let facet of filtersCriteria.facets'
                                            [value]='facet'>
                                    {{facet.Value}} ({{facet.Count}})
                                </mat-option>
                            </div>
                            <div class="d-flex filter__actions">
                                <button mat-stroked-button
                                    [disabled]="formFilter.controls[filtersCriteria.DicoCaracID].value.length == 0"
                                    (click)="filterByFacet(filtersCriteria)"
                                    class="single-filter-btn">
                                    <mat-icon>filter_list</mat-icon>
                                    {{ 'products.filter' | translate }}
                                </button>
                                <button
                                    [disabled]="formFilter.controls[filtersCriteria.DicoCaracID].value.length == 0"
                                    (click)="clearSingleFilter(filtersCriteria.DicoCaracID)"
                                    mat-stroked-button
                                    class="single-reset-btn">
                                    <mat-icon>clear</mat-icon>
                                    {{ 'products.reset' | translate }}
                                </button>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            
                <button (click)="clearAllFilters()"
                        mat-stroked-button
                        class="filter-btn">
                    <mat-icon>clear</mat-icon>
                    {{ 'products.reset' | translate }}
                </button>
            </ng-container>
        </div>
    </ng-container>
</form>
