import {Component, Input, OnInit} from '@angular/core';
import {ENTITY_NAME_MAP, EntityName} from '../../../../lib/data/model/portal';
import {environment} from '@env';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteResolverService} from '../../../data/services/routes/route-resolver.service';
import {NpInstanceService} from '../../../data/services/np-instance.service';
import {EntityMenuItem} from '../../../data/models/np-entity.models';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Component({
    selector: 'app-sidebar-content',
    templateUrl: './sidebar-content.component.html',
    styleUrls: ['./sidebar-content.component.scss'],
})
export class SidebarContentComponent implements OnInit {
    @Input() isPublicationPortal: boolean = false;

    public entities = Array
        .from(environment.instance.allowedEntities)
        .filter((entityName: EntityName) => {
            return environment.enableMediaLibrary ? true : entityName !== EntityName.MediaLibrary;
        })
        .map((entityName: EntityName) => ENTITY_NAME_MAP.get(entityName));

    public selectedEntity: { name: EntityName, label: string };
    public menuItems: EntityMenuItem[] = [];
    public menuItems$: Observable<EntityMenuItem[]> = of([]);
    public customClass: string;
    private _getSelectedItem$ = new BehaviorSubject<EntityMenuItem>(null);

    constructor(private _router: Router,
                private _route: ActivatedRoute,
                private _routeResolverService: RouteResolverService,
                private _npInstanceService: NpInstanceService,
                private _instanceService: NpInstanceService) {
  }

  ngOnInit(): void {
      this.customClass = this._instanceService.getStyleCass();
    this.menuItems$ = this._getMenuItems();
    this.selectedEntity = ENTITY_NAME_MAP.get(this._npInstanceService.currentEntity.name);
  }

  changeEntity(selectedEntity: { name: EntityName, label: string }) {
    this.selectedEntity = selectedEntity;
    return this._routeResolverService.setEntityAndNavigateTo(selectedEntity.name, RoutePagesName.HOME);
  }

  gotoHomePage() {
    return this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME);
  }

  setActiveItem(menuItem: EntityMenuItem) {
    this._getSelectedItem$.next(menuItem);
  }

  private _getMenuItems() {
    return this._route.data.pipe(
        map(data => data?.entityParameter?.menuItems || []),
        switchMap((menuItems: EntityMenuItem[]) => {
            return this._npInstanceService.getCurrentInstancePage()
                .pipe(
                    map(currentInstancePage => {
                        return this._setSelectedMenuItemByRouteName(menuItems, currentInstancePage?.page);
                    })
                );
        }),
        switchMap((menuItems: EntityMenuItem[]) => {
            return this._getSelectedItem$
                .pipe(
                    map(selectedMenuItem => {
                        return this._setSelectedMenuItemByRouteName(menuItems, selectedMenuItem?.associatedRouteName);
                    })
                );
        }),
    );
  }

    private _setSelectedMenuItemByRouteName(menuItems: EntityMenuItem[], currentRouteName: string): EntityMenuItem[] {
        return currentRouteName ? menuItems.map(menuItem => {
            menuItem.selected = menuItem.associatedRouteName === currentRouteName;
            return menuItem;
        }) : menuItems;
    }
}
