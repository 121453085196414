import {createAction, props} from '@ngrx/store';
import {WorkflowStatusDto} from '../../../lib';

export const FetchWorkflowStatus = createAction('[workflow Status] Fetch Status');

export const FetchWorkflowStatusSuccess = createAction('[workflow Status] Fetch Status Success', props<{
    status: WorkflowStatusDto[]
}>());

export const FetchWorkflowStatusFailure = createAction('[workflow Status] Fetch Status Failure', props<{
    message: string
}>());
