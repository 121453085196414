import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {NpParameterDTO} from '../model/np-paramater';
import {HttpClient} from '@angular/common/http';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NpParameterService {

    private _getByNameUrl = '/api/sdk/Administration/parameters/GetByName';
    private _getByNamesUrl = '/api/sdk/Administration/parameters/GetByNames';
    private _getAllUrl = '/api/sdk/Administration/parameters';

    private _http = inject(HttpClient);

    getByName(parameterName: string): Observable<NpParameterDTO> {
        return !!parameterName ?
            this._http.get<NpParameterDTO>(`${this._getByNameUrl}/${parameterName}`)
                .pipe(shareReplay(1)) :
            throwError('Parameter name incorrect or null');
    }

    getByNames(parameterNames: string[]): Observable<NpParameterDTO[]> {
        return !!parameterNames && parameterNames.length > 0 ?
            this._http.post<NpParameterDTO[]>(this._getByNamesUrl, {names: parameterNames})
                .pipe(shareReplay(1)) :
            throwError('Parameter names incorrect or null');
    }

    getAllParameters(): Observable<NpParameterDTO[]> {
        return this._http.get<NpParameterDTO[]>(this._getAllUrl)
            .pipe(shareReplay(1));
    }
}
