export enum RoutePagesName {
    HOME = 'home',
    PRODUCTS = 'products',
    MEDIAS = 'medias',
    FAVORITE = 'favorite',
    IMPORT = 'import',
    ACCOUNT = 'account',
    CATALOGUE = 'catalogue',
    LOGIN = 'login',
}

export enum RouteParamName {
    INSTANCE_ID = 'instanceId'
}
