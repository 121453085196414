import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TreeNodeCatalogDto, CatalogPortal, CatalogConfigPortal, CatalogCreation } from '@lib/models';
import { map } from 'rxjs/operators';
import { NpDownloadService } from './np-download.service';
import { TreeNodeCatalog, CatalogConfig } from '@app/models';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class NpCatalogService {

  private readonly _catalogTreeUrl = '/api/sdk/Portal/Catalog/GetTree/';
  private readonly _createCatalogUrl = '/api/sdk/Portal/Catalog/Create/';
  private readonly _catalogZipFileUrl = '/api/sdk/Portal/Catalog/GetZipFile/';
  private readonly _catalogPdfFileUrl = '/api/sdk/Portal/Catalog/GetPdf/';
  private readonly _duplicateCatalogUrl = '/api/sdk/Portal/Catalog/Duplicate/';
  private readonly _deleteCatalogTreeUrl = '/api/sdk/Portal/Catalog/Delete/';
  private readonly _getCatalogUrl = '/api/sdk/Portal/Catalog/Get/';
  private readonly _editCatalogUrl = '/api/sdk/Portal/Catalog/Edit/';
  private readonly _generateCatalogUrl = '/api/sdk/Portal/Catalog/Generate/';
  private readonly _saveCatalogUrl = '/api/sdk/Portal/Catalog/Save/';

  constructor(
    private _http: HttpClient,
    private _downloadService: NpDownloadService) {
  }

  public createCatalog = (catalogInfo: CatalogCreation): Observable<CatalogConfigPortal> => {
    return this._http.post<CatalogConfigPortal>(this._createCatalogUrl, {
      Name: catalogInfo.name,
      Description: catalogInfo.description,
      SelectedChapters: catalogInfo.selectedChapters
    });
  };

  public editCatalog = (catalogConfig: CatalogConfig, structure: CatalogPortal): Observable<CatalogConfigPortal> => {
    return this._http.post<CatalogConfigPortal>(this._editCatalogUrl, {
      CatalogId: catalogConfig.catalogId,
      Structure: structure,
      SelectedChapters: catalogConfig.selectedChapters
    });
  };

  public getCatalogTreeByParentId = (parentId: number): Observable<TreeNodeCatalogDto[]> =>
    this._http.get<TreeNodeCatalogDto[]>(`${this._catalogTreeUrl}/1/${parentId}`)

  public getCatalogById = (id: number): Observable<CatalogPortal> =>
    this._http.get<CatalogPortal>(`${this._getCatalogUrl}/${id}`)

  public getCatalogPdfFile = (catalog: TreeNodeCatalog): Observable<void> =>
    this._http.get(`${this._catalogPdfFileUrl}/${catalog.id}`, { responseType: 'blob' })
      .pipe(
        map(blob => {
          saveAs(blob, catalog.pdfFileName);
        })
      )

  public getCatalogZipFile = (catalog: TreeNodeCatalog): Observable<void> =>
    this._http.get(`${this._catalogZipFileUrl}/${catalog.id}`, { responseType: 'blob' })
      .pipe(
        map(blob => {
          saveAs(blob, catalog.zipFileName);
        })
      )

  public duplicateCatalog = (id: number): Observable<TreeNodeCatalogDto> =>
    this._http.post<TreeNodeCatalogDto>(`${this._duplicateCatalogUrl}`, { CatalogId: id })
      .pipe(
        map(treeNodeCatalog => {
          if (!treeNodeCatalog) {
            throwError('Duplication failed');
          }

          return treeNodeCatalog;
        })
      )

  public deleteCatalogTree = (catalogId: number): Observable<boolean> =>
    this._http.delete<boolean>(`${this._deleteCatalogTreeUrl}/${catalogId}`)
      .pipe(
        map(isDeleted => {
          if (!isDeleted) {
            throwError('Deletion failed');
          }
          return isDeleted;
        })
      )

  public generateCatalog = (catalogConfig: CatalogConfig, structure: CatalogPortal): Observable<void> => {
    return this._http.post<void>(this._generateCatalogUrl, {
      CatalogId: catalogConfig.catalogId,
      Structure: structure,
      SelectedChapters: catalogConfig.selectedChapters
    });
  };

  public saveCatalog = (catalogConfig: CatalogConfig, structure: CatalogPortal): Observable<void> => {
    return this._http.post<void>(this._saveCatalogUrl, {
      CatalogId: catalogConfig.catalogId,
      Structure: structure,
      SelectedChapters: catalogConfig.selectedChapters
    });
  };
}
