import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NpUserInfoService} from '../../../lib';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {FetchAdvancedSearch, FetchUserInfo, FetchUserInfoFailure, FetchUserInfoSuccess} from '../actions';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {FetchAllCharacteristics} from '@store/characteristics';

@Injectable()
export class UserInfoEffect {
    constructor(private actions$: Actions,
                private _userInfoService: NpUserInfoService,
                private _store: Store) {
    }

    userInfo = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchUserInfo),
            mergeMap(() => {
                    return this._userInfoService.getCurrentUser()
                        .pipe(
                            map((users) => {
                                    const nextState = FetchUserInfoSuccess(
                                        {userInfo: users?.length > 0 ? users[0] : null}
                                    );
                                    // TODO: Mettre une condition en fonction de la config du client (Avec ou sans médiathèque
                                    this._store.dispatch(FetchAdvancedSearch());
                                    this._store.dispatch(FetchAllCharacteristics());
                                    return nextState;
                                }
                            ),
                            catchError(error => of(FetchUserInfoFailure({message: error?.toString()})))
                        );
                }
            )
        );
    });
}
