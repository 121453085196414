<div [ngClass]="customClass">
    <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0 page-title">{{ 'common.home' | translate }}</h3>
        <div class="search-container">
            <mat-form-field appearance="outline" class="field-search-custom">
                <mat-label>{{ 'common.search' | translate }}</mat-label>
                <input type="text" matInput
                       [formControl]="searchFormControl"
                       (keyup.enter)="searchByKeyword($event.target.value)"
                       #searchFilter/>
                <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search"/>
            </mat-form-field>
            <img src="./assets/icons/x-mark.svg"
                 class="fontawesome-icon-custom ml-2 cursor-pointer"
                 alt="Reset"
                 *ngIf="searchFilter.value !== ''"
                 (click)="resetSearch($event)"/>
        </div>
    </div>
    <mat-divider class="my-3"></mat-divider>

    <ng-container *ngIf="dashboardQueriesView$ | async as dashboardQueriesView">
        <app-loader-spinner *ngIf="dashboardQueriesLoaderView$ | async"></app-loader-spinner>

        <div *ngIf="((dashboardQueriesView?.advancedSearches?.length === 0 && !dashboardQueriesView.loading) || hasKeyword); else defaultDashboard">
            <div *ngIf="dataView$ | async as cardsView">
                <app-loader-spinner *ngIf="cardsView.loading; else dataBlock"></app-loader-spinner>
                <ng-template #dataBlock>
                    <app-media-card
                            [filteredCard]="cardsView.productWithTotalRow?.productSummaries || []"
                            [loading]="cardsView.loading"
                    >
                    </app-media-card>
                </ng-template>
            </div>
        </div>

        <ng-template #defaultDashboard>
                <div *ngFor="let dashboardQuery of dashboardQueriesView?.advancedSearches">
                    <app-home-page-block
                            [advancedSearch]="dashboardQuery"
                            [customClass]="customClass">
                    </app-home-page-block>
                </div>
        </ng-template>

    </ng-container>

    <app-home-page-files-view [customClass]="customClass"></app-home-page-files-view>
</div>
