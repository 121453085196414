import {Component, inject, OnDestroy} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DataSummary} from '@app/models';
import {UploadFilesService} from 'src/app/data/services/upload-files.service';
import {FileExtension} from 'src/app/data/helpers/file-helper';
import {NpImportServiceService} from '@nextpage/np-sdk-data';
import {SpUserInfoService} from '@data/services';
import {switchMap} from 'rxjs/operators';
import {Subscription, throwError} from 'rxjs';
import {RouteResolverService} from 'src/app/data/services/routes/route-resolver.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Component({
    selector: 'app-import-page',
    templateUrl: './import-page.component.html',
    styleUrls: ['./import-page.component.scss'],
})
export class ImportPageComponent implements OnDestroy {
    public dataSummary: MatTableDataSource<DataSummary>;
    private _subscription: Subscription;

    public dropZoneHovered = false;
    public fileName: string;
    public selectedFile: File;
    public fileError = false;
    public isLoading: boolean;

    private _userInfoService = inject(SpUserInfoService);

    constructor(private _uploadFilesService: UploadFilesService,
                private _snackBar: MatSnackBar,
                private _routeResolverService: RouteResolverService,
                protected _importService: NpImportServiceService) {
    }

    public onFileChange(event) {
        this.handleDrop(event.target.files[0]);
    }

    handleHover($event: boolean) {
        this.dropZoneHovered = $event;
    }

    handleDrop(file: File) {
        if (FileExtension.isImage(file)) {
            this.fileError = false;
            this.selectedFile = file;
            this.fileName = this.selectedFile.name;
            this._uploadFilesService
                .getDataFromFile(this.selectedFile)
                .subscribe(response => {
                    this.dataSummary = response;
                });
        } else {
            this.fileError = true;
            this.resetData();
        }
    }

    resetData() {
        this.selectedFile = null;
        this.fileName = null;
    }

    executeDataImport() {
        this.isLoading = true;
        this._subscription = this._userInfoService.getImportProfileExtId()
            .pipe(
                switchMap(profileExtId => {
                    return profileExtId ?
                        this._importService.importData(this.selectedFile, profileExtId) :
                        throwError('Profil extId no found.');
                })
            )
            .subscribe(result => {
                if (result) {
                    this.isLoading = false;
                    this._snackBar.open('Produits importés avec succès');
                    return this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.PRODUCTS);
                }
            });
    }
    private _unsubscribe() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
    ngOnDestroy() {
        this._unsubscribe();
    }
}
