import {Access, CaracConfig, DicoCarac, NPDicoCarac, NPElement, NPElementType, TypeCode} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {
    COMPONENT_NAME_BY_TYPE,
    DicoCaracDestination,
    LINK_COMPONENT_NAME_BY_CARDINALITY,
    LINK_COMPONENT_NAME_BY_DESTINATION
} from '../constants/sp-component-name';

export class NpElementHelper {

  private static _links: string[][] = [[]];

  public static getValue(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
    const tmpCaraValue = element.getValue(caracConfig.DicoCaracExtID);
    if (tmpCaraValue) {
      return {
        value: element.getValue(caracConfig.DicoCaracExtID),
        config: caracConfig,
        element: element,
        baseElement: element
      };
    }
    return null;
  }

  public static getElementName(element: NPElement) {
    return element.ElementType === NPElementType.Family ?
        element.getValueTextValue(DicoCarac.FAMILY_LABEL) :
        element.ElementType === NPElementType.Product ? element.getValueTextValue(DicoCarac.PRODUCT_LABEL) :
            element.getValueTextValue(DicoCarac.REFERENCE_LABEL);
  }

  public static getParentName(element: NPElement) {
    return element.Parent ? NpElementHelper.getElementName(element.Parent) : 'Inconnu';
  }

  public static getPathName(element: NPElement) {
    if (element) {
      const _parents: string[] = [];
      let _parent = element.Parent;
      do {
        _parents.push(NpElementHelper.getElementName(_parent));
        _parent = _parent.Parent ? _parent.Parent : undefined;
      } while (_parent !== undefined);
      return _parents.reduceRight((accumulator, currentValue) => accumulator + ' ' + '\\' + ' ' + currentValue);
    }
    return '';
  }

  static rebuildConfigs(caracs: NPDicoCarac[]): {data: CaracConfig[], links: string[][]} {
    const tmpData = caracs.map(cc => {
      const newConfig = new CaracConfig();
      newConfig.DicoCarac = cc;
      newConfig.DicoCaracExtID = cc.ExtID;
      newConfig.ShowCondition = 'Always';
      newConfig.ForcedState = newConfig.DicoCarac.Editable === '1' ? Access.GESTIONNAIRE : Access.LECTURESEULE;
      newConfig.PrintLabel = false;
      newConfig.DicoCaracExtIDLabel = cc.ExtID;
      newConfig.Tooltip = 'Unknown characteristic';
      newConfig.Label = cc.Name;
      newConfig.Tooltip = cc.Description;
      this.writeComponent(newConfig);
      return newConfig;
    });
    return {data: tmpData, links: this._links};
  }

  private static writeComponent(newConfig: CaracConfig) {
    let tmpComponentName = '';
    const dicoCaracType = newConfig && newConfig.DicoCarac && newConfig.DicoCarac.TypeCode ? newConfig.DicoCarac.TypeCode.toString() : null;
    if (dicoCaracType && dicoCaracType.trim() !== '') {
      if (newConfig.DicoCarac.TypeCode.toString().indexOf(TypeCode.LIEN) !== -1) { // Tous les types LIENS
        tmpComponentName = LINK_COMPONENT_NAME_BY_DESTINATION[newConfig.DicoCarac.Destination]
            || LINK_COMPONENT_NAME_BY_CARDINALITY[newConfig.DicoCarac.Cardinality]
            || COMPONENT_NAME_BY_TYPE[TypeCode.LIEN];
        this._setLinks(newConfig.DicoCarac);
      } else {
        tmpComponentName = COMPONENT_NAME_BY_TYPE[dicoCaracType];
      }
    }
    // Ecriture des composants spécifiques
    this.writeSpecificComponent(newConfig);
    newConfig.Component = tmpComponentName;
  }

  /**
   * Ecriture des composants spécifiques
   * param newConfig
   */
  private static writeSpecificComponent(newConfig: CaracConfig) {
    if (newConfig && newConfig.DicoCarac) {
      if (newConfig.DicoCarac.TypeCode === TypeCode.CHOIX_NP) {
        newConfig.Specific = {advancedMode: true, Component: 'MultipleChoiceComponent', useNewVersion: true}; // Permet d'afficher le mode avancé (avec popup)
      }
      if (newConfig.DicoCarac.TypeCode === TypeCode.DATETYPE) {
        newConfig.Specific = {hideDatePickerToggle: true};
      }
      // Composant spécifique au projet NP, donc il n'appartient pas au sdk-graphics
      if (newConfig.DicoCarac.TypeCode
          && newConfig.DicoCarac.TypeCode.toString().indexOf(TypeCode.LIEN) !== -1
          && newConfig.DicoCarac.Destination
          && newConfig.DicoCarac.Destination.indexOf(DicoCaracDestination.MEDIA) === -1
          && newConfig.DicoCarac
          && newConfig.DicoCarac.Cardinality !== 1) {
        newConfig.Specific = {Component: 'LinkCaracWriterV2Component', useNewVersion: true};
      }
    }

  }

  /*    static filteredCaracs(_copyOfFolder: Map<string, CaracTree>, valueToResearch: any) {
          const tmpMap = new Map<string, CaracTree>();
          _copyOfFolder.forEach((value, key) => {
              const tmpData = this._filterCaracValues(value.caracObjects, valueToResearch);
              if (this.familyNameContains(key, valueToResearch)) {
                  tmpMap.set(key, Object.assign({}, value));
              }
              if (tmpData && tmpData.length > 0) {
                  if (!tmpMap.has(key)) {
                      tmpMap.set(key, new CaracTree());
                  }
                  tmpMap.get(key).caracObjects = tmpData;
              }
          });
          return tmpMap;
      }

      private static _filterCaracValues(caracObjects: CaracObject[], valueToResearch: string) {
          valueToResearch = valueToResearch ? valueToResearch : '';
          return caracObjects ? caracObjects.filter(cc => cc.carac.Label && cc.carac.Label.toUpperCase().indexOf(valueToResearch.toUpperCase()) !== -1) : [];
      }*/

  private static _setLinks(dicoCarac: NPDicoCarac) {
    if (!this._links.some(links => links.some(link => link === dicoCarac.ExtID))) {
      this._links.push([dicoCarac.ExtID]);
    }
  }

  public static sortTextValues(v1: string, v2: string, desc = false) {
    v1 = v1 ? v1 : '';
    v2 = v2 ? v2 : '';
    return !desc ? v1.trim().localeCompare(v2.trim()) : v2.trim().localeCompare(v1.trim());
  }

  /*   public static sortNumericValues(v1: any, v2: any, desc = false) {
         return !desc ? v1 - v2 : v2 - v1;
     }

     static indeterminate(treeSummary: TreeSummary) {
         return treeSummary.data.some(_child => _child.checked) && treeSummary.data.some(_child => !_child.checked);
     }*/

  /*    static parentChecked(treeSummary: TreeSummary) {
          return treeSummary.checked ? treeSummary.checked : treeSummary.data.every(_child => _child.checked);
      }

      static sortTreeByCaracExtId(caracExtId: string, _mapTrees: MapTree[]) {
          _mapTrees.forEach(values => {
              values.value.data = values.value.data.sort((preview, current) => {
                  const previewValue = preview.element.getValueTextValue(caracExtId) || '';
                  const currentValue = current.element.getValueTextValue(caracExtId) || '';
                  return this.sortTextValues(previewValue, currentValue);
              });
          });
          return _mapTrees;
      }

      static sortDescTreeByCaracExtId(_mapTrees: MapTree[]) {
          _mapTrees.map(values => {
              values.value.data.sort().reverse();
              return values;
          });
          return _mapTrees;
      }

      static elementMapToArray(elements: Map<string, NPElement>) {
          return elements ? Array.from(elements.values()) : [];
      }*/
}
