import {Access, NPElement, TypeCode} from '@nextpage/np-sdk-data';
import {Field} from '@data/types';
import {WorkflowStatusDto} from '@nextpage/np-sdk-data';

export interface Message {
    cssClass?: string;
    type?: string;
    text?: string;
}

export interface SpRouteData {
    title: string;
    pageTitle: string;
    displayFullScreen: boolean;
}

export class ElementSummary {
    select = '';
    overview = '';
    label = '';
    modificationDate = '';
    productLength?: '';
    productPrice?: '';
    productWeight?: '';
    imageUrl?: string;
    productColor?: string[];
    actions = '';
    element: NPElement;
    lastModificationUserName: string;
    isFavoriteElement?: boolean;
    urlWithoutToken: string;
}

export class ProductSummary {
    public fields: Field[];
    public label: string;
    public modificationDate: string | Date;
    public element: NPElement;
    public imageUrl: string;
    public imageName?: string;
    public action: string;
    public urlWithoutToken: string;
    public status: Partial<WorkflowStatusDto> = null;
    public isFavoriteElement = false;
    public selected?: boolean;
    public downloadUrl?: {label: string, url: string}[];
    public lastModificationUserName: string;
    public extID?: string;
    public mediaSizeInMo?: string;

    constructor(param?: Partial<ProductSummary>) {
        this.element = param?.element;
        this.fields = param?.fields || [];
        this.label = param?.label;
        this.modificationDate = param?.modificationDate;
        this.imageUrl = param?.imageUrl;
        this.action = param?.action;
        this.urlWithoutToken = param?.urlWithoutToken;
        this.isFavoriteElement = param?.isFavoriteElement;
        this.selected = param?.selected;
        this.downloadUrl = param?.downloadUrl || [];
        this.lastModificationUserName = param?.lastModificationUserName;
        this.status = param?.status || param?.element?.getValueStatus()?.wfStatus;
        this.extID = param?.extID;
        this.imageName = param?.imageName;
        this.mediaSizeInMo = param?.mediaSizeInMo;
    }
}

export class MediaSummary {
    public label: string;
    public element: NPElement;
    public modificationDate: string | Date;
    public downloadUrl?: {label: string, url: string}[];
    public imageUrl = '';
    public action = '';
}

export class Characteristic {
    constructor(private _label: string, public ExtID: string, public _typeCode: TypeCode) {
    }

    get label(): string {
        return this._label;
    }
    get typeCode(): TypeCode {
        return this._typeCode;
    }
}

export class DataSummary {
    headers: string[] = [];
    headers2: string[] = [];
    data: unknown[] = [];
    count: number;
    AllData: unknown[] = [];
}

export interface SystemLastModificationDate {
    Access: Access;
    ContextID: number;
    DicoCaracExtID: string;
    DicoCaracID: number;
    Element?: NPElement;
    ElementExtID: string;
    ElementID: number;
    LangID: number;
    LastModifiedDate: string;
    ModifiedByUserID: number;
    ModifiedByUserName: string;
    TypeValue: number;
}
