
<div *ngFor="let bInfo of allBlocInfo">
    <div class="element-view" *ngFor="let blockInfo of caracWithStyles.get(bInfo)" [ngStyle]="blockInfo.style" [class.one-column]="blockInfo.style">
        <div class="maped-element" *ngFor="let cValue of blockInfo.caracs">
            <div *ngIf="cValue.config.Specific !== undefined  && cValue.config.Specific['html_tag']; else noSpe">
                <div *libReplaceTag="cValue.config.Specific['html_tag']">
                    <lib-app-value-view [caracIdStateValue]="cValue" [element]="element"></lib-app-value-view>
                </div>
            </div>
            <ng-template #noSpe>
                <lib-app-value-view [caracIdStateValue]="cValue" [element]="element"></lib-app-value-view>
            </ng-template>
        </div>
    </div>
</div>
