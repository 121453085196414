<div class="user-card">
  <h1 class="mt-2 mb-2 text-center">{{ 'account.title' | translate }}</h1>
  <h4 class="mb-6 text-center">{{ 'account.subtitle' | translate }}</h4>
  <div class="d-flex align-items-center mb-5 p-2">
    <label class="mr-3">{{ 'common.fields.select-language' | translate }} :</label>
    <div *ngIf="currentUiLanguageView$ | async as selectedLang">
      <mat-button-toggle-group [value]="selectedLang">
        <ng-container *ngFor="let language of languages">
          <mat-button-toggle value="{{language.code}}" (click)="changeSiteLanguage(language.code)">
            {{language.label}}
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
  </div>
  
  <div *ngIf="currentUserView$ | async as currentUser">
    <div class="text-center card-content">
      <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>{{ 'common.fields.lastname' | translate }}</mat-label>
        <input disabled matInput value="{{ currentUser.LastName }}" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>{{ 'common.fields.firstname' | translate }}</mat-label>
        <input disabled matInput value="{{ currentUser.FirstName }}" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field disabled appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>{{ 'common.fields.email' | translate }}</mat-label>
        <input disabled type="email" matInput value="{{ currentUser.Email }}" />
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>{{ 'common.fields.password' | translate }}</mat-label>
        <input type="password" matInput value="" />
        <mat-icon matSuffix>password</mat-icon>
      </mat-form-field> -->
      <!-- <div>
        <button mat-raised-button color="primary">{{ 'common.save' | translate }}</button>
      </div> -->
    </div>
  </div>
</div>
