import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { shareReplay } from "rxjs/operators";
import { ParamsFilterBuilder } from "src/app/data/builders";
import { PUBLICATION_DEFAULT_PAGE_INDEX, PUBLICATION_DEFAULT_PAGE_SIZE } from "src/app/data/constants/products-page-size.constants";
import { FetchProductsElements, FetchProductsElementsLoader, selectProductsElements } from "@store/products";
import { environment } from "src/environments/environment";
import { ProductsSelectionComponent } from "./elements/products-selection/products-selection.component";
import { Chapter, CatalogPage } from "@app/models";

@Component({
    selector: 'app-dialog-products-selection',
    templateUrl: './dialog-products-selection.component.html',
    styleUrls: ['./dialog-products-selection.component.scss']
})
export class DialogProductsSelectionComponent implements OnInit {
    @ViewChild(ProductsSelectionComponent) productsSelectionComponent: ProductsSelectionComponent;

    public dataView$ = this._store.select(selectProductsElements)
        .pipe(shareReplay(1));

    public pageSize = PUBLICATION_DEFAULT_PAGE_SIZE;
    public paramFilterBuilder: ParamsFilterBuilder;

    private readonly _elementType = environment.instance.elementType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { chapter: Chapter, descriptionField: string, page: CatalogPage },
        private _store: Store
    ) { }

    ngOnInit(): void {
        this.paramFilterBuilder = new ParamsFilterBuilder()
            .withNumberOfElementByPage(this.pageSize)
            .withPage(PUBLICATION_DEFAULT_PAGE_INDEX)
            .withChannelScope(this.data?.chapter?.channelCategoryID)
            .withElementTypes([this._elementType])
            .withFieldsToDisplay([this.data?.descriptionField]);

        this.searchProducts();
    }

    public goToFirstPage(): void {
        this.productsSelectionComponent.goToFirstPage();
    }

    public searchProducts(): void {
        this._store.dispatch(FetchProductsElementsLoader());
        this._store.dispatch(FetchProductsElements({ paramsFilter: this.paramFilterBuilder.build() }));
    }
}