<ng-container *ngIf="isCardinalityOne; else multiCardinalitiesBlock">
    <lib-select-list-value-lien
            [value]="value" [caracInfo]="caracInfo"
            [caracConfig]="caracConfig"
            [baseElement]="baseElement">
    </lib-select-list-value-lien>
</ng-container>

<ng-template #multiCardinalitiesBlock>
    <lib-link-carac-write
            [value]="value" [caracInfo]="caracInfo"
            [caracConfig]="caracConfig">
    </lib-link-carac-write>
</ng-template>
