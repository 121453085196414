<p-overlayPanel #op styleClass="edit-cover__overlay" (onShow)="getCoverTemplates()">
    <ng-template pTemplate="content">
        <mat-card class="edit-cover__container">
            <mat-card-header class="align-items-center justify-content-between edit-cover__header">
                <mat-card-title class="edit-catalogue__title">{{ 'publication.catalogue.cover' | translate }}</mat-card-title>
                <fa-icon [icon]="['far', 'xmark']" size="lg" (click)="closeOverlay()"></fa-icon>
            </mat-card-header>

            <mat-card-content class="edit-cover__content">
                <form class="d-flex flex-column" [formGroup]='catalogCoverForm' (ngSubmit)='editCover()'>
                    <mat-form-field class="mb-2" appearance='outline'>
                      <mat-label>{{ 'common.fields.catalogue-title' | translate }}</mat-label>
                      <input matInput [maxlength]="coverTitleMaxLength" type='text' formControlName='catalogTitle' (input)="onTextChange($event.target.value)" />
                      <mat-hint>{{ 'common.fields.default-title' | translate }} 
                        <span [ngClass]="{'limit-reached': coverTitleLength === coverTitleMaxLength}">{{coverTitleLength}} / {{coverTitleMaxLength}}</span>
                    </mat-hint>
                    </mat-form-field>

                    <p class="text-center template-title">{{ 'publication.catalogue.choose-page-template' | translate }}</p>

                    <ng-container *ngIf="(coverTemplatesView$ | async) as coverTemplatesView">
                        <mat-radio-group *ngIf="coverTemplatesView?.hasTemplates; else noResult"
                        formControlName="catalogCoverTemplate" 
                        required>
                            <mat-radio-button *ngFor="let coverTemplate of coverTemplatesView?.publicationTemplates; let index = index"
                            class="mat-radio-container-no-show" 
                            [value]="coverTemplate?.id">
                                <img *ngIf="coverTemplate?.jpgFile" 
                                [src]="coverTemplate?.jpgFile" 
                                [alt]="coverTemplate?.name"
                                (error)="displayDefaultImage(coverTemplate)">
                                <span class="cover-label">{{ coverTemplate?.name || coverTemplate?.inddFile }}</span>
                            </mat-radio-button>
                        </mat-radio-group>

                        <ng-template #noResult>
                            <div class="text-center" *ngIf="!coverTemplatesView?.loading">{{'publication.catalogue.no-template-found' | translate}}</div>
                          </ng-template>
                      
                        <app-loader-spinner *ngIf='coverTemplatesView?.loading' [diameter]="50"></app-loader-spinner>
                    </ng-container>
          
                  </form>
            </mat-card-content>

            <mat-card-actions class="justify-content-end edit-cover__actions">
                <button mat-stroked-button class="cancel-btn" (click)="closeOverlay()">{{ 'common.cancel' | translate }}</button>
                <button mat-flat-button 
                color='primary' 
                class="validate-btn"
                type='submit'
                [disabled]='catalogCoverForm.invalid || (loading$ | async)'>
                <span *ngIf='(loading$ | async)'
                class='spinner-border spinner-border-sm mr-1'></span>
                {{ 'common.validate' | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</p-overlayPanel>
