import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NpMedia} from '../../services/media-library-services/np-medias-tree.service';
import {NPElement} from '@nextpage/np-sdk-data';

@Component({
  selector: 'lib-np-media-preview',
  templateUrl: './np-media-preview.component.html',
  styleUrls: ['./np-media-preview.component.scss']
})
export class NpMediaPreviewComponent implements OnInit {

  @Input() mediaObject: NpMedia;
  @Output() selected = new EventEmitter<NPElement>();

  constructor() {
  }

  ngOnInit() {
  }

  onSelectMedia() {
    this.selected.emit(this.mediaObject.media);
  }
}
