import {createReducer, on} from '@ngrx/store';
import {CatalogTreeUpdate, DeleteCatalogTreeSuccess, FetchCatalogTree, FetchCatalogTreeFailure, FetchCatalogTreeSuccess, GetCatalogPdf, GetCatalogSources, GetCatalogFileSuccess, GetCatalogFileFailure, DuplicateCatalogTree, CatalogTreeUpdateFailure, DeleteCatalogTree} from '@store/publication';
import { TreeNodeCatalog } from '@app/models';
import * as _ from 'lodash';

export const catalogTreeFeatureKey = 'catalog-tree';

export interface CatalogTreeState {
    catalogTree: TreeNodeCatalog[];
    hasCatalogs: boolean;
    errorMessage: any;
    loading: boolean;
    hasError: boolean;
}

export const initialStateCatalogTree: CatalogTreeState = {
    catalogTree: null,
    hasCatalogs: false,
    errorMessage: null,
    loading: false,
    hasError: false
};

export const catalogTreeReducer = createReducer(
    initialStateCatalogTree,
    on(
        FetchCatalogTree,
        (state, ): CatalogTreeState => ({
            ...state,
            catalogTree: null,
            hasCatalogs: false,
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        FetchCatalogTreeSuccess,
        (state, {catalogTree}): CatalogTreeState => ({
            ...state,
            catalogTree,
            hasCatalogs: !_.isEmpty(catalogTree),
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        FetchCatalogTreeFailure,
        (state, {message}): CatalogTreeState => {
            return ({
                ...state,
                catalogTree: null,
                hasCatalogs: false,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
    on(
        CatalogTreeUpdate,
        (state, {catalogTree}): CatalogTreeState => {
            const newCatalogTree = [...state.catalogTree, catalogTree];
            return ({
                ...state,
                catalogTree: newCatalogTree,
                hasCatalogs: !_.isEmpty(newCatalogTree),
                loading: false,
                hasError: false,
                errorMessage: null
            })
        }
    ),
    on(
        DeleteCatalogTree,
        (state, ): CatalogTreeState => ({
            ...state,
            loading: true,
        }) 
    ),
    on(
        DeleteCatalogTreeSuccess,
        (state, {catalogTree}): CatalogTreeState => {
            const newCatalogTree = _.filter(state.catalogTree, (catalog) => !_.isEqual(catalog, catalogTree));
            return ({
                ...state,
                catalogTree: newCatalogTree,
                hasCatalogs: !_.isEmpty(newCatalogTree),
                loading: false,
                hasError: false,
                errorMessage: null
            })
        }
    ),
    on(
        DuplicateCatalogTree,
        (state, ): CatalogTreeState => ({
            ...state,
            loading: true,
        }) 
    ),
    on(
        CatalogTreeUpdateFailure,
        (state, {message}): CatalogTreeState => {
            return ({
                ...state,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
    on(
        GetCatalogPdf,
        (state, ): CatalogTreeState => ({
            ...state,
            loading: true,
        }) 
    ),
    on(
        GetCatalogSources,
        (state, ): CatalogTreeState => ({
            ...state,
            loading: true,
        }) 
    ),
    on(
        GetCatalogFileSuccess,
        (state, ): CatalogTreeState => ({
            ...state,
            loading: false
        }) 
    ),
    on(
        GetCatalogFileFailure,
        (state, {message}): CatalogTreeState => {
            return ({
                ...state,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
);
