import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { OverlayPanel } from "primeng/overlaypanel";
import { Subscription } from "rxjs";
import { SpDicoCarac } from "src/app/data/constants";
import { FetchPublicationTemplates, FetchPublicationTemplatesSuccess, PublicationTemplateState, selectPublicationTemplateView } from "@store/publication";
import { selectLoadingAuthentication } from "src/app/store/selector";
import { CatalogPage, PublicationTemplate } from "@app/models";
import { PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH } from "@data/constants";
import { NpInstanceService } from "src/app/data/services/np-instance.service";

@Component({
    selector: 'app-catalogue-cover-edition-overlay',
    templateUrl: './catalogue-cover-edition-overlay.component.html',
    styleUrls: ['./catalogue-cover-edition-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueCoverEditionOverlayComponent implements OnDestroy {
    @Input() page: CatalogPage;

    @ViewChild('op') overlayPanel: OverlayPanel;

    public loading$ = this._store.select(selectLoadingAuthentication); //TODO new store?
    public coverTemplatesView$ = this._store.select(selectPublicationTemplateView);
    public catalogCoverForm: FormGroup;

    public coverTitleLength = 0;
    public coverTitleMaxLength: number;

    private _imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
    private _coverTemplatesViewSub: Subscription;

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _instanceService: NpInstanceService) {
        this.catalogCoverForm = this._fb.group({
            catalogTitle: [''],
            catalogCoverTemplate: ['', Validators.required]
        });
    }

    public getCoverTemplates(): void {
        this._coverTemplatesViewSub = this._store.select(selectPublicationTemplateView)
        .subscribe(templatesView => {
            if (templatesView?.hasTemplates) {
                this.setDefaultCoverTemplate(templatesView);
            }
        });

        this._store.dispatch(FetchPublicationTemplates({ templateCharacteristicExtID: SpDicoCarac.CP_EXTID_MODELE_PAGE_COUVERTURE }));
    }

    public displayDefaultImage(coverTemplate: PublicationTemplate): void {
        coverTemplate.jpgFile = this._imageTemplateByDefault;
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
        this.coverTitleMaxLength = this.page?.template?.maxCharactersLength || PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH;
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
        this._store.dispatch(FetchPublicationTemplatesSuccess({ publicationTemplates: null }));
        this._coverTemplatesViewSub?.unsubscribe();
    }

    public editCover(): void {
        //TODO
    }

    public onTextChange(value: string): void {
        this.coverTitleLength = value.length;
    }

    private setDefaultCoverTemplate(templatesView: PublicationTemplateState): void {
        const firstCoverTemplate = templatesView?.publicationTemplates[0];
        if (firstCoverTemplate) {
            this.catalogCoverForm.patchValue({ catalogCoverTemplate: firstCoverTemplate.id });
        }
    }

    ngOnDestroy(): void {
        this._coverTemplatesViewSub?.unsubscribe();
    }

}
