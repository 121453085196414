import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LanguageHelper} from '../helpers/language.helper';
import {NpLanguageDto} from '../model/np-language-dto';

@Injectable({
  providedIn: 'root'
})
export class NpLanguageService {
  private urlGetAll = '/api/sdk/language/GetAll';
  private urlGetWithWritePermissions = '/api/sdk/language/GetWithWritePermissions';

  constructor(
      private http: HttpClient
  ) {
  }

  getAll(): Observable<NpLanguageDto[]> {
    return this.http.get<NpLanguageDto[]>(this.urlGetAll);
  }

  getWithWritePermissions(): Observable<NpLanguageDto[]> {
    return this.http.get<NpLanguageDto[]>(this.urlGetWithWritePermissions);
  }

  getAllLabels(): Observable<string[]> {
    return this.getAll().pipe(map(x => LanguageHelper.getLabels(x)));
  }
}
