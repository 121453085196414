<!--Affichage du contenu HTML non éditable-->
<ng-container *ngIf="showHtmlArea(); else textBlock">
    <div class="html-container" *ngIf="!showTextArea()"
         [class.red-border]="displayRequiedError"
         (blur)="onBlurCheck()"
         [innerHTML]="stdValue">
    </div>
</ng-container>

<ng-template #textBlock>
    <div class="input">
        <div class="input-block">

            <input *ngIf="!showTextArea()" type="text" (ngModelChange)="onChange($event)" name="value"
                   [(ngModel)]="stdValue" [class.red-border]="displayRequiedError" (blur)="onBlurCheck()"
                   (keyup)="checkMaxLength()" maxlength="{{maxLength()}}">
            <textarea *ngIf="showTextArea()" (ngModelChange)="onChange($event)" name="value" [(ngModel)]="stdValue"
                      rows="5"
                      cols="50" cdkTextareaAutosize cdkAutosizeMinRows="3"
                      (blur)="onBlurCheck()" maxlength="{{maxLength()}}" (keyup)="checkMaxLength()"></textarea>
            <ng-container *ngIf="hasUnite">
                <span class="carac-unite">{{caracConfig.DicoCarac.Unite}}</span>
            </ng-container>
        </div>
        <span *ngIf="displayRequiedError" class="requied-error-message inpu-custo">Champ obligatoire</span>
        <span *ngIf="!isNotMaxLength"
              class="requied-error-message inpu-custo">La taille limite de ce champ est de {{maxLength()}}.</span>
    </div>
</ng-template>
