import {Component, Input, OnInit} from '@angular/core';
import {ProductSummaryWithTotalRow} from 'src/app/data/types';
import {ProductsFacade} from 'src/app/data/facades';
import {Observable} from 'rxjs';
import {ProductSummary} from 'src/app/data/models/sp-models';
import {map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectStatus } from 'src/app/store/workflow-status';
import { concatLatestFrom } from '@ngrx/effects';
import { AdvSearchArgs } from 'src/lib';

@Component({
    selector: 'app-home-page-block',
    templateUrl: './home-page-block.component.html',
    styleUrls: ['./home-page-block.component.scss']
})
export class HomePageBlockComponent implements OnInit {
    @Input() advancedSearch: AdvSearchArgs;
    @Input() customClass: string;

    public cardsView$!: Observable<ProductSummary[]>;


    constructor(
        private _productsFacade: ProductsFacade,
        private _store: Store
    ) {
    }

    ngOnInit(): void {
        this.cardsView$ = this._getProducts();
    }

    private _getProducts(): Observable<ProductSummary[]> {
        return this._productsFacade.fetchElementsByAdvancedSearch(this.advancedSearch)
        .pipe(
            concatLatestFrom(() => [
                this._store.select(selectStatus)
            ]),
            map(([productsSummaryWithTotalRow, workflowStatus]) => {
                const productSummariesWithWorkflowStatusLabel = productsSummaryWithTotalRow.productSummaries.map(productSummary => {
                    const statusExtID = productSummary.element.getValueStatus()?.StatusExtID;
                    const status = workflowStatus.find(wfStatus => wfStatus.Name === statusExtID) || null;
                    return { ...productSummary, status };
                });

                const updatedProductSummariesResult: ProductSummaryWithTotalRow = {
                    totalsRows: productsSummaryWithTotalRow.totalsRows,
                    productSummaries: productSummariesWithWorkflowStatusLabel
                };

                return updatedProductSummariesResult.productSummaries;
            })
        );
    }
}
