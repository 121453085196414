<div class="d-flex justify-content-between align-items-center">
  <ng-container *ngIf="dataView$ | async as dataView">
    <h2 mat-dialog-title>{{ data?.chapter?.name }} <span class="products-nb" *ngIf="dataView.productWithTotalRow?.totalsRows">{{dataView.productWithTotalRow?.totalsRows}} {{'publication.catalogue.product-s' | translate}}</span></h2>
  </ng-container>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <div class="dialog-content__products-container">
    <app-products-selection-filters 
    [chapter]="data?.chapter"
    [paramFilterBuilder]="paramFilterBuilder"
    (firstPage)="goToFirstPage()"
    (filterElements)="searchProducts()"></app-products-selection-filters>
    
    <ng-container *ngIf="dataView$ | async as dataView">
      <app-products-selection 
      [loading]="dataView.loading"
      [products]="dataView.productWithTotalRow?.productSummaries?.data" 
      [totalRecords]="dataView.productWithTotalRow?.totalsRows"
      [chapter]="data?.chapter"
      [page]="data?.page"
      [descriptionField]="data?.descriptionField"
      [paramFilterBuilder]="paramFilterBuilder"
      [pageSize]="pageSize"
      (filterElements)="searchProducts()"></app-products-selection>
    </ng-container>
  </div>
</mat-dialog-content>
