import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CaracInfo, SummaryElement} from '../np-value/Model';
import {
    Access,
    CaracConfig,
    DicoCarac,
    ElementRepository,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracLien,
    NPCaracLienRebuildValue,
    NPElement,
    TypeCode,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import {MediaFillRateService} from '../../services/media-fill-rate.service';
import {TableRow} from '../link-style-array/link-style-array.component';
import {flatMap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {WarningService} from '../../services/warning.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'lib-link-style-array2',
    templateUrl: './link-style-array2.component.html',
    styleUrls: ['./link-style-array2.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkStyleArray2Component implements OnInit {
    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    private _voe = new ValueOneElementHelper();
    private _initialListPossibles: SummaryElement[] = [];
    private elementsWithAllCaracs: NPElement[] = [];
    public readOnlyAccess = false;

    public columns: CaracConfig[];
    public elements: TableRow[];
    public listPossibles: SummaryElement[] = [];
    public isValid = true;
    public searchTextControl = new UntypedFormControl();
    private _subElements: Subscription;
    private _rebuildLinkedElements: NPCaracLienRebuildValue[] = [];

    constructor(private _mediaFillRateService: MediaFillRateService,
                private _elementRepository: ElementRepository,
                private _eltWrSrv: ElementWriterService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                private _snackBar: MatSnackBar,
                private _warningService: WarningService) {
    }

    ngOnInit() {

        this._initAccess();

        // On n'affiche pas la carac de configuration d'un nouvel elemment, elle sert juste à apporter la config de nouveau élemeent à ajouter dans le tabeleau
        this.columns = this.caracConfig.ConfigurationObject.filter(column => column.BlocInfo !== 'newElementConfigs');
        // On ne veut pas que les champs du tabeau apparaissent avec  label
        this.columns.map(caracConfig => {
            return caracConfig.PrintLabel = false;
        });

        // On récupère la liste d'élements existants
        this._initData();
        // this.elements = this.value.RebuildLinkedElements.map(rel => ({element: rel.Element, over: false}));

        if (!this.readOnlyAccess) {
            this._updateListePossible('');
            this.searchTextControl.valueChanges
                .subscribe((searchText: string) => {
                    this._search(searchText);
                });
        }

        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });
    }

    getCISV(currElement, caracConfig) {
        return {
            value: this._voe.getCaracValue(currElement, caracConfig),
            config: caracConfig
        };
    }

    isFull(currElement: NPElement) {
        // ici on ne gère que les données structutées, le reste est géré dans NPElementViewComponent
        return this.caracConfig.DicoCarac.TypeCode === TypeCode.STRUCTUREDDATA ?
            this._mediaFillRateService.isNotFulledtFillRateGlobal(currElement) : false;
    }

    // Supprime un élement de la liste
    delete(pTableRow: TableRow) {
        this._warningService.warningBox()
            .subscribe(toDelete => {
                if (toDelete && pTableRow) {
                    this._eltWrSrv.deleteValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, pTableRow.element);
                    // TODO: Revoir comment actualiser l'interface en récuperant les données de RebuildLinkedElements après la suppression
                    this.elements = this.elements.filter(value => value.element.ExtID !== pTableRow.element.ExtID);
                }
            });
    }

    /**
     * Initialisation de la liste des éléments possibles
     */
    private _updateListePossible(searchText: string) {
        this.listPossibles = [];
        this._subElements = this._elementRepository.searchForLink(this.caracConfig.DicoCarac, searchText, this.value.Element)
            .pipe(
                flatMap(result => {
                    this.listPossibles = result.map(rel => {
                        // On ajoute l'élément à la liste s'il n'est pas lié
                        // Attention: les élements renvoyés par cette API n'ont pas toutes les caracs(nécessaires pour leur utilisation dans le tableau)
                        // Raison pour laquelle on fait un autre appel pour récupérer les mêmes élements mais avec toutes les caracs:
                        return {
                            elementName: this._voe.getLabel(rel),
                            elementID: rel.ID,
                            elementOrder: rel.getValueSearchRankLevelSearchRanking(),
                            element: rel
                        };
                    });
                    this._initialListPossibles = Object.assign([], this.listPossibles);
                    const extIDs: string[] = this.listPossibles.map(value => (value.element.ExtID));
                    return this._elementRepository.getElements(extIDs, [[]]);
                })
            )
            .subscribe(result => {
                this.elementsWithAllCaracs = Array.from(result.values());
            });
    }

    /**
     * Ajoute un élement dans le tableau
     */
    addElement(selected: SummaryElement) {
        // debugger;
        if (this._notAlreadyExist(selected.element.ExtID)) {
            // Si l'élement a déjà été chargé, on le récupère directement de elementsWithAllCaracs, sinon on le cherche dans np;
            const tmpElement = this.elementsWithAllCaracs.find(currElement => currElement.ExtID === selected.element.ExtID);
            if (tmpElement) {
                this._save(tmpElement);
            } else {
                this._elementRepository.getElements([selected.element.ExtID], [[]])
                    .subscribe(elements => {
                        const elementFromAPI = elements.get(selected.element.ExtID);
                        this._save(elementFromAPI);
                    });

            }
        } else {
            this._snackBar.open('Ce produit existe déjà dans le tableau !', 'Fermer', {
                duration: 3000,
                verticalPosition: 'top',
                panelClass: ['blue-snackbar']
            });
        }
    }

    /**
     * Enregistre un élement dans la carac lien indiquée
     * et met à jour l'interface (tabeleau
     * element: L'élement à ajouter dans le tableau
     */
    private _save(element: NPElement) {
        if (element) {
            this._eltWrSrv.concatValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, element);
            this.elements.push({element: element, over: false});
        }
    }

    /**
     * Vérifier si l'élément est lié.
     */
    private _notAlreadyExist(extId: string) {
        return this.elements.every(currValue => currValue.element.ExtID !== extId);
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    private _search(searchText: string) {
        this.listPossibles = searchText === '' ? this._initialListPossibles :
            this._initialListPossibles.filter(currValue => currValue.elementName.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
                || currValue.element.ExtID.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
                || String(currValue.elementID).toUpperCase().indexOf(searchText.toUpperCase()) !== -1);
    }

    private _initAccess() {
        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;
    }

    ready() {
        return this.value !== undefined && this.caracInfo !== undefined && this.caracConfig !== undefined;
    }

    private _initData() {

        if (this.hasFilter()) {
            const tmpFilter = this.caracConfig.Specific[FILTER_CONSTATNTES.Filter];
            const tmpFilterDicoCarac = tmpFilter[FILTER_CONSTATNTES.Where];
            const tmpFilterValue = tmpFilter[FILTER_CONSTATNTES.Equals];
            this._rebuildLinkedElements = this.value.RebuildLinkedElements.filter(link => {
                const tmpElement = link.Element.getValueLien(tmpFilterDicoCarac).RebuildLinkedElements[0];
                return tmpElement && tmpElement.Element.ExtID === tmpFilterValue;
            });
            this.elements = this._rebuildLinkedElements.map(rel => ({element: rel.Element, over: false}));

        } else {
            this.elements = this.value.RebuildLinkedElements.map(rel => ({element: rel.Element, over: false}));
        }
    }

    private hasFilter() {
        return this.caracConfig && this.caracConfig.Specific && this.caracConfig.Specific[FILTER_CONSTATNTES.Filter]
            && this.caracConfig.Specific[FILTER_CONSTATNTES.Filter][FILTER_CONSTATNTES.Where]
            && this.caracConfig.Specific[FILTER_CONSTATNTES.Filter][FILTER_CONSTATNTES.Where] !== ''
            && this.caracConfig.Specific[FILTER_CONSTATNTES.Filter][FILTER_CONSTATNTES.Equals]
            && this.caracConfig.Specific[FILTER_CONSTATNTES.Filter][FILTER_CONSTATNTES.Equals] !== '';
    }

    /**
     * Retourne le nom (label) de la carac passée en param
     * Si la carac est de type ##ProductLabel, on affiche le label de la carac de substitution (##ProductLabelCaracExtID)
     * qui doit être créée dans nextPage.
     * Cela évite l'affichage de "ProductLabel" qui n'est pa modifiable
     */
    getColumnName(currCarac: CaracConfig) {
        if (currCarac) {
            if (currCarac.DicoCaracExtID === DicoCarac.PRODUCT_LABEL) {
                const productLabel = currCarac.ConfigurationObject ?
                    currCarac.ConfigurationObject.find(cc => currCarac.Specific && cc.DicoCaracExtID === currCarac.Specific[DicoCarac.PRODUCT_LABEL]) : undefined;
                return productLabel ? productLabel.Label : currCarac.Label;
            }
            return currCarac.Label;
        }
        return 'Inconnu';

    }
}

enum FILTER_CONSTATNTES {
    Filter = 'Filter',
    Where = 'WhereDicoCaracValue',
    Equals = 'Equals'
}
