<ul class="d-flex flex-column w-auto list-unstyled products-list">
  <ng-container *ngIf="page?.products">
    <li class="d-flex justify-content-between w-100 products-list__item item-selected"
    *ngFor="let product of page?.products">
      <button (click)="selectProduct()">{{ product.name }}</button>
      <button (click)="confirmProductDeletion(product)"><fa-icon [icon]="['far', 'square-xmark']"></fa-icon></button>
    </li>
  </ng-container>
  <ng-container *ngIf="(page?.template?.numberOfElements - page?.products.length) > 0">
    <li class="d-flex justify-content-between w-100 products-list__item no-item-selected" *ngFor="let item of [].constructor(page?.template?.numberOfElements - page?.products.length)">
      <button class="add-btn" (click)="selectProduct()">{{ 'publication.catalogue.add-product' | translate }}</button>
    </li>
  </ng-container>
</ul>
