<main class="catalogue-page__container">
  <ng-container *ngIf="(catalogView$ | async) as catalogView">
    <h1 class="d-flex align-items-center catalogue-page__title">
      {{catalogView.catalog?.name}}
      <button mat-icon-button (click)="toggleOverlay($event)">
        <fa-icon [icon]="['far', 'pen']"></fa-icon>
      </button>
      <app-catalogue-edition-overlay #catalogueEditionOverlay 
      [catalog]="catalogView.catalog"
      [catalogId]="catalogId"></app-catalogue-edition-overlay>
    </h1>

    <mat-accordion class="d-block m-auto catalogue-page__main" multi="true">

      <ng-container *ngIf="catalogView.catalog?.chapters as chapters">
        <ng-container *ngFor="let chapter of chapters">
          <app-catalogue-chapter [chapter]="chapter"></app-catalogue-chapter>
        </ng-container>
      </ng-container>

    </mat-accordion>
  </ng-container>
</main>