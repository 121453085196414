import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {CaracInfo} from '../Model';
import {FileReaderService} from '../../../services';
import {PhotosInfos} from '../Model';
import {first} from 'rxjs/operators';
import {NpMediasSelectionComponent} from '../../np-medias-selection/np-medias-selection.component';

import {DicoCarac, ElementWriterService, NPCaracLien, NPCaracLienRebuildValue, NPElement, CaracConfig} from '@nextpage/np-sdk-data';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'lib-app-value-image-write-view',
    templateUrl: './np-value-image-write-view.component.html',
    styleUrls: ['./np-value-image-write-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueImageWriteViewComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public photosUrl: PhotosInfos[];

    public val: NPCaracLien;

    constructor(private _fileReader: FileReaderService,
                private _dialog: MatDialog,
                private _elementWriter: ElementWriterService) {

        this.photosUrl = [];
    }

    ngOnInit() {
        this._buildState();
    }


    private _buildState() {
        this.val = this.value.Element.getValueLien(this.value.DicoCaracExtID);
        this.photosUrl = [];
        this.val.RebuildLinkedElements.forEach(link => {
            this.photosUrl.push(new PhotosInfos(
                this._fileReader.toUrlThumbImage(link.Element),
                this._fileReader.toUrlThumbImage(link.Element),
                link.Element.getValueTextValue(DicoCarac.MEDIA_FILE),
                link.Order
            ));
        });
        this.photosUrl = this.photosUrl.sort((a, b) => a.order - b.order);
    }

    deletePicture(picture: PhotosInfos) {
        const mediaToDelete: NPCaracLienRebuildValue = this._getLinkedMedia(picture);
        this._elementWriter.deleteValueLink(this.value.Element, this.value.DicoCaracExtID, mediaToDelete.Element);
        this._buildState();
    }

    changePicture(picture: PhotosInfos) {
        const mediaToChange: NPCaracLienRebuildValue = this._getLinkedMedia(picture);
        this._selectMedia(mediaToChange.Element.ID)
            .subscribe((result) => {
                this._elementWriter.changeValueLink(this.value.Element, this.value.DicoCaracExtID, result, mediaToChange.Order);
                this._buildState();
            });
    }

    addPicture() {
        this._selectMedia()
            .subscribe((result: NPElement) => {
                if (result != null) {
                    this._elementWriter.concatValueLink(this.value.Element, this.value.DicoCaracExtID, result);
                    this._buildState();
                }
            });
    }

    // private _getMaxOrder(): number {
    //   if (this.photosUrl.length === 0) {
    //     return 1;
    //   }
    //   return Math.max(1, ...this.photosUrl.map((v) => v.order)) + 1;
    // }

    private _selectMedia(selectedMediaID: number = -1) {
        return this._dialog.open(NpMediasSelectionComponent, {
            width: '80%',
            height: '70%',
            data: {
                selectedMediaID: selectedMediaID,
                dicoCaracExtID: this.value.DicoCaracExtID
            }
        }).afterClosed().pipe(
            first()
        );
    }


    private _getLinkedMedia(picture: PhotosInfos) {
        const value = this.val.RebuildLinkedElements.filter((val: NPCaracLienRebuildValue) => {
            return picture.order === val.Order;
        });
        if (value.length === 1) {
            return value[0];
        }
        return null;
    }

}

