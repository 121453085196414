import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NpParameterService} from 'src/lib/data/services';
import {map} from 'rxjs/operators';
import {removeUnauthorizedChars} from '../../core/functions/media-thumb-size.functions'; // A revoir

@Injectable({
    providedIn: 'root'
})
export class ThumbsSizeService {

    private _parameterService = inject(NpParameterService);

    getAllowedThumbsSize(): Observable<string[]> {
        return this._parameterService.getByName('ThumbsSizes')
            .pipe(
                map(thumbsSizes =>
                    !!thumbsSizes?.Valeur ?
                        thumbsSizes.Valeur
                            .split(',')
                            .map(removeUnauthorizedChars)
                        : [])
            );
    }
}
