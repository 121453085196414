
<ng-container *ngIf='dataSource && headerLabels'>
    <div id='media-table'>
        <table mat-table [dataSource]='dataSource' multiTemplateDataRows>
            <ng-container [matColumnDef]='column' *ngFor='let column of columnsToDisplay'>
                <th mat-header-cell *matHeaderCellDef>
                    {{ headerLabels[column] | translate }}
                </th>
                <td mat-cell *matCellDef='let element' 
                [title]="((column !== 'actions') ? 'common.click-more-information' : '') | translate"
                (click)="column !== 'actions' && showMoreInfos(element)">
                    <ng-container *ngIf="column === 'select'; else viewMediaTable">
                        <mat-checkbox
                                (click)='$event.stopPropagation(); getSelectedValue(element)'
                                (change)='$event ? selection.toggle(element) : null'
                                [checked]='selection.isSelected(element)'
                        >
                        </mat-checkbox>
                    </ng-container>
                    <ng-template #viewMediaTable>
                        <ng-container *ngIf="column === 'overview'; else viewMediaDetails">
                            <img
                                    [src]='element.imageUrl'
                                    class='imageUrlStyle'
                                    height='125'
                                    alt='Aperçu du média'
                            />
                        </ng-container>
                        <ng-template #viewMediaDetails>
                            <app-product-table-cell-view [element]='element' [column]='column'>
                            </app-product-table-cell-view>
                        </ng-template>
                    </ng-template>
                    <ng-container *ngIf="column === 'actions'">
                        <div class="d-flex">
                            <button mat-icon-button class="icon-more-info"
                                    [matTooltip]="'common.display-more-information' | translate"
                                    (click)='showMoreInfos(element)'>
                                <img src="./assets/icons/eye.svg" class="fontawesome-icon-custom icon-eye" alt="eye" height="16"
                                     width="16">
                            </button>
                            <button mat-icon-button matTooltip="Ajouter aux favoris" 
                                    [matTooltip]="((favoritesState$ | async | favorite:element) ? 'common.remove-favorites' : 'common.add-favorites') | translate"
                                    (click)='toggleFavorite(element, $event)'>
                                <mat-icon [style.color]="(favoritesState$ | async | favorite:element) ? 'red' : ''">
                                    {{(favoritesState$ | async | favorite:element) ? 'favorite' : 'favorite_border'}}
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='columnsToDisplay'></tr>
            <tr
                    mat-row
                    *matRowDef='let row; columns: columnsToDisplay'
                    class='expanded-detail-element-row'
            ></tr>
        </table>
        <ng-content></ng-content>
    </div>
</ng-container>
