import {createAction, props} from '@ngrx/store';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';

export const FetchDashboardAdvancedSearches = createAction('[Dashboard Page] Fetch Advanced Searches');

export const FetchDashboardAdvancedSearchesLoader = createAction('[Dashboard Page]  Loader starting');

export const FetchDashboardAdvancedSearchesSuccess = createAction('[dashboard Page] Fetch Advanced Searches Success', props<{
    advancedSearches: AdvSearchArgs[]
}>());

export const FetchDashboardAdvancedSearchesFailure = createAction('[dashboard Page] Fetch Advanced Searches Failure', props<{
    message: string
}>());
