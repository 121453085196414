import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchCharTemplatesSuccess,
    FetchProductCharTemplates,
    FetchProductCharTemplatesFailure
} from './char-template.actions';
import {ProductsFacade} from 'src/app/data/facades';
import {selectAdvancedSearchParamsScope} from '../../table';
import {ParamsFilterBuilder} from 'src/app/data/builders';
import {environment} from 'src/environments/environment';

@Injectable()
export class CharTemplateEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private productsFacade: ProductsFacade
    ) {
    }

    fetchProductCharTemplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchProductCharTemplates),
            switchMap(action => this._store.select(selectAdvancedSearchParamsScope)
                .pipe(
                    filter(result => !!result.selectedScope?.scopeId),
                    map(scope => ({scope, action}))
                ),
            ),
            switchMap(result => {
                    const paramFilterBuilder = new ParamsFilterBuilder()
                        .withElementTypes([environment.instance.elementType])
                        .withScope({isChannel: result.scope.selectedScope.isChannel, scopeId: result.scope.selectedScope.scopeId})
                        .build();

                    return this.productsFacade.getCharTemplatesByAdvSearch(paramFilterBuilder)
                        .pipe(
                            map((charTemplates) => FetchCharTemplatesSuccess({charTemplates})),
                            catchError(error => of(FetchProductCharTemplatesFailure({message: error.toString()})))
                        );
                }
            )
        );
    });
}
