import {createReducer, on} from '@ngrx/store';
import * as _ from 'lodash';
import { Catalog, CatalogConfig } from '@app/models';
import { CreateCatalog, CatalogActionsFailure, CatalogActionsSuccess, GenerateCatalog, GetCatalog, GetCatalogFailure, GetCatalogSuccess, SaveCatalog, AddOrDeletePage, AddOrDeleteProduct } from './catalog.actions';
import { PublicationHelper } from 'src/app/data/helpers/publication.helper';

export const catalogFeatureKey = 'catalog';

export interface CatalogState {
    catalog: Catalog;
    catalogConfig: CatalogConfig;
    errorMessage: any;
    loading: boolean;
    hasError: boolean;
}

export const initialStateCatalog: CatalogState = {
    catalog: null,
    catalogConfig: null,
    errorMessage: null,
    loading: true,
    hasError: false
};

export const catalogReducer = createReducer(
    initialStateCatalog,
    on(
        GetCatalog,
        (state, ): CatalogState => ({
            ...state,
            catalog: null,
            catalogConfig: null,
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        GetCatalogSuccess,
        (state, {catalog}): CatalogState => ({
            ...state,
            catalog,
            catalogConfig: null,
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        GetCatalogFailure,
        (state, {message}): CatalogState => {
            return ({
                ...state,
                catalog: null,
                catalogConfig: null,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
    on(
        CreateCatalog,
        (state, ): CatalogState => ({
            ...state,
            catalog: null,
            catalogConfig: null,
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        CatalogActionsSuccess,
        (state, {catalogConfig}): CatalogState => {
            return ({
                ...state,
                catalog: catalogConfig?.structure,
                catalogConfig: PublicationHelper.removeFirstBlankPage(catalogConfig) as CatalogConfig,
                loading: false,
                hasError: false,
                errorMessage: null
            })
        } 
    ),
    on(
        CatalogActionsFailure,
        (state, {message, catalog}): CatalogState => {
            return ({
                ...state,
                catalog: catalog,
                catalogConfig: null,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
    on(
        GenerateCatalog,
        (state, ): CatalogState => ({
            ...state,
            loading: true,
            errorMessage: null
        }) 
    ),
    on(
        SaveCatalog,
        (state, ): CatalogState => ({
            ...state,
            loading: true,
            errorMessage: null
        }) 
    ),
    on(
        AddOrDeletePage,
        (state, {catalog}): CatalogState => {
            return ({
                ...state,
                catalog: catalog
            })
        }
    ),
    on(
        AddOrDeleteProduct,
        (state, {catalog}): CatalogState => {
            return ({
                ...state,
                catalog: catalog
            })
        }
    )
);
