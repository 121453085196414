import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DialogProductDeletionComponent } from 'src/app/graphics/components/publication/dialog-product-deletion/dialog-product-deletion.component';
import {
  DialogProductsSelectionComponent
} from 'src/app/graphics/components/publication/dialog-products-selection/dialog-products-selection.component';
import { CatalogPage, Chapter, Product, Catalog } from '@app/models';
import { Observable, Subscription, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserCustomFieldValue } from '@store/selector';
import { SpDicoCarac } from 'src/app/data/constants';
import { filter, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AddOrDeleteProduct, CatalogState, selectCatalogView } from '@store/publication';

@Component({
  selector: 'app-catalogue-products',
  templateUrl: './catalogue-products.component.html',
  styleUrls: ['./catalogue-products.component.scss']
})
export class CatalogueProductsComponent implements OnInit, OnDestroy {
  @Input() chapter: Chapter;
  @Input() page: CatalogPage;

  public catalogView$ = this._store.select(selectCatalogView)
    .pipe(
        filter((catalogState: CatalogState) => !!catalogState?.catalog?.chapters)
    );

  public descriptionField: string | Observable<never>;
  
  private _catalog: Catalog;
  private _catalogSub: Subscription;

  constructor(
    public dialog: MatDialog,
    private _store: Store) {
  }

  ngOnInit(): void {
    this._store.select(selectUserCustomFieldValue(SpDicoCarac.CP_EXTID_DESCRIPTIF_PUBLICATION))
      .pipe(
          map(value => value ? value : throwError('Custom field extID not found.'))
      ).subscribe(field => this.descriptionField = field);

    this._catalogSub = this.catalogView$
      .pipe(
        tap((catalogState: CatalogState) => {
          this._catalog = catalogState.catalog;
        }),
      ).subscribe();
  }

  public selectProduct(): void {
    this.dialog.open(DialogProductsSelectionComponent, {
      panelClass: 'products-selection-dialog',
      data: { chapter: this.chapter, descriptionField: this.descriptionField, page: this.page }
    });
  }

  public confirmProductDeletion(product: Product): void {
    this.dialog.open(DialogProductDeletionComponent, {
      panelClass: 'product-deletion-dialog',
      data: product
    }).afterClosed()
    .subscribe(confirmDeletion => {
      if (confirmDeletion
        && this.chapter?.pages
        && this.page) {
        _.remove(this.page.products, product);

        if(this._catalog) {
          const chapterIndex = _.findIndex(this._catalog.chapters, { id: this.chapter.id });
          if (chapterIndex !== -1) {
            _.set(this._catalog.chapters, chapterIndex, this.chapter);
          }

          this._store.dispatch(AddOrDeleteProduct({ catalog: this._catalog }));
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this._catalogSub?.unsubscribe();
  }
}
