import { Pipe, PipeTransform } from '@angular/core';

import {ProductSummary} from '@app/models';

@Pipe({
  name: 'favorite',
})
export class FavoritePipe implements PipeTransform {
  transform(favoriteList: string[], elementSummary: ProductSummary): boolean {
    elementSummary.isFavoriteElement = favoriteList.some(extID => elementSummary.element.ExtID === extID);
    return elementSummary.isFavoriteElement;
  }
}
