import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CatalogTreeUpdate, CatalogTreeUpdateFailure, DeleteCatalogTree, DeleteCatalogTreeFailure, DeleteCatalogTreeSuccess, DuplicateCatalogTree, FetchCatalogTree, FetchCatalogTreeFailure, FetchCatalogTreeSuccess, GetCatalogPdf, GetCatalogFileSuccess, GetCatalogSources, GetCatalogFileFailure } from '@store/publication';
import { Store } from '@ngrx/store';
import { NpCatalogService } from '../../../../lib';
import { selectPublicationCataloguesFolderId } from '../../selector/user-info.selectors';
import * as _ from 'lodash';
import { DialogService } from 'src/app/graphics/services/dialog.service';
import { toTreeNodeCatalogModel } from '@np/utils';

@Injectable()
export class CatalogTreeEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private _catalogService: NpCatalogService,
        private _dialogService: DialogService) {
    }

    catalogTreeEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchCatalogTree),
            mergeMap(() =>
                this._store.select(selectPublicationCataloguesFolderId).pipe(
                    filter(folderId => !_.isNil(folderId)),
                    switchMap(folderId => {
                        if (folderId === '') {
                            return of(FetchCatalogTreeSuccess({ catalogTree: [] }));
                        }
                        return this._catalogService.getCatalogTreeByParentId(folderId as number).pipe(
                            map(catalogsTreeDto => {
                                const catalogsTreeDtoToCatalogsTreeModel = catalogsTreeDto.map(catalogTreeDto => toTreeNodeCatalogModel(catalogTreeDto));
                                return FetchCatalogTreeSuccess({ catalogTree: catalogsTreeDtoToCatalogsTreeModel })
                            })
                        );
                    })
                )
            ),
            catchError(error => {
                return of(FetchCatalogTreeFailure({ message: error }))
            })
        );
    });

    duplicateCatalogTreeEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCatalogTree),
            switchMap((action) => {
                return this._catalogService.duplicateCatalog(action.catalogId).pipe(
                    map(catalogTreeDto => {
                        const catalogTreeDtoToCatalogTreeModel = toTreeNodeCatalogModel(catalogTreeDto);
                        this._dialogService.showSuccessMessage('publication.homepage.catalogue-duplicated');
                        return CatalogTreeUpdate({catalogTree : catalogTreeDtoToCatalogTreeModel});
                    }),
                    catchError((error) => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-duplication-error');
                        return of(CatalogTreeUpdateFailure({ message: error }));
                    })
                );
            })
        );
    });

    deleteCatalogTreeEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DeleteCatalogTree),
            switchMap((action) => {
                return this._catalogService.deleteCatalogTree(action.catalog.id).pipe(
                    map(() => {
                        this._dialogService.showSuccessMessage('publication.homepage.catalogue-deleted');
                        return DeleteCatalogTreeSuccess({catalogTree: action.catalog});
                    }),
                    catchError((error) => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-deletion-error');
                        return of(DeleteCatalogTreeFailure({ message: error }));
                    })
                );
            })
        );
    });

    getCatalogPdfEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetCatalogPdf),
            switchMap((action) => {
                return this._catalogService.getCatalogPdfFile(action.catalog).pipe(
                    map(() => {
                        return GetCatalogFileSuccess();
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-pdf-error');
                        return of(GetCatalogFileFailure({ message: error }))
                    })
                );
            })
        );
    });

    getCatalogSourcesEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetCatalogSources),
            switchMap((action) => {
                return this._catalogService.getCatalogZipFile(action.catalog).pipe(
                    map(() => {
                        return GetCatalogFileSuccess();
                    }),
                    catchError(error => {
                        this._dialogService.showErrorMessage('publication.homepage.catalogue-sources-error');
                        return of(GetCatalogFileFailure({ message: error }))
                    })
                );
            })
        );
    });
}
