import {Injectable} from '@angular/core';
import {FileReaderService} from '../file-reader.service';
import {DicoCarac, ElementRepository, NPElement, NPElementType} from '@nextpage/np-sdk-data';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, flatMap, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NpMediasTreeService {

    private _mediasTree: NpMediaDirectory;
    private selectedMediaDirectory: NpMediaDirectory;
    private _sendSelectedMediaDirectory = new BehaviorSubject<NpMediaDirectory>(null);
    private _loading = new BehaviorSubject<boolean>(false);

    constructor(private _fileReader: FileReaderService,
                private _elementReader: ElementRepository) {
    }


    public getMediasTree(rootDirectory: NPElement): NpMediaDirectory {
        this._mediasTree = new NpMediaDirectory(rootDirectory);
        this.setMediasTree(this._mediasTree);
        return this._mediasTree;

    }

    private setMediasTree(mediaDirectory: NpMediaDirectory) {
        const rootDirectory = mediaDirectory.rootDirectory;
        const medias = this._getChildrenByType(rootDirectory, NPElementType.Media)
            .map(element => {
                const newMedia = new NpMedia();
                newMedia.src = this._fileReader.toUrlMedia(element);
                    newMedia.thumb = this._fileReader.toUrlThumbImage(element);
                    newMedia.alt = element.Values.get(DicoCarac.MEDIA_LABEL)['Value'];
                    newMedia.media = element;
                    newMedia.parentName = mediaDirectory.name;
                return newMedia;
            });

        const mediasDirectory = this._getChildrenByType(rootDirectory, NPElementType.MediaDirectory)
            .map(_mediaDirectoryElement => {
                return new NpMediaDirectory(_mediaDirectoryElement);
            });

        mediaDirectory.medias = medias;
        mediaDirectory.mediasDirectory = mediasDirectory;

        if (mediaDirectory.mediasDirectory.length > 0) {
            mediaDirectory.mediasDirectory.forEach(_mediaDirectoryElement => {
                this.setMediasTree(_mediaDirectoryElement);
            });
        }

    }

    private _getChildrenByType(directory: NPElement, type: NPElementType): NPElement[] {
        return directory.Children ? directory.Children
            .filter((element: NPElement) => {
                return element.ElementType === type;
            }) : [];
    }

    public displayMedias(directory: NpMediaDirectory) {
        this.selectedMediaDirectory = directory;
        this._sendSelectedMediaDirectory.next(this.selectedMediaDirectory);
    }

    isSelected(directory: NpMediaDirectory) {
        return this.selectedMediaDirectory && directory &&
            this.selectedMediaDirectory.rootDirectory.ExtID === directory.rootDirectory.ExtID;
    }

    public getSelectedMediaDirectory() {
        return this._sendSelectedMediaDirectory.asObservable()
            .pipe(
                filter(data => data !== undefined && data !== null)
            );
    }

    loadChildren(mediaDirectory: NpMediaDirectory, forced = false): Observable<boolean> {
        this.setLoading(true);
        this.displayMedias(mediaDirectory);
        if (!forced && (mediaDirectory.opened || mediaDirectory.hasBeenLoaded)) {
            mediaDirectory.opened = !mediaDirectory.opened;
            this.displayMedias(mediaDirectory);
            this.setLoading(false);
            return of(false);
        } else {
            return this._elementReader.getDescendants([mediaDirectory.rootDirectory.ExtID], [[]], true)
                .pipe(
                    tap(result => {
                        mediaDirectory.rootDirectory.Children = result.get(mediaDirectory.rootDirectory.ExtID).Children || [];
                        const tmpDir = this.getMediasTree(mediaDirectory.rootDirectory);
                        mediaDirectory.medias = tmpDir.medias;
                        mediaDirectory.mediasDirectory = tmpDir.mediasDirectory;
                        mediaDirectory.opened = !mediaDirectory.opened;
                        mediaDirectory.hasBeenLoaded = true;
                        this.displayMedias(mediaDirectory);
                        this.setLoading(false);
                    }),
                    flatMap(() => {
                        return of(true);
                    })
                );
        }
    }

    private setLoading(value: boolean) {
        this._loading.next(value);
    }

    public getLoading() {
        return this._loading.asObservable();
    }
}

export class NpMedia {
    src: string;
    thumb: string;
    alt: string;
    media: NPElement;
    parentName: string;
}

export class NpMediaDirectory {
    rootDirectory: NPElement;
    name: string;
    medias: NpMedia[] = [];
    mediasDirectory: NpMediaDirectory[] = [];
    opened = false;
    hasBeenLoaded = false;

    constructor(mediaElement?: NPElement) {
        if (mediaElement) {
            this.rootDirectory = mediaElement;
            this.name = mediaElement.Values.get(DicoCarac.MEDIADIRECTORY_LABEL)['Value'];
        }
    }
}
