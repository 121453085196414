import {createReducer, on} from '@ngrx/store';
import {FetchAdvancedSearchParamsFailure, FetchAdvancedSearchParamsSuccess, UpdateScopeSuccess} from './advanced-search-params.actions';

export const advancedSearchParamsFeatureKey = 'advancedSearchParams';

export interface AdvancedSearchParamsState {
    scopeId: number | null;
    isChannel: boolean;
    selectedScope: {
        scopeId: number | null;
        isChannel: boolean;
    };
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialAdvancedSearchParams: AdvancedSearchParamsState = {
    scopeId: null,
    isChannel: false,
    selectedScope: null,
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const advancedSearchParamsReducer = createReducer(
    initialAdvancedSearchParams,
    on(
        FetchAdvancedSearchParamsSuccess,
        (state, {scopeId, isChannel}): AdvancedSearchParamsState => {
            return ({
                ...state,
                scopeId: scopeId,
                isChannel: isChannel,
                selectedScope: {
                    scopeId,
                    isChannel
                },
                loading: false,
                errorMessage: null
            });
        }
    ),
    on(
        UpdateScopeSuccess,
        (state, {scopeId, isChannel}): AdvancedSearchParamsState => {
            return ({
                ...state,
                selectedScope: {
                    scopeId,
                    isChannel
                }
            });
        }
    ),
    on(FetchAdvancedSearchParamsFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
