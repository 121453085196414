import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchAllCharacteristics,
    FetchCharacteristicsFailure,
    FetchCharacteristicsSuccess,
} from './characteristics.actions';
import {DicocaracRepository} from '../../../lib';
import {selectDataLanguage} from '../data-languages/data-language.selector';

@Injectable()
export class CharacteristicsEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private _dicoCaracRepository: DicocaracRepository
    ) {
    }

    fetchCharacteristics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchAllCharacteristics),
            switchMap(() => this._store.select(selectDataLanguage)
                .pipe(filter(selectedDataLanguage => !!selectedDataLanguage?.ID))),
            switchMap(language => {
                    return this._dicoCaracRepository.getAllByLangId(language.ID)
                        .pipe(
                            map((dicoCharacteristics) => FetchCharacteristicsSuccess({dicoCharacteristics})),
                            catchError(error => of(FetchCharacteristicsFailure({message: error.toString()})))
                        );
                }
            )
        );
    });

}
