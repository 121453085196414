import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {TokenService} from '../services/token.service';
import {SpAuthenticationService} from '../services/sp-authentication.service';
import {RouteResolverService} from '../services/routes/route-resolver.service';
import { RoutePagesName } from '../constants/route-pages.constants';


@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
    constructor(
        private authenticationService: TokenService,
        private spAuthService: SpAuthenticationService,
        private _routeResolverService: RouteResolverService
    ) {}

    canActivate(): boolean {
        if (!this.authenticationService.getToken() || !this.spAuthService.isAuthenticated()) {
            this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.LOGIN);
            return false;
        }
        return true;
    }
}
