import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {iif, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchAdvancedSearchParamsFailure,
    FetchAdvancedSearchParamsSuccess,
    InitDataTable,
    UpdateScope,
    UpdateScopeSuccess
} from './advanced-search-params.actions';
import {DicoCarac, ElementRepository} from '../../../lib';
import {selectChannelScopeExtId, selectFamilyScopeExtId} from '../selector/user-info.selectors';

@Injectable()
export class AdvancedSearchParamsEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private _elementRepository: ElementRepository
    ) {
    }

    intAdvancedSearchParams$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InitDataTable),
            switchMap(() => this._store.select(selectChannelScopeExtId) // Channel scope
                .pipe(map(scopeExtId => ({scopeExtId: scopeExtId, isChannel: true})))
                .pipe(
                    switchMap(scope =>
                        !!scope && !!scope.scopeExtId ?
                            of(scope) :
                            this._store.select(selectFamilyScopeExtId) // Family scope
                                .pipe(map(scopeExtId => ({scopeExtId: scopeExtId, isChannel: false})))
                    )
                )
            ),
            switchMap((scope) => {
                return iif(() => !!scope.scopeExtId, this._getScopeId(scope.scopeExtId), of(0))
                    .pipe(
                        map(scopeId => FetchAdvancedSearchParamsSuccess({scopeId, isChannel: scope.isChannel})),
                        catchError(error => of(FetchAdvancedSearchParamsFailure({message: error.toString()})))
                    );
            })
        );
    });

    updateScope$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UpdateScope),
            map((action) => {
                return UpdateScopeSuccess({scopeId: action.scopeId, isChannel: action.isChannel})
            })
        );
    });

    private _getScopeId = (scopeExtId: string) =>
        this._elementRepository.getElement(
            scopeExtId,
            [[]],
            [DicoCarac.CHANNEL_LABEL, DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.FAMILY_LABEL]
        ).pipe(map(scope => scope.ID))
}
