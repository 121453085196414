import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CatalogPage, Chapter, Catalog, PageTemplate } from "@app/models";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { filter, tap } from "rxjs/operators";
import { TemplatesNames, TemplatesTags } from "src/app/data/enums/publication-tags.enum";
import { AddOrDeletePage, CatalogState, selectCatalogView } from "@store/publication";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-catalogue-page-addition',
  templateUrl: './catalogue-page-addition.component.html',
  styleUrls: ['./catalogue-page-addition.component.scss']
})
export class CataloguePageAdditionComponent implements OnInit, OnDestroy {

  @Input() chapter: Chapter;

  @Output() addPage = new EventEmitter<boolean>();

  public catalogView$ = this._store.select(selectCatalogView)
    .pipe(
        filter((catalogState: CatalogState) => !!catalogState?.catalog?.chapters)
    );

  private _catalog: Catalog;
  private _catalogSub: Subscription;

  constructor(private _store: Store) {}

  public ngOnInit(): void {
    this._catalogSub = this.catalogView$
      .pipe(
          tap((catalogState: CatalogState) => {
              this._catalog = catalogState.catalog;
          }),
      )
      .subscribe();
  }

  public addPageToChapter(): void {
    const newPage = new CatalogPage({
      name: `${TemplatesTags.PRODUCT} - Page`,
      type: TemplatesNames.PRODUCT,
      isEditable: true,
      products: [],
      template: null
    });
    
    if (this.chapter) {
      this.chapter.pages.push(newPage);
      
      if(this._catalog) {
        const chapterIndex = _.findIndex(this._catalog.chapters, { id: this.chapter.id });
        if (chapterIndex !== -1) {
          _.set(this._catalog.chapters, chapterIndex, this.chapter);
        }

        this._store.dispatch(AddOrDeletePage({catalog: this._catalog}));
      }
    }

    this.addPage.emit(true);
  }

  public ngOnDestroy(): void {
    this._catalogSub?.unsubscribe();
  }
}
