import {createAction, props} from '@ngrx/store';
import {ProductSummaryWithTotalRow} from '@data/types';
import {ParamsFilter} from '@app/models';

export const FetchElements = createAction('[elements] Fetch Elements',
    props<{paramsFilter: ParamsFilter}>());

export const FetchElementsLoader = createAction('[elements] Loader starting');

export const FetchElementsSuccess = createAction('[elements] Fetch Elements Success', props<{
    productSummariesResult: ProductSummaryWithTotalRow
}>());

export const FetchElementsFailure = createAction('[elements] Fetch Elements Failure', props<{
    message: string
}>());
