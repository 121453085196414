<ng-container *ngIf="mediaObject">
  <div class="media-preview-content" (click)="onSelectMedia()">
    <div class="media-preview-content-photo">

      <div class="photo-block uploaded-img-block visuel-media">
        <div class="uploaded-img-container">
          <img [src]="mediaObject.src" [alt]="mediaObject.alt" [title]="mediaObject.alt" class="img-responsive"/>
          <ul class="list-unstyled img-actions">
            <li>
              <a [href]="mediaObject.src" title="Zoom" target="_blank"
                 [attr.data-lightbox]="mediaObject.src" [attr.data-title]="mediaObject.alt">
                <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom"/>
              </a>
            </li>
            <li>
              <a title="Télécharger" class="download-btn">
                <img src="assets/imgs/picto-telecharger-photo.png" alt="Télécharger"/>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="media-preview-content-label">
      <div class="parent-name-block">
        <label for="parent-name">Parent:</label>
        <span id="parent-name">{{mediaObject.parentName}}</span>
      </div>
      <div class="media-name-block">
        <label for="media-name">Nom:</label>
        <span id="media-name">{{mediaObject.alt}}</span>
      </div>

    </div>
  </div>
</ng-container>


