import * as _ from 'lodash';

/***
 * Remove extension from file name
 */
export function removeExtension(fileNameWithExtension: string): string {
    if (!!fileNameWithExtension) {
        const fileNameToArray = fileNameWithExtension.split('.') ?? [];
        if (!_.isEmpty(fileNameToArray)) {
            return fileNameToArray.slice(0, -1).join('.');
        }
    }
    return 'download';
}
