<mat-dialog-content>
  <ng-container *ngIf="currentMedia">
      <div class="iframe-container">
          <iframe
                  title="Inline Frame Example"
                  width="300"
                  height="150"
                  class="resp-iframe"
                  allowfullscreen="allowfullscreen"
                  [src]="fileSource">
          </iframe>
      </div>
  </ng-container>
</mat-dialog-content>
<!--<mat-dialog-actions>-->
<!--  <button mat-button mat-dialog-close>Cancel</button>-->
<!--  <button mat-button [mat-dialog-close]="true">Delete</button>-->
<!--</mat-dialog-actions>-->
