import {Injectable} from '@angular/core';
import {CaracValueResult, CaracValueResultArg} from '../model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NpCaracValueService {

    private _caracValuesByAdvancedSearchResultUrl = '/api/sdk/caracvaleur/GetCaracValuesByAdvancedSearchResult';

    constructor(private _http: HttpClient) {
    }

    /**
     * Retourne des caracValeurs associées aux éléments reroutnés par la recherche avancée passée en paramètre
     */
    public GetCaracValuesByAdvancedSearchResult(caracValueResultArg: CaracValueResultArg): Observable<CaracValueResult[]> {
        return this._http.post<CaracValueResult[]>(this._caracValuesByAdvancedSearchResultUrl, caracValueResultArg);
    }
}
