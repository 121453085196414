import {createAction, props} from '@ngrx/store';
import { ChoiceCriteria } from 'src/app/data/types';

export const FetchPublicationChoiceCriterias = createAction(
    '[Publication Choice Criterias] Fetch Publication Choice Criterias',
    props<{ categoryId: number }>()
);

export const FetchPublicationChoiceCriteriasSuccess = createAction(
    '[Publication Choice Criterias] Fetch Success',
    props<{ choiceCriterias: ChoiceCriteria[] }>()
);

export const FetchPublicationChoiceCriteriasFailure = createAction(
    '[Publication Choice Criterias] Fetch Failure',
    props<{ message: string }>()
);
