import { Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/materialize';
import 'rxjs/add/operator/dematerialize';
import { DataMocker } from './data-mocker';

@Injectable()
export class NpIntercept implements HttpInterceptor {
  constructor(@Optional() private _DataMocker: DataMocker) {}

  /**
   * Réalise l'interception des requetes pour changer la réponse
   * Source : http://jasonwatmore.com/post/2017/12/15/angular-5-mock-backend-example-for-backendless-development
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // array in local storage for registered users
    let result = null;
    if (this._DataMocker != null) {
      result = this._DataMocker.mockResponse(request, next);
    }
    if (result == null) {
      result = next.handle(request);
    }
    return result;
  }
}
