import {PublicationTemplate} from '@app/models';
import { CatalogBuilder, CatalogConfigBuilder, ChapterBuilder, PageBuilder, PageTemplateBuilder, ProductBuilder, PublicationTemplateBuilder, ChapterPortalBuilder, CatalogPortalBuilder, PagePortalBuilder, TemplatePortalBuilder, ProductPortalBuilder } from '@data/builders';
import { Catalog, CatalogConfig, CatalogPage, Chapter, PageTemplate, Product, TreeNodeCatalog } from '@app/models';
import { CatalogConfigPortal, CatalogPortal, ChapterPortal, PagePortal, ProductPortal, TemplatePortal, PublicationTemplateDto, TreeNodeCatalogDto } from '@lib/models';

export function toTreeNodeCatalogModel(treeCatalogDto: TreeNodeCatalogDto) : TreeNodeCatalog {
    return ({
        id: treeCatalogDto.ID,
        name: treeCatalogDto.Name,
        pdfFileName: treeCatalogDto.PdfFileName,
        zipFileName: treeCatalogDto.ZipFileName
    })
}

export function toPublicationTemplate(templateDto: PublicationTemplateDto): PublicationTemplate {
    return new PublicationTemplateBuilder()
        .withId(templateDto.ID)
        .withInddFile(templateDto.InddFile)
        .withJpgFile(templateDto.JpgFile)
        .withXmlFile(templateDto.XmlFile)
        .withDescription(templateDto.Description)
        .withNumberOfElements(templateDto.NumberOfElements)
        .build();
}

export function toCatalogConfigModel(catalogConfig: CatalogConfigPortal): CatalogConfig {
    return new CatalogConfigBuilder()
        .withCatalogId(catalogConfig.CatalogId)
        .withStructure(toCatalogModel(catalogConfig.Structure))
        .withSelectedChapters(catalogConfig.SelectedChapters)
        .build();
}

export function toCatalogModel(catalog: CatalogPortal): Catalog {
    return new CatalogBuilder()
        .withId(catalog.Id)
        .withName(catalog.Name)
        .withDescription(catalog.Description)
        .withChapters(catalog.Chapters?.map(chapter => toChapterModel(chapter)) || [])
        .build();
}

export function toCatalogPortal(catalog: Catalog): CatalogPortal {
    return new CatalogPortalBuilder()
        .withId(catalog.id)
        .withName(catalog.name)
        .withDescription(catalog.description)
        .withChapters(catalog.chapters?.map(chapter => toChapterPortal(chapter)) || [])
        .build();
}

export function toChapterModel(chapter: ChapterPortal): Chapter {
    return new ChapterBuilder()
        .withName(chapter.Name)
        .withId(chapter.Id)
        .withChannelCategoryID(chapter.ChannelCategoryID)
        .withChannelCategoryExtID(chapter.ChannelCategoryExtID)
        .withPages(chapter.Pages?.map(page => toPageModel(page)) || [])
        .build();
}

export function toChapterPortal(chapter: Chapter): ChapterPortal {
    return new ChapterPortalBuilder()
        .withName(chapter.name)
        .withId(chapter.id)
        .withChannelCategoryID(chapter.channelCategoryID)
        .withChannelCategoryExtID(chapter.channelCategoryExtID)
        .withPages(chapter.pages?.map(page => toPagePortal(page)) || [])
        .build();
}

export function toPageModel(page: PagePortal): CatalogPage {
    return new PageBuilder()
        .withId(page.Id)
        .withName(page.Name)
        .withDescription(page.Description)
        .withTemplate(toPageTemplateModel(page.Template))
        .withProducts(page.Products?.map(product => toProductModel(product)) || [])
        .build() as CatalogPage;
}

export function toPagePortal(page: CatalogPage): PagePortal {
    return new PagePortalBuilder()
        .withId(page.id)
        .withName(page.name)
        .withDescription(page.description)
        .withTemplate(toTemplatePortal(page.template))
        .withProducts(page.products?.map(product => toProductPortal(product)) || [])
        .build();
}

export function toPageTemplateModel(template: TemplatePortal): PageTemplate {
    return template 
        ? new PageTemplateBuilder()
        .withId(template.Id)
        .withPath(template.Path)
        .withNumberOfElements(template.NumberOfElements)
        .withMaxCharactersLength(template.MaxCharactersLength)
        .build()
    : null;
}

export function toTemplatePortal(template: PageTemplate): TemplatePortal | null {
    return template 
        ? new TemplatePortalBuilder()
            .withId(template.id)
            .withPath(template.path)
            .withNumberOfElements(template.numberOfElements)
            .withMaxCharactersLength(template.maxCharactersLength)
            .build()
        : null;
}

export function toProductModel(product: ProductPortal): Product {
    return new ProductBuilder()
        .withId(product.Id)
        .withName(product.Name)
        .withProductId(product.ProductId)
        .build();
}

export function toProductPortal(product: Product): ProductPortal {
    return new ProductPortalBuilder()
        .withId(product.id)
        .withName(product.name)
        .withProductId(product.productId)
        .build();
}